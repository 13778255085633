import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { markAllAsRead } from "../../redux/notifications";
import { PAGES } from "../../utils/constants";
import { callTrackingApi } from "../../utils/helperCookie";
import "./Notification.css";
import NotificationCard from "./NotificationCard";

const Notifications = () => {
  const { pathname } = useLocation();
  const emailId = useSelector((state) => state?.users?.user?.attributes?.email);
  const userId = useSelector((state) => state?.users?.user?.username);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const notificationCount = useSelector(
    (state) => state.notifications?.notificationsCount
  );
  const notificationsItem = useSelector(
    (state) => state.notifications?.notifications
  );
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();
  const markAllRead = () => {
    dispatch(markAllAsRead(userId, tokenId));
  };

  // useEffect(() => {
  //   if (emailId) {
  //     callTrackingApi({
  //       email: emailId,
  //       pagetitle: PAGES.NOTIFICATION,
  //       pagelink: pathname,
  //     });
  //   }
  // }, [emailId]);

  useEffect(() => {
    if (notificationsItem) {
      setNotificationData(notificationsItem);
    }
  }, [notificationsItem]);

  return (
    <div className="started-box">
      <div className="container">
        <div className="notification-main-box">
          <div className="notification-title">
            <h2>
              Notifications
              <a
                href="javascript:void(0)"
                onClick={markAllRead}
                className="markallread"
              >
                Mark all as read
              </a>
            </h2>
          </div>
          <div>
            {notificationData.map((notification) => (
              <NotificationCard
                title={notification.Sub}
                summery={notification.Body}
                time={notification.TxnDt}
                danger={false}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
