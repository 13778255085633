import React from "react";
import { Modal } from "@mui/material";
import "./Dashboard.css";
import { paraStyle, style } from "./styles";

function ModalDashboard({ openModal, handleModalClose, appUrl }) {
  return (
    <Modal className="modal-box invoice-modal-box"
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-body">
      
        <div onClick={handleModalClose} style={paraStyle}>X</div>
        <iframe src={appUrl} width="100%" height="500px" />
      
      </div>
    </Modal>
  );
}

export default ModalDashboard;
