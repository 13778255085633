import {
  PASSWORD_LESS_ERROR,
  PASSWORD_MORE_ERROR,
  PASSWORD_ERROR,
  LOGIN_ERROR,
  EMAIL_NOT_VERIFIED,
  EMAIL_ERROR,
  VALID_EMAIL_ERROR,
  STATUS_DOWNLOADABLE,
  ROUTES,
  STATUS_REGISTERED,
  STATUS_PENDING_VERIFICATION,
  STATUS_VERIFICATION_FAILED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PROGRESS,
  STATUS_VERIFIED,
  STATUS_ON_HOLD,
} from "./constants";

export const getPassword = (error) => {
  if (error?.lessPassword) {
    return PASSWORD_LESS_ERROR;
  }
  if (error?.morePassword) {
    return PASSWORD_MORE_ERROR;
  }
  if (error?.passwordError) {
    return PASSWORD_ERROR;
  }
  return "";
};

export const getValidEmail = (error) => {
  if (error?.validEmailError) {
    return VALID_EMAIL_ERROR;
  }
  if (error?.emailError) {
    return EMAIL_ERROR;
  }
  return "";
};

export const getError = (str) => {
  if (str?.includes("User pool")) {
    return LOGIN_ERROR;
  }
  return str;
};

export const getEmailVerifiedError = (str) => {
  if (str?.includes("not verified")) {
    return EMAIL_NOT_VERIFIED;
  } else if (str?.includes("conform")) {
    let result = str.replace("conform", "confirm");
    return result;
  }
  return str;
};

export const getName = (a, b) => {
  if (a === "User" || b === "User") {
    return "";
  }
  if (a) {
    return a;
  } else if (b) {
    return b;
  }
  return "";
};

export const getPageTitle = (title) => {
  let result = title.substr(1);
  if (result?.length > 0) {
    return result;
  }
  return "home";
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const checkDisableBtnForm = (value) => {
  if (value) {
    return true;
  }
  return false;
};

const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

export const timeSince = (dt) => {
  let date = new Date(dt);
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  const interval = intervals.find((i) => i.seconds < seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
};

export const getRouteName = (status) => {
  if (status === STATUS_DOWNLOADABLE || status === STATUS_PAYMENT_SUCCESS) {
    return ROUTES.DOWNLOAD;
  } else if (
    status === STATUS_REGISTERED ||
    status === STATUS_PENDING_VERIFICATION ||
    status === STATUS_VERIFICATION_FAILED ||
    status === STATUS_VERIFICATION_COMPLETED ||
    status === STATUS_PAYMENT_FAILED ||
    status === STATUS_PAYMENT_AWAITED
  ) {
    return ROUTES.APP_STAGE;
  }
  return ROUTES.APP_STAGE;
};

export const getRouteHeading = (status) => {
  // if (
  //   status === STATUS_REGISTERED ||
  //   status === STATUS_PENDING_VERIFICATION ||
  //   status === STATUS_VERIFICATION_FAILED ||
  //   status === STATUS_VERIFICATION_COMPLETED ||
  //   status === STATUS_PAYMENT_FAILED ||
  //   status === STATUS_PAYMENT_AWAITED
  // ) {
  //   return "App Stage";
  // } else if (
  //   status === STATUS_DOWNLOADABLE ||
  //   status === STATUS_PAYMENT_SUCCESS
  // ) {
  //   return "Download Stage";
  // }

  if(status === STATUS_REGISTERED){
    return "Sign Letter Stage";
  }else if(status === STATUS_PENDING_VERIFICATION || status === STATUS_VERIFICATION_FAILED){
    return "Letter Verification Stage";
  }else if(status === STATUS_VERIFICATION_COMPLETED || status === STATUS_PAYMENT_FAILED || status === STATUS_PAYMENT_AWAITED){
    return "Payment Detail Stage";
  }else if ( status === STATUS_DOWNLOADABLE || status === STATUS_PAYMENT_SUCCESS ) 
  {
    return "Download Stage";
  }

  return "About You Stage";
};

export const getLicenseDisable = (event, about) => {
  if (event === "true" && about?.Plan === "Freemium") {
    return false;
  }
  return true;
};

export const getCurrency = (currency, curr) => {
  if (currency === "inr") {
    return rupeeIndian.format(curr);
  } else {
    return dollarUS.format(curr);
  }
};

export const getCurrencyFreemium = (country, price) => {
  if (country?.toLowerCase() == "india" || country?.toLowerCase() == "inr") {
    return "inr";
  } else {
    return "usd";
  }
};

let dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

let rupeeIndian = Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

export const getStatusValue = (status, profile) => {
  if (status) {
    if (status === "AdminVerified") {
      return STATUS_VERIFIED;
    } else if (status === "Registered") {
      return STATUS_VERIFIED;
    } else if (status === "PendingVerification") {
      return STATUS_VERIFIED;
    } else if (status === "VerificationCompleted") {
      return STATUS_VERIFIED;
    } else if (status === "VerificationFailed") {
      return STATUS_VERIFIED;
    } else if (status === "PaymentAwaited") {
      return STATUS_VERIFIED;
    } else if (status === "PaymentSuccess") {
      return STATUS_VERIFIED;
    } else if (status === "PaymentFailed") {
      return STATUS_VERIFIED;
    } else if (status === "AppDownloaded") {
      return STATUS_VERIFIED;
    } else if (status === "AppDownloadable") {
      return STATUS_VERIFIED;
    }
  } else if (profile) {
    if (profile === "rejected") {
      return STATUS_ON_HOLD;
    } else {
      return STATUS_PROGRESS;
    }
  }
  return STATUS_PROGRESS;
};

export const getLicenseDate = (d) => {
  d = String(d);
  let time = 0;
  if (d.length > 10) {
    time = Number(d);
  } else {
    time = Number(d) * 1000;
  }
  let date = new Date(Number(time)).toUTCString();
  //date = date.toLocaleDateString() + " " + date.toLocaleTimeString();
  return date;
};

export const getInvoiceKeyDate = (d) => {
  d = String(d);
  let time = 0;
  if (d.length > 10) {
    time = Number(d);
  } else {
    time = Number(d) * 1000;
  }
  let date = new Date(Number(time)).toUTCString();
  return date;
};

export const compareDate = (d) => {
  d = String(d);
  let time = 0;
  if (d.length > 10) {
    time = Number(d);
  } else {
    time = Number(d) * 1000;
  }
  let licenseDate = new Date(Number(time));
  let currentDate = new Date(Date.now());

  if (licenseDate < currentDate) {
    return true;
  }
  return false;
};

export const getTimeLimitMonth = (timeLimit) => {
  if (timeLimit.includes("1M")) {
    return "1 Month";
  } else if (timeLimit.includes("M")) {
    timeLimit = timeLimit.replace("M", " Months");
    return timeLimit;
  }
  return timeLimit;
};
