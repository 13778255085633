import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setAboutYouStage, setHubspotStage } from "../../redux/stages";
import {
  setActiveUser,
  setConfirmLoading,
  setEventDay,
  setLoggedIn,
  setUserData,
} from "../../redux/users";
import ApiService from "../../service/axiosUtils";
import { ROUTES, USER_APPLICANT, USER_REJECTED } from "../../utils/constants";
import { getHubspotUser } from "../../service/hubspotAxios";
import store from "../../redux/store";
import { getPricingTable, getIndiaPricingTable } from "../../redux/prices";
import {
  fetchNotifications,
  fetchNotificationsCount,
} from "../../redux/notifications";

function PrivateComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  const profile = useSelector(
    (state) => state?.users?.user?.attributes?.profile
  );
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);

  // useEffect(() => {
  //   if (hubspotYou) {
  //     setIsPlanDataLoaded(true);
  //     console.log("in if condition");
  //   }
  // }, [hubspotYou]);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        const refreshToken = session.getRefreshToken();
        const urlPathName = window.location.pathname;

        Auth.currentAuthenticatedUser()
          .then((res) => {
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                handleLogout();
              } else {
                Auth.currentUserInfo().then((resp) => {
                  const userObj = {
                    username: res?.username,
                    attributes: resp?.attributes,
                    token: data.getIdToken().getJwtToken(),
                  };

                  setData(res);
                  dispatch(setActiveUser(true));
                  dispatch(setUserData(userObj));
                  dispatch(setConfirmLoading(true));
                  if (urlPathName === "/app-stage" || urlPathName === "/pricing" || urlPathName === "/profile") {
                      if (hubspotYou?.Country && hubspotYou?.Country?.toLowerCase() === "india") {
                        dispatch(getIndiaPricingTable());
                      }else{
                        dispatch(getPricingTable(data.getIdToken().getJwtToken()));
                      }
                   }
                  
                  ApiService.getApiData(
                    `/users/id`,
                    data.getIdToken().getJwtToken()
                  )
                    .then((res) => {
                      dispatch(setConfirmLoading(false));
                      if (res?.data && res?.data?.length > 0) {
                        dispatch(setAboutYouStage(res?.data[0]));
                      }
                      dispatch(
                        fetchNotificationsCount(
                          res?.username,
                          data.getIdToken().getJwtToken()
                        )
                      );
                      dispatch(
                        fetchNotifications(
                          res?.username,
                          data.getIdToken().getJwtToken()
                        )
                      );
                    })
                    .catch((e) => {
                      dispatch(setConfirmLoading(false));
                      console.log(e?.message);
                    });

                  ApiService.getApiData(
                    `/eventDay`,
                    data.getIdToken().getJwtToken()
                  )
                    .then((eventRes) => {
                      let day = "false";
                      let apiBody = {};
                      if (typeof eventRes?.data?.body === "string") {
                        apiBody = JSON.parse(eventRes?.data?.body);
                        day = apiBody?.data;
                      } else {
                        day = eventRes?.data?.data;
                      }
                      dispatch(setEventDay(day));
                    })
                    .catch((e) => {
                      console.log(e?.message);
                    });

                  getHubspotUser(res?.attributes?.email)
                    .then((r) => r.json())
                    .then((resp) => {
                      const obj = {
                        Org: resp?.properties?.company?.value || "",
                        Dept:
                          resp?.properties?.department_unit_org_chart?.value ||
                          "",
                        PhNo: resp?.properties?.phone?.value || "",
                        Desgn: resp?.properties?.jobtitle?.value || "",
                        Mac: resp?.properties?.macaddress?.value || "",
                        Country: resp?.properties?.custom_country?.value || "",
                      };
                      dispatch(setHubspotStage(obj));
                      //set india price table if country have india
                      if (resp?.properties?.custom_country?.value && resp?.properties?.custom_country?.value.toLowerCase() === "india") {
                        dispatch(getIndiaPricingTable());
                      }
                    })
                    .catch((e) => console.log(e));
                });
              }
            });
          })
          .catch(() => {
            navigate(ROUTES.LANDING_PAGE);
          });
      })
      .catch((e) => console.log(e));
  }, [navigate, dispatch]);

  useEffect(() => {
    if (data && Object.keys(data)?.length === 0) {
      navigate(ROUTES.LANDING_PAGE);
    }
  }, [data, navigate]);

  const handleLogout = () => {
    if (store?.getState()?.users?.activeUser) {
      Auth.signOut().then(() => {
        dispatch(setUserData(null));
        dispatch(setActiveUser(false));
        dispatch(setAboutYouStage(null));
        dispatch(setHubspotStage(null));
        dispatch(setLoggedIn(false));
        window.location.href = "/";
      });
    }
  };

  const handleRender = () => {
    if (Object.keys(data)?.length !== 0) {
      return <Outlet />;
    }
  };

  return <>{data && handleRender()}</>;
}

export default PrivateComponent;
