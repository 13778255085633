import React from "react";
import ReactOwlCarousel from "react-owl-carousel";
const TrustBox = () => {
  return (
    <>
      <div className="trust-box">
        <div className="container">
          <h2 className="section-title text-center mb-2 wow fadeInUp">
            Trusted by
          </h2>
          <p className="text-center wow fadeInUp" data-wow-delay="0.2s">
            Government and Law Enforcement Agencies across
          </p>

          <ReactOwlCarousel
            items={1}
            loop
            nav
            className="trust-slider owl-carousel wow fadeInUp"
          >
            {/* <!--Item--> */}
            <div className="item">Europe</div>
            {/* <!--Item--> */}
            <div className="item">APAC</div>
            {/* <!--Item--> */}
            <div className="item">Oceania</div>
            {/* <!--Item--> */}
            <div className="item">GCC Countries</div>
          </ReactOwlCarousel>
        </div>
      </div>
    </>
  );
};
export default TrustBox;
