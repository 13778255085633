import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import React from "react";
import useGeoLocation from "react-ipgeolocation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { countriesCodes } from "../../utils/countryCode";
import { checkDisableBtnForm } from "../../utils/utils";
import "./StageOneAboutYou.css";

const StageOneAboutYou = ({
  stage,
  aboutYouDetails,
  setAboutYouDetails,
  aboutYouError,
  setAboutYouError,
  validationError,
  setValidationError,
}) => {
  const location = useGeoLocation();
  const countryCode = location?.country?.toLowerCase() || "in";
  const attributes = useSelector((state) => state?.users?.user?.attributes);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);

  const onChangeHandler = (e) => {
    aboutYouDetails[e.target.name] = e.target.value;
    setAboutYouDetails({ ...aboutYouDetails });

    if (aboutYouDetails.FirstName) {
      setAboutYouError((prevState) => ({
        ...prevState,
        nameError: false,
      }));
      var nameRegex = /^[a-zA-Z\- ]+$/;
      if (nameRegex.test(aboutYouDetails.FirstName)) {
        setValidationError((prevState) => ({
          ...prevState,
          nameError: false,
        }));
      } else {
        setValidationError((prevState) => ({
          ...prevState,
          nameError: true,
        }));
      }
    }
    if (aboutYouDetails.LastName) {
      setAboutYouError((prevState) => ({
        ...prevState,
        lastNameError: false,
      }));
      var lastNameRegex = /^[a-zA-Z\- ]+$/;
      if (lastNameRegex.test(aboutYouDetails.LastName)) {
        setValidationError((prevState) => ({
          ...prevState,
          lastNameError: false,
        }));
      } else {
        setValidationError((prevState) => ({
          ...prevState,
          lastNameError: true,
        }));
      }
    }
    if (aboutYouDetails.Org) {
      setAboutYouError((prevState) => ({
        ...prevState,
        organizationError: false,
      }));
    }
    if (aboutYouDetails.Dept) {
      setAboutYouError((prevState) => ({
        ...prevState,
        departmentError: false,
      }));
    }
    if (aboutYouDetails.Desgn) {
      setAboutYouError((prevState) => ({
        ...prevState,
        designationError: false,
      }));
    }
    if (aboutYouDetails.PhNo) {
      setAboutYouError((prevState) => ({
        ...prevState,
        phNoError: false,
      }));
    }
    if (aboutYouDetails.Country) {
      setAboutYouError((prevState) => ({
        ...prevState,
        countryError: false,
      }));
    }
    // if (aboutYouDetails.Mac) {
    //   setAboutYouError((prevState) => ({
    //     ...prevState,
    //     macError: false,
    //   }));

    //   let macRegex = new RegExp(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/);

    //   if (macRegex.test(aboutYouDetails.Mac)) {
    //     setValidationError((prevState) => ({
    //       ...prevState,
    //       macError: false,
    //     }));
    //   } else {
    //     setValidationError((prevState) => ({
    //       ...prevState,
    //       macError: true,
    //     }));
    //   }
    // }
  };

  const handlePhoneChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[a-z]/gi, "");
    aboutYouDetails.PhNo = val;
    const name = "PhNo";
    aboutYouDetails[name] = val;
    setAboutYouDetails({ ...aboutYouDetails });
    if (aboutYouDetails.PhNo) {
      const phoneRegex = /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;

      if (phoneRegex.test(aboutYouDetails.PhNo)) {
        setValidationError((prevState) => ({
          ...prevState,
          phNoError: false,
        }));
      } else {
        setValidationError((prevState) => ({
          ...prevState,
          phNoError: true,
        }));
      }
    }
  };

  const disablePhone = () => {
    return hubspotYou?.PhNo?.length > 5;
  };

  return (
    <div className="info-box">
      <div className="info-field-box">
        <h3 className="text-center mb-20">Get Started</h3>
        <p className="text-center mb-20">
          Help us serve you the best by knowing you better.
        </p>
        <div className="field-row">
          <div className="field-col">
            <label className="field-title">
              First Name<span> *</span>
            </label>
            <input
              name="FirstName"
              type="text"
              placeholder=""
              value={aboutYouDetails.FirstName}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(attributes?.given_name)}
              required
            />
            <span className="error-message">
              {aboutYouError.nameError
                ? "Name is mandatory*"
                : validationError.nameError
                ? "Please provide proper name"
                : null}
            </span>
          </div>

          <div className="field-col">
            <label className="field-title">
              Last Name<span> *</span>
            </label>
            <input
              name="LastName"
              type="text"
              placeholder=""
              value={aboutYouDetails.LastName}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(attributes?.family_name)}
              required
            />
            <span className="error-message">
              {aboutYouError.lastNameError
                ? "Name is mandatory*"
                : validationError.lastNameError
                ? "Please provide proper last name"
                : null}
            </span>
          </div>

          <div className="field-col">
            <label className="field-title">
              Organization<span> *</span>
            </label>
            <input
              name="Org"
              type="text"
              placeholder=""
              value={aboutYouDetails.Org}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(hubspotYou?.Org)}
              required
            />
            <span className="error-message">
              {aboutYouError.organizationError
                ? "Organization name is mandatory*"
                : ""}
            </span>
          </div>

          <div className="field-col">
            <label className="field-title">
              Department<span> *</span>
            </label>
            <input
              name="Dept"
              type="text"
              placeholder=""
              value={aboutYouDetails.Dept}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(hubspotYou?.Dept)}
              required
            />
            <span className="error-message">
              {aboutYouError.departmentError
                ? "Department name is mandatory*"
                : ""}
            </span>
          </div>

          <div className="field-col">
            <label className="field-title">
              Designation<span> *</span>
            </label>
            <input
              name="Desgn"
              type="text"
              placeholder=""
              value={aboutYouDetails.Desgn}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(hubspotYou?.Desgn)}
              required
            />
            <span className="error-message">
              {aboutYouError.designationError
                ? "Designation name is mandatory*"
                : ""}
            </span>
          </div>

          <div className="field-col">
            <label className="field-title">
              Phone number<span> *</span>
            </label>
            <input
              name="PhNo"
              type="text"
              placeholder=""
              value={aboutYouDetails.PhNo}
              onChange={handlePhoneChange}
              className="field-input"
              disabled={disablePhone()}
              required
            />
            <span className="error-message">
              {validationError.phNoError
                ? "Please provide proper phone number*"
                : ""}
            </span>
          </div>

          {/* <div className="field-col">
            <label className="field-title">
              Phone number<span> *</span>
            </label>
            <PhoneInput
              inputStyle={{
                backgroundColor: "white",
                opacity: 0.8,
                height: "35px",
                width: "100%",
              }}
              dropdownStyle={{ color: "black", height: "100px", scrollBehaviour: "unset" }}
              country={countryCode}
              value={aboutYouDetails.PhNo}
              disabled={checkDisableBtnForm(hubspotYou?.PhNo)}
              onChange={handlePhoneChange}
              inputProps={{
                name: "phone",
                required: true,
                id: "phone-input",
              }}
            />
            <span className="error-message">
              {aboutYouError.phNoError
                ? "Phone Number is mandatory*"
                : validationError.macError
                ? "Please provide proper phone number*"
                : null}
            </span>
          </div> */}

          {/* <div className="field-col">
            <label className="field-title">
              MAC Address (Laptop/Desktop)<span>*</span>
              <div className="tooltip-dropdown">
                <div className="tooltip-icon">
                  <span className="material-icons-outlined">info</span>
                </div>
                <div className="tooltip-dropdown-content">
                  <p>
                    To find out the Mac address of your system follow the below
                    steps:
                  </p>
                  <ul>
                    <li>
                      open command prompt using the start menu in the system
                      where you would like to install CARBN.AI<sup>TM</sup>.{" "}
                    </li>
                    <li>Type the following command “ipconfig &nbsp; /all”. </li>
                    <li>
                      Copy and paste the 12-digit Mac address. For example.
                      XX-XX-XX-XX-XX-XX
                    </li>
                  </ul>
                </div>
              </div>
            </label>

            <input
              name="Mac"
              type="text"
              placeholder=""
              value={aboutYouDetails.Mac}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(hubspotYou?.Mac)}
              required
            />
            <span className="error-message">
              {aboutYouError.macError
                ? "MAC Address is mandatory*"
                : validationError.macError
                ? "Please provide MAC address in valid format"
                : null}
            </span>
          </div> */}

          <div className="field-col">
            <label className="field-title">
              Country<span> *</span>
            </label>
            <select
              required
              name="Country"
              value={aboutYouDetails?.Country || "India"}
              onChange={onChangeHandler}
              className="field-input"
              disabled={checkDisableBtnForm(hubspotYou?.Country)}
            >
              {countriesCodes.map((c, index) => {
                return <option value={c?.name} key={index}>{c?.name}</option>;
              })}
            </select>
            <span className="error-message">
              {aboutYouError.countryError ? "Country is mandatory*" : ""}
            </span>
          </div>
          <div className="advise-info">
            Kindly be advised that the fields you are currently filling out will not be editable later. Please ensure all information is accurate before submission.
          </div>

          <div className="advise-info">
            The subscription invoice(s) will be billed on the organisation’s name.
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageOneAboutYou;
