import { Backdrop, Box, CircularProgress } from "@mui/material";
import React from "react";

function LoadingScreen({ open }) {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: "98.5vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 3,
        }}
      >
        <Backdrop open={open} className="loader-backdrop">
          <CircularProgress />
        </Backdrop>
      </Box>
    </>
  );
}

export default LoadingScreen;
