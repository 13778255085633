import { Box } from "@mui/material";
import React from "react";
import {
  CODE_ERROR,
  PASSWORD_ERROR,
  RE_PASSWORD_ERROR,
} from "../../utils/constants";
import SnackBarHome from "../Snackbar/SnackbarHome";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const ResetPasswordStageTwo = ({
  setForgotPasswordStage,
  handleForgotPasswordSubmit,
  handleCodeResend,
  user,
  userError,
  handleChange,
  open,
  setOpen,
  message,
  iconVisible,
  handleIconToggle,
}) => {
  return (
    <>
      <h3 className="reset-title mb-4">Reset your password</h3>

      <div className="field-col full-width">
        <label className="field-title-code">
          OTP<span>*</span>
        </label>

        <input
          className="field-input-login"
          name="code"
          type="text"
          required
          value={user?.code}
          onChange={handleChange}
        />
        <span className="error-message">
          {userError?.codeError ? CODE_ERROR : ""}
        </span>
      </div>

      <div className="field-col full-width">
        <label className="field-title-code">
          New Password<span>*</span>
        </label>
        <div className="input-password-login">
          <input
            className="field-input-login"
            name="newPassword"
            type={iconVisible ? "password" : "text"}
            required
            value={user?.newPassword}
            onChange={handleChange}
          />
          <span className="input-icon-login" onClick={handleIconToggle}>
            {iconVisible ? (
              <EyeSlash color="black" size={24} />
            ) : (
              <Eye color="black" size={24} />
            )}
          </span>
        </div>
        <span className="error-message">
          {userError?.newPasswordError ? PASSWORD_ERROR : ""}
        </span>
      </div>

      <div className="field-col full-width">
        <label className="field-title-code">
          Confirm Password<span>*</span>
        </label>

        <div className="input-password-login">
          <input
            className="field-input-login"
            name="confirmPassword"
            type={iconVisible ? "password" : "text"}
            required
            value={user?.confirmPassword}
            onChange={handleChange}
          />
          <span className="input-icon-login" onClick={handleIconToggle}>
            {iconVisible ? (
              <EyeSlash color="black" size={24} />
            ) : (
              <Eye color="black" size={24} />
            )}
          </span>
        </div>
        <span className="error-message">
          {userError?.confirmPasswordError ? RE_PASSWORD_ERROR : ""}
        </span>
      </div>

      <p className="font-12 password-helper-text">
      Your password should be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.
                      </p>

      <SnackBarHome
        open={open}
        setOpen={setOpen}
        severity="error"
        message={message}
      />

      <button
        className="btn primary-btn me-3 mb-3"
        onClick={(event) => {
          handleForgotPasswordSubmit(event);
        }}
        disabled={!user?.code || !user?.newPassword || !user?.confirmPassword}
      >
        Submit
      </button>
      <button
        className="btn primary-border-btn mb-3 me-3"
        onClick={(event) => {
          handleCodeResend(event);
        }}
      >
        Resend OTP
      </button>
      <button
        className="btn primary-border-btn mb-3"
        onClick={(event) => {
          setForgotPasswordStage(0);
        }}
      >
        Back to Sign in
      </button>

      <div style={{ marginTop: "15px" }}>
        For any queries, please call{" "}
        <a href="tel:+918062281345">+91 80622 81345</a> or email{" "}
        <a href="mailto:support@carbn.ai">support@carbn.ai</a>.
      </div>
    </>
  );
};

export default ResetPasswordStageTwo;
