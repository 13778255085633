import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentDetail } from "../../redux/payments";
import LoadingScreen from "../LoadingScreen";
import "./strip.css";

const stripePromise = loadStripe(
  "pk_test_51OQ6LHSI7RoV16YmMaBIOM87GLZoEvByDEyVBJC2owgcdWCHdRBTCvxw6Sxx5RNr1cZcBv3Q3FYz41nc3WeBRvuh00yHXyWx6F"
);
const appearance = {
  theme: "night",
  labels: "floating",
};

const PaymentByStripe = ({ priceDetail }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const payDetail = useSelector((state) => state.payments?.payments);
  const userDetails = useSelector((state) => state?.users?.user);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const macAddress = useSelector((state) => state?.stage?.hubspotYou?.Mac);
  const [clientSecret, setClientSecret] = useState("");

  // this is used to get client secret key
  useEffect(() => {
    setLoading(true);
    if (tokenId)
      dispatch(
        fetchPaymentDetail(
          priceDetail,
          userDetails,
          tokenId,
          aboutYou,
          macAddress
        )
      )
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
  }, [tokenId]);

  // here we set that client secret key
  useEffect(() => {
    setClientSecret(payDetail.client_secret);
  }, [payDetail]);

  return (
    <>
      <div id="checkout" style={{ color: "red" }}>
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
        {loading && <LoadingScreen open={loading} />}
      </div>
    </>
  );
};

export default PaymentByStripe;
