import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { ROUTES } from "../../utils/constants";
import LandingPageEN from "./index-en";
import LandingPageIndia from './index-india'; // India-specific Landing Page

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("navigation run");
    // Fetch country from ipwhois.io
    fetch('https://ipwhois.app/json/')
      .then((response) => response.json())
      .then((data) => {
        const countryRoutes = {
          IN: ROUTES.LANDING_PAGE_IN, // Example: Add other countries here as needed
        };

        const countryCode = data.country_code; // Example: "IN", "FR"
        const targetRoute = countryRoutes[countryCode];

        if (targetRoute) {
          navigate(targetRoute); // Redirect only if a specific country route exists
        }else{
          navigate(ROUTES.LANDING_PAGE);
        }
      })
      .catch((error) => {
        console.error('Error fetching IP data:', error);
        // Stay on the default landing page if there's an error
        navigate(ROUTES.LANDING_PAGE);
      }).finally(() => {
        setIsLoading(false); // Stop showing the loading spinner
      });
  }, [navigate]);

  console.log("loading");
  if (isLoading) {
    // Render a loading state or spinner while fetching country data
    return <></>;
  }

  //return <LandingPageEN />;

  switch (location.pathname) {
    case ROUTES.LANDING_PAGE_IN:
      return <LandingPageIndia />;
    case ROUTES.LANDING_PAGE:
    default:
      return <LandingPageEN />;
  }
};

export default LandingPage;
