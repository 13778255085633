//import Cookies from "js-cookie";

function validateHTTPandHTTPS(reqStr) {
  var tarea = reqStr;
  /*******https is available in the url*******/
  if (tarea.indexOf("http://") === 0 || tarea.indexOf("https://") === 0) {
    return true;
  }
}

export const callTrackingApi = (payload) => {
  const domainURL = window.location.origin;
  /*****if http or https is availble in the url then go init******/
  var pagelink;
  if (validateHTTPandHTTPS(payload.pagelink) === true) {
    pagelink = payload.pagelink;
  } else {
    pagelink = domainURL + "" + payload.pagelink;
  }

  fetch("https://api.ipify.org?format=json")
    .then(function (response) {
      return response.json();
    })
    .then(function (res) {
      let cookieCRCT = localStorage.getItem("_cr_ct");

      let raw = JSON.stringify({
        email: payload?.email || "",
        cookieid: cookieCRCT,
        ipaddress: res.ip,
        title: payload?.pagetitle,
        pagelink: pagelink,
        useragent: window.navigator.userAgent,
      });

      let requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };
      return fetch(
        `${process.env.REACT_APP_HUBSPOT_URL}?method=cs&action=cr`,
        requestOptions
      ).catch((e) => {
        console.log(e);
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

/******if we required on any button******/
export const handleAnalyticsClick = (payload) => {
  callTrackingApi(payload);
};
