import React from "react";
import Modal from "@mui/material/Modal";
import "../LandingPage/inner-parts/HubspotModel.css";

const HowBeginModal = ({ howItWorks, closeHowItWorks }) => {
  return (
    <>
      <Modal
        className="video-modal modal-box"
        open={howItWorks}
        onClose={closeHowItWorks}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-dialog">
          {/* <button type="button" className="btn-close"><span className="material-icons-outlined">close</span> Close</button> */}
          <div className="modal-body">
            <iframe
              src="https://player.vimeo.com/video/923250421?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Add case"
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default HowBeginModal;
