import React from "react";

const NotificationCard = ({ title, summery, time, danger }) => {
  const letter = title?.charAt(0);
  return (
    <div className="notification-inner-box">
      <div className="notification-header-profile">{letter}</div>
      <div className="notification-header">
        <div>
          <h5>{title}</h5>
          <p> {time}</p>
        </div>
      </div>
      <div className="notification-description">{summery}</div>
    </div>
  );
};

export default NotificationCard;
