import { Box } from "@mui/material";
import React from "react";
import { CODE_ERROR } from "../../utils/constants";
import SnackBarHome from "../Snackbar/SnackbarHome";

const ResetPasswordStageFour = ({
  user,
  userError,
  handleChange,
  handleCodeSubmit,
  handleLoginCodeSubmit,
  open,
  setOpen,
  message,
}) => {
  return (
    <>

      <h3 className="reset-title">We Have Emailed You</h3>

      <p className="mb-4">OTP sent to {user?.email}. Please enter to finish signing up.</p>

      <div className="field-col full-width">
        <label className="field-title">Enter your OTP<span>*</span></label>
        <input className="field-input-login" name="newCode" type="text" required value={user?.newCode} onChange={handleChange} />
        <span className="error-message">{userError?.newCodeError ? CODE_ERROR : ""} </span>
      </div>

      <SnackBarHome open={open} setOpen={setOpen} severity="error" message={message} />

      <button className="btn primary-btn" onClick={(event) => {handleLoginCodeSubmit(event); }}>Confirm</button>

      <div style={{ marginTop: "15px" }}>
        For any queries, please call{" "}
        <a href="tel:+918062281345">+91 80622 81345</a> or email{" "}
        <a href="mailto:support@carbn.ai">support@carbn.ai</a>.
      </div>
    </>
  );
};

export default ResetPasswordStageFour;
