import React, { useCallback, useEffect, useState } from "react";
import SignupForm from "../../../Components/Signup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN_REGISTERED,
  ADMIN_VERIFIED,
  MAX_PASSWORD,
  MIN_PASSWORD,
  OTP_ERROR,
  PAGES,
  PASSWORD_MATCH_ERROR,
  ROUTES,
  STATUS_DOWNLOADABLE,
  STATUS_DOWNLOADED,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PENDING_VERIFICATION,
  STATUS_REGISTERED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_VERIFICATION_FAILED,
  TOKEN_ERROR,
  USER_SIGNUP_APPLICANT,
} from "../../../utils/constants";
import { Auth } from "aws-amplify";
import { signUpUserHubspot } from "../../../service/hubspotAxios";
import LoadingScreen from "../../../Components/LoadingScreen";
import { callTrackingApi } from "../../../utils/helperCookie";
import { setUserData } from "../../../redux/users";
import { getEmailVerifiedError, getError } from "../../../utils/utils";
import { invalidEmailNames, invalidDevEmailNames } from "../../../utils/invalidEmails";
import ApiService from "../../../service/axiosUtils";
import { setAboutYouStage } from "../../../redux/stages";
import { getPricingTable } from "../../../redux/prices";

let BASE_URL_CHECK = process.env.REACT_APP_PROD_BASE_URL;

function Signup() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const signUpEmail = useSelector((state) => state?.users?.signUpEmail);
  const navigate = useNavigate();
  const userValue = {
    name: "",
    lastName: "",
    email: signUpEmail || "",
    password: "",
    repassword: "",
    referralcode: "",
    code: "",
  };
  const [user, setUser] = useState(userValue);
  const [isCheckedCheckBox, setCheckedCheckBox] = useState(false);
  
  const initialError = {
    nameError: false,
    lastNameError: false,
    emailError: false,
    validEmailError: false,
    passwordError: false,
    lessPassword: false,
    morePassword: false,
    repasswordError: false,
    codeError: false,
  };
  const [userError, setUserError] = useState(initialError);
  const [errApi, setApiError] = useState("");
  const [open, setOpen] = useState(false);
  const [signupStage, setSignupStage] = useState(0);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.useRef();
  const [iconVisible, setIconVisible] = useState(true);
  const [resetIconVisible, setResetIconVisible] = useState(true);

  
  useEffect(() => {
    callTrackingApi({
      email: "",
      pagetitle: PAGES.SIGNUP_PAGE,
      pagelink: pathname,
    });
  }, []);

  useEffect(() => {
    setUser((prevState) => ({ ...prevState, email: signUpEmail }));
  }, [signUpEmail]);

  const checkKeyPress = useCallback(
    (e) => {
      const { key } = e;
      if (key === "Enter") {
        handleSubmit(e);
      }
    },
    [user]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  const handleChange = (event) => {
    let val = event.target.value;
    if (event.target.name === "name" || event.target.name === "lastName") {
      val = val.replace(/[^A-Za-z]/gi, "");
    }
    setUser({ ...user, [event.target.name]: val });
    setUserError(initialError);
  };

  const setCheckedCheckBoxFunc = (event) => {
      setCheckedCheckBox(event.target.checked);
  }

  const handleSubmit = (event) => {

    event?.preventDefault();
    if (
      !user.name ||
      !user.lastName ||
      !user.email ||
      !user.password ||
      !user.repassword ||
      !isCheckedCheckBox
    ) {
      if (!user.name) {
        setUserError((prevState) => ({ ...prevState, nameError: true }));
      }
      if (!user.lastName) {
        setUserError((prevState) => ({ ...prevState, lastNameError: true }));
      }
      if (!user.email) {
        setUserError((prevState) => ({ ...prevState, emailError: true }));
      }
      if (!user.password) {
        setUserError((prevState) => ({ ...prevState, passwordError: true }));
      }
      if (!user.repassword) {
        setUserError((prevState) => ({ ...prevState, repasswordError: true }));
      }
      if(!isCheckedCheckBox){
        setUserError((prevState) => ({ ...prevState, privacyError: true }));
      }
      return;
    }

    //for again check checkbox is checkec or not
    var checkbox = document.getElementById('privacyCheckbox');
    if (!checkbox.checked) {
        console.log('Checkbox is not checked');
        setUserError((prevState) => ({ ...prevState, privacyError: true }));
        return;
    }

    // eslint-disable-next-line
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (user?.email) {
      if (!regex.test(user?.email)) {
        setUserError((prevState) => ({ ...prevState, emailError: true }));
        return;
      }
    }

    let isValidEmail = true;
    if (user?.email) {
      /***for dev server remove domains**/
      if(BASE_URL_CHECK=="https://api.dev.carbn.ai/carbon-dev/"){
        console.log("in if");
        invalidDevEmailNames.forEach((email) => {
          //if (user?.email?.includes(email)) {
          if (user?.email?.toLowerCase().includes(email.toLowerCase())) {
            setUserError((prevState) => ({
              ...prevState,
              emailError: true,
            }));
            setUserError((prevState) => ({
              ...prevState,
              validEmailError: true,
            }));
            isValidEmail = false;
            return false;
          }
        });
      }else{
        console.log("in else");
        invalidEmailNames.forEach((email) => {
          //if (user?.email?.includes(email)) {
          if (user?.email?.toLowerCase().includes(email.toLowerCase())) {
            setUserError((prevState) => ({
              ...prevState,
              emailError: true,
            }));
            setUserError((prevState) => ({
              ...prevState,
              validEmailError: true,
            }));
            isValidEmail = false;
            return false;
          }
        });
      }
    }
    if (!isValidEmail) {
      return;
    }

    if (user?.password) {
      if (user?.password?.length < MIN_PASSWORD) {
        setUserError((prevState) => ({ ...prevState, passwordError: true }));
        setUserError((prevState) => ({ ...prevState, lessPassword: true }));
        return;
      }
      if (user?.password?.length > MAX_PASSWORD) {
        setUserError((prevState) => ({ ...prevState, passwordError: true }));
        setUserError((prevState) => ({ ...prevState, morePassword: true }));
        return;
      }
    }
    if (user?.password !== user?.repassword) {
      setUserError((prevState) => ({
        ...prevState,
        password: true,
        repasswordError: true,
      }));
      setApiError(PASSWORD_MATCH_ERROR);
      setOpen(true);
      return;
    }

    setLoading(true);
    // TODO: default user will have profile = user and admin's profile will be "" or "admin"
    Auth.signUp({
      username: user?.email,
      password: user?.password,
      attributes: {
        given_name: user?.name,
        family_name: user?.lastName,
        profile: USER_SIGNUP_APPLICANT,
        preferred_username: USER_SIGNUP_APPLICANT,
      },
      options: {
        userAttributes: {
          given_name: user?.name,
          family_name: user?.lastName,
          profile: USER_SIGNUP_APPLICANT,
          preferred_username: USER_SIGNUP_APPLICANT,
        },
      },
    })
      .then(() => {
        setLoading(false);
        setSignupStage(1);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(getEmailVerifiedError(`${err?.message}`));
        setOpen(true);
      });
  };

  const handleCodeResend = (event) => {
    event.preventDefault();
    setLoading(true);
    Auth.resendSignUp(user?.email)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(`${err?.message}`);
        setOpen(true);
      });
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();
    if (!user?.code) {
      setUserError((prevState) => ({ ...prevState, codeError: true }));
      return;
    }
    setLoading(true);
    const token = await recaptchaRef?.current?.executeAsync();
    if (!token) {
      setApiError(TOKEN_ERROR);
      setOpen(true);
      return;
    }
    recaptchaRef?.current?.reset();
    Auth.confirmSignUp(user?.email, user?.code)
      .then(() => {
        signUpUserHubspot(user);
        Auth.signIn(user?.email, user?.password, { captcha: token })
          .then((resp) => {
            const userObj = {
              username: resp?.username,
              attributes: resp?.attributes,
              token: resp?.signInUserSession?.idToken?.jwtToken,
            };
            // dispatch(getPricingTable(resp?.signInUserSession?.idToken?.jwtToken));
            setUser(userValue);
            dispatch(setUserData(userObj));

            // EVENT DAY API
            ApiService.getApiData(
              `/eventDay`,
              resp?.signInUserSession?.idToken?.jwtToken
            )
              .then((eventRes) => {
                let body = { data: "false" };
                if (typeof eventRes?.data?.body === "string") {
                  body = JSON.parse(eventRes?.data?.body);
                } else {
                  body = eventRes?.data;
                }

                // Check if event day is true
                if (body?.data === "true") {
                  // GET USERS BY ID API
                  ApiService.getApiData(
                    `/users/id`,
                    resp?.signInUserSession?.idToken?.jwtToken
                  )
                    .then((res) => {
                      setLoading(false);
                      if (res?.data && res?.data?.length > 0) {
                        dispatch(setAboutYouStage(res?.data[0]));
                        // TODO: Add logic to re-direct to specific route
                        if (
                          res?.data[0]?.StatusVal === ADMIN_REGISTERED ||
                          res?.data[0]?.StatusVal === ADMIN_VERIFIED
                        ) {
                          navigate(ROUTES.DOWNLOAD);
                        } else if (
                          res?.data[0]?.StatusVal === STATUS_DOWNLOADED
                        ) {
                          navigate(ROUTES.DOWNLOAD);
                        } else {
                          navigate(ROUTES.DOWNLOAD);
                        }
                      } else {
                        navigate(ROUTES.CONFIRMATION);
                      }
                    })
                    .catch((e) => {
                      setLoading(false);
                      navigate(ROUTES.CONFIRMATION);
                    });
                } else {
                  // Check if event day is false
                  // GET USERS BY ID API
                  ApiService.getApiData(
                    `/users/id`,
                    resp?.signInUserSession?.idToken?.jwtToken
                  )
                    .then((res) => {
                      setLoading(false);
                      if (res?.data && res?.data?.length > 0) {
                        dispatch(setAboutYouStage(res?.data[0]));
                        // TODO: Add logic to re-direct to specific route
                        if (
                          res?.data[0]?.StatusVal === STATUS_REGISTERED ||
                          res?.data[0]?.StatusVal ===
                            STATUS_PENDING_VERIFICATION ||
                          res?.data[0]?.StatusVal ===
                            STATUS_VERIFICATION_FAILED ||
                          res?.data[0]?.StatusVal ===
                            STATUS_VERIFICATION_COMPLETED ||
                          res?.data[0]?.StatusVal === STATUS_PAYMENT_FAILED ||
                          res?.data[0]?.StatusVal === STATUS_PAYMENT_AWAITED
                        ) {
                          navigate(ROUTES.APP_STAGE);
                        } else if (
                          res?.data[0]?.StatusVal === STATUS_PAYMENT_SUCCESS ||
                          res?.data[0]?.StatusVal === STATUS_DOWNLOADABLE
                        ) {
                          navigate(ROUTES.DOWNLOAD);
                        } else if (
                          res?.data[0]?.StatusVal === STATUS_DOWNLOADED &&
                          res?.data[0]?.ContractS3Key
                        ) {
                          navigate(ROUTES.DOWNLOAD);
                        } else if (
                          res?.data[0]?.StatusVal === STATUS_DOWNLOADED &&
                          !res?.data[0]?.ContractS3Key
                        ) {
                          navigate(ROUTES.APP_STAGE);
                        } else if (
                          res?.data[0]?.StatusVal === ADMIN_REGISTERED ||
                          res?.data[0]?.StatusVal === ADMIN_VERIFIED
                        ) {
                          navigate(ROUTES.APP_STAGE);
                        } else {
                          navigate(ROUTES.APP_STAGE);
                        }
                      } else {
                        navigate(ROUTES.CONFIRMATION);
                      }
                    })
                    .catch(() => {
                      setLoading(false);
                      navigate(ROUTES.CONFIRMATION);
                    });
                }
              })
              .catch((e) => {
                setLoading(false);
                console.log(e);
              });
          })
          .catch((err) => {
            setLoading(false);
            setApiError(getError(`${err?.message}`));
            setOpen(true);
          });
      })
      .catch(() => {
        setLoading(false);
        setApiError(OTP_ERROR);
        setOpen(true);
      });
  };

  const handleIconToggle = () => {
    setIconVisible(!iconVisible);
  };

  const isFormValidFunc = () => {
    return (
      user.name &&
      user.lastName &&
      user.email &&
      user.password &&
      user.repassword &&
      isCheckedCheckBox
    );
  };

  return (
    <>
      <SignupForm
        user={user}
        userError={userError}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        signupStage={signupStage}
        setSignupStage={setSignupStage}
        handleCodeSubmit={handleCodeSubmit}
        handleCodeResend={handleCodeResend}
        recaptchaRef={recaptchaRef}
        open={open}
        setOpen={setOpen}
        severity="error"
        message={errApi}
        iconVisible={iconVisible}
        resetIconVisible={resetIconVisible}
        setResetIconVisible={setResetIconVisible}
        handleIconToggle={handleIconToggle}
        isCheckedCheckBox={isCheckedCheckBox}
        setCheckedCheckBox={setCheckedCheckBoxFunc}
        isFormValid={isFormValidFunc}
      />
      {loading && <LoadingScreen open={loading} />}
    </>
  );
}

export default Signup;
