import React, { useState } from "react";
import { countriesCodes } from "../../../utils/countryCode";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "../../Dashboard/index.css";
import "../../Dashboard/Dashboard.css";
import { ROUTES } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setRazorpayAddress } from "../../../redux/users";

function BillingForm({ state, setIsBillingDetailModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const country = useSelector((state) => state?.stage?.hubspotYou?.Country);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [formData, setFormData] = useState({
    country: country || "United States",
    state: "",
    addresslineone: "",
    addresslinetwo: "",
    city: "",
    zipcode: "",
  });

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    if (name === "zipcode") {
      if (country?.toLowerCase() === "india") {
        const regex = /^\d{6}$/;
        if (regex.test(value)) {
          setZipCodeError(false);
        } else {
          setZipCodeError(true);
        }
      }
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = () => {
    if (
      !formData?.state ||
      !formData?.addresslineone ||
      !formData?.addresslinetwo ||
      !formData?.city ||
      !formData?.zipcode ||
      zipCodeError
    ) {
      toast.error("Please fill all details", {
        position: "top-center",
      });
      return;
    }
    dispatch(setRazorpayAddress(formData));
    navigate(ROUTES.PAYMENTOPTION, {
      state: state,
    });
  };

  return (
    <>
      <Modal
        className="modal-box close-none billing-madal"
        open={true}
        onClose={() => setIsBillingDetailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
        <div className="hubspot-form-box">
        <div className="hubspot-inner-box">
          <h3>Company Billing Address</h3>
          <div className="field-row">
            <div className="field-col">
              <label className="field-title">Country</label>
              <select
                name="Country"
                value={country || "United States"}
                className="field-input"
                disabled={true}
              >
                {countriesCodes.map((c) => {
                  return <option value={c?.name}>{c?.name}</option>;
                })}
              </select>
            </div>
            <div className="field-col">
              <label className="field-title">State</label>
              <input
                className="field-input-signup"
                label="state"
                variant="outlined"
                name="state"
                type="text"
                value={formData?.state}
                onChange={handleAddressChange}
              />
            </div>
            <div className="field-col">
              <label className="field-title">Address line 1</label>
              <input
                className="field-input-signup"
                label="Address line 1"
                variant="outlined"
                name="addresslineone"
                type="text"
                value={formData?.addresslineone}
                onChange={handleAddressChange}
              />
            </div>
            <div className="field-col">
              <label className="field-title">Address line 2</label>
              <input
                className="field-input-signup"
                label="Address line 2"
                variant="outlined"
                name="addresslinetwo"
                type="text"
                value={formData?.addresslinetwo}
                onChange={handleAddressChange}
              />
            </div>
            <div className="field-col">
              <label className="field-title">City</label>
              <input
                className="field-input-signup"
                label="City"
                variant="outlined"
                name="city"
                type="text"
                value={formData?.city}
                onChange={handleAddressChange}
              />
            </div>
            <div className="field-col">
              <label className="field-title">Zip Code</label>
              <input
                className="field-input-signup"
                label="Zip Code"
                variant="outlined"
                name="zipcode"
                type="text"
                value={formData?.zipcode}
                onChange={handleAddressChange}
              />
              {zipCodeError && (
                <span className="error-message">Enter valid zip code</span>
              )}
            </div>
            <button className="btn primary-btn" onClick={handleSubmit}>
              Confirm Detail
            </button>
          </div>
        </div>
        </div>
        </div>
      </Modal>
    </>
  );
}

export default BillingForm;
