import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import "./VideoModel.css";

const VideoModel = ({ videoStatus, closeVideo, videoId }) => {
  // console.log(videoId);
  return (
    <>
      <Modal
        className="video-modal-login modal-box"
        open={videoStatus}
        onClose={closeVideo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-dialog">
          {/* <button type="button" className="btn-close"><span className="material-icons-outlined">close</span> Close</button> */}
          <div className="modal-body">
            <iframe
              src={`https://player.vimeo.com/video/${videoId}?badge=0
&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              width="100%"
              height="100%"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Add_case.e3a6cb22f490976ce5f3"
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default VideoModel;
