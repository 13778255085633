import React, { useEffect, useState } from "react";
import ApiService from "../../../service/axiosUtils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants";
import LoadingScreen from "../../LoadingScreen";

function Razorpay({ priceDetail }) {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.users?.user);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const razorpayAddress = useSelector((state) => state?.users?.razorpayAddress);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    displayRazorpay();
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    setLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    ApiService.postApiData(
      `${process.env.REACT_APP_PROD_BASE_URL}/payment/razorpay/createOrder`,
      {
        priceID: priceDetail?.priceIdINR,
        email: userDetails?.attributes?.email,
        name: userDetails?.attributes?.given_name,
        UserId: userDetails?.username,
        DataLimit: priceDetail?.dataLimit,
        TimeLimit: priceDetail?.timeLimit,
        PlanName: priceDetail?.planName,
        LicenseExpDate: aboutYou?.LicenseExpDate || 0,
        MacAddress: hubspotYou?.Mac,
        Address: {
          country: razorpayAddress?.country,
          state: razorpayAddress?.state,
          line1: razorpayAddress?.addresslineone,
          line2: razorpayAddress?.addresslinetwo,
          city: razorpayAddress?.city,
          postal_code: razorpayAddress?.zipcode,
        },
      },
      userDetails?.token
    )
      .then((res) => {
        console.log(res);
        setLoading(false);

        // order_id, amount (in paise) WILL GET FROM CREATE ORDER
        // CREATE ORDER API -> PAY NOW BUTTON
        // {
        //     "response": {
        //         "id": "order_NbWHmvzUc6nyKH",
        //         "entity": "order",
        //         "amount": 2100000,
        //         "amount_paid": 0,
        //         "amount_due": 2100000,
        //         "currency": "INR",
        //         "receipt": "6b5d6cbf-3867-44e5-bc45-6418ab3e2dfd",
        //         "offer_id": null,
        //         "status": "created",
        //         "attempts": 0,
        //         "notes": {
        //             "UserName": "Abhishek Singh",
        //             "Email": "abhishek.singh@gmail.com",
        //             "UserID": "12112bhjb2hjb1",
        //             "PlanName": "bundle"
        //         },
        //         "created_at": 1708079676
        //     }
        // }
        let order_id = res.data.response.id;

        const options = {
          key: "rzp_test_nSLKlf6PRq0I1J", // Enter the Key ID generated from the Dashboard
          amount: res.data.response.amount,
          currency: res.data.response.currency,
          name: "CARBN.AI™",
          description: "Test Transaction",
          image:
            "https://www.clear-trail.com/wp-content/uploads/2024/03/ct-logo.jpg",
          order_id: order_id,
          handler: async function (response) {
            console.log(response);
            // response -> razorpay_payment_id, razorpay_order_id, razorpay_signature
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };

            // AFTER FILLING ALL DETAILS IN RAZORPAY, HANDLER FUNCTION WILL BE CALLED
            // NODE API sessionStatus WITH ABOVE PAYLOAD WILL BE CALLED
            // THIS api will get status - success or failed -> navigate the page to resp. route

            // const result = await axios.post(
            //   "http://localhost:3000/payment/success",
            //   data
            // );

            ApiService.postApiData(
              `${process.env.REACT_APP_PROD_BASE_URL}/payment/razorpay/sessionStatus`,
              data,
              userDetails?.token
            )
              .then((resp) => {
                console.log(resp);
                if (resp?.data?.payment_status === "Success") {
                  navigate(ROUTES.RAZORPAY_SUCCESS, {
                    state: {
                      status: resp?.data?.payment_status,
                    },
                  });
                }
              })
              .catch((e) => {
                toast.error(e?.response?.message, {
                  position: "top-center",
                });
              });
          },
          prefill: {
            name: res.data.response.notes.UserName,
            email: res.data.response.notes.Email,
          },
          notes: {
            address: {
              country: razorpayAddress?.country,
              state: razorpayAddress?.state,
              line1: razorpayAddress?.addresslineone,
              line2: razorpayAddress?.addresslinetwo,
              city: razorpayAddress?.city,
              postal_code: razorpayAddress?.zipcode,
            },
          },
          theme: {
            color: "#f7941d",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.message, {
          position: "top-center",
        });
      });
  }

  return <div>{loading && <LoadingScreen open={loading} />}</div>;
}

export default Razorpay;
