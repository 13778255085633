import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setAboutYouStage } from "../../redux/stages";
import {
  setCountry,
  setFirstTimeUser,
  setUserData,
} from "../../redux/users";
import ApiService from "../../service/axiosUtils";
import {
  ADMIN_REGISTERED,
  ADMIN_VERIFIED,
  ROUTES,
  STATUS_DOWNLOADABLE,
  STATUS_DOWNLOADED,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PENDING_VERIFICATION,
  STATUS_REGISTERED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_VERIFICATION_FAILED,
} from "../../utils/constants";

function PublicComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isloggedIn, setIsloggedIn] = useState(null);

  useEffect(() => {
    Auth.currentUserInfo()
      .then((resp) => {
        Auth.currentAuthenticatedUser()
          .then((res) => {
            // TODO: Add logic to re-direct to specific route
            callEventDayAndCheckApi(res, resp);
          })
          .catch(() => {
            setIsloggedIn(false);
          });
      })
      .catch((e) => console.log(e));
  }, [navigate, dispatch]);

  const callEventDayAndCheckApi = (resp, info) => {
    const userObj = {
      username: resp?.username,
      attributes: info?.attributes,
      token: resp?.signInUserSession?.idToken?.jwtToken,
    };
    dispatch(setUserData(userObj));

    // EVENT DAY API
    ApiService.getApiData(
      `/eventDay`,
      resp?.signInUserSession?.idToken?.jwtToken
    )
      .then((eventRes) => {
        let body = { data: "false" };
        if (typeof eventRes?.data?.body === "string") {
          body = JSON.parse(eventRes?.data?.body);
        } else {
          body = eventRes?.data;
        }
        // Check if event day is true
        if (body?.data === "true") {
          // GET USERS BY ID API
          ApiService.getApiData(
            `/users/id`,
            resp?.signInUserSession?.idToken?.jwtToken
          )
            .then((res) => {
              if (res?.data && res?.data?.length > 0) {
                dispatch(setFirstTimeUser(res?.data[0].FirstTimeUser));
                dispatch(setCountry(res?.data[0].Country));
                dispatch(setAboutYouStage(res?.data[0]));
                // TODO: Add logic to re-direct to specific route
                if (
                  res?.data[0]?.StatusVal === ADMIN_REGISTERED ||
                  res?.data[0]?.StatusVal === ADMIN_VERIFIED
                ) {
                  navigate(ROUTES.DOWNLOAD);
                } else if (res?.data[0]?.StatusVal === STATUS_DOWNLOADED) {
                  navigate(ROUTES.DOWNLOAD);
                } else {
                  navigate(ROUTES.DOWNLOAD);
                }
              } else {
                navigate(ROUTES.CONFIRMATION);
              }
            })
            .catch((e) => {
              navigate(ROUTES.CONFIRMATION);
            });
        } else {
          // Check if event day is false
          // GET USERS BY ID API
          ApiService.getApiData(
            `/users/id`,
            resp?.signInUserSession?.idToken?.jwtToken
          )
            .then((res) => {
              if (res?.data && res?.data?.length > 0) {
                dispatch(setFirstTimeUser(res?.data[0].FirstTimeUser));
                dispatch(setCountry(res?.data[0].Country));
                dispatch(setAboutYouStage(res?.data[0]));
                // TODO: Add logic to re-direct to specific route
                if (
                  res?.data[0]?.StatusVal === STATUS_REGISTERED ||
                  res?.data[0]?.StatusVal === STATUS_PENDING_VERIFICATION ||
                  res?.data[0]?.StatusVal === STATUS_VERIFICATION_FAILED ||
                  res?.data[0]?.StatusVal === STATUS_VERIFICATION_COMPLETED ||
                  res?.data[0]?.StatusVal === STATUS_PAYMENT_FAILED ||
                  res?.data[0]?.StatusVal === STATUS_PAYMENT_AWAITED
                ) {
                  navigate(ROUTES.APP_STAGE);
                } else if (
                  res?.data[0]?.StatusVal === STATUS_PAYMENT_SUCCESS ||
                  res?.data[0]?.StatusVal === STATUS_DOWNLOADABLE
                ) {
                  navigate(ROUTES.DOWNLOAD);
                } else if (
                  res?.data[0]?.StatusVal === STATUS_DOWNLOADED &&
                  res?.data[0]?.ContractS3Key
                ) {
                  navigate(ROUTES.DOWNLOAD);
                } else if (
                  res?.data[0]?.StatusVal === STATUS_DOWNLOADED &&
                  !res?.data[0]?.ContractS3Key
                ) {
                  navigate(ROUTES.APP_STAGE);
                } else if (
                  res?.data[0]?.StatusVal === ADMIN_REGISTERED ||
                  res?.data[0]?.StatusVal === ADMIN_VERIFIED
                ) {
                  navigate(ROUTES.HOW_TO_BEGIN);
                } else {
                  navigate(ROUTES.APP_STAGE);
                }
              } else {
                navigate(ROUTES.CONFIRMATION);
              }
            })
            .catch(() => {
              navigate(ROUTES.HOW_TO_BEGIN);
            });
        }
      })
      .catch((e) => {});
  };

  const handleRender = () => {
    if (isloggedIn === false) {
      return <Outlet />;
    }
  };

  return <>{handleRender()}</>;
}

export default PublicComponent;
