// PROD
const awsExports = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_r24DZ5Crc",
  USER_POOL_APP_CLIENT_ID: "qfaoor6j6qnil4i5uu30o7tj5",
};

// DEV
// const awsExports = {
//   REGION: "us-east-1",
//   USER_POOL_ID: "us-east-1_RiWhBnXtI",
//   USER_POOL_APP_CLIENT_ID: "1dechapgvk15nb9etdumjdn8sd",
// };

// TEST
// const awsExports = {
//   REGION: "us-east-1",
//   USER_POOL_ID: "us-east-1_NLoREH2nW",
//   USER_POOL_APP_CLIENT_ID: "45tbpiq1g2vcr2lnqban5ulvfq",
// };

export { awsExports };
