import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import { ROUTES } from "../../utils/constants";

const FreemiumSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [counter, setCounter] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    if (state?.status === "freemium") {
      let elementsNotification = document.getElementById('notificationstrip-id');
      elementsNotification.classList.add("d-none");
      setTimeout(() => {
        navigate(ROUTES.DOWNLOAD);
        let elementsNotification = document.getElementById('notificationstrip-id');
        elementsNotification.classList.remove("d-none");
      }, 15000);
      
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="pay-container-box">
      <div className="container">
      <div className="pay-inner-box">
        {state?.status === "freemium" && (
          <>
            <h2 className={`pay-status-message pay-title`}>
              License is getting ready !
            </h2>
            <p className="message">
              You will be redirected to download exe page shortly. (
              {counter >= 0 ? counter : ""})
            </p>
            {counter < 1 ? (
              <NavLink to={ROUTES.DOWNLOAD} className={"pay-msg"}>
                &#8209; Go to Download
              </NavLink>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
    </div>
  );
};

export default FreemiumSuccess;
