import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_REGISTERED,
  ADMIN_VERIFIED,
  ROUTES,
  STATUS_DOWNLOADABLE,
  STATUS_DOWNLOADED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PENDING_VERIFICATION,
  STATUS_REGISTERED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_VERIFICATION_FAILED,
} from "../../utils/constants";
import "./Confirmation.css";
import LoadingScreen from "../LoadingScreen";

function Footer() {
  const navigate = useNavigate();

  const attributes =
    useSelector((state) => state?.users?.user?.attributes) || "";
  const reasonCustom = attributes["custom:reason"] || "";
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const eventDay = useSelector((state) => state?.users?.eventDay);
  const loading = useSelector((state) => state?.users?.confirmLoading);

  useEffect(() => {
    if (aboutYou && eventDay) {
      if (eventDay === "true") {
        if (
          aboutYou?.StatusVal === ADMIN_REGISTERED ||
          aboutYou?.StatusVal === ADMIN_VERIFIED
        ) {
          navigate(ROUTES.DOWNLOAD);
        } else if (aboutYou?.StatusVal === STATUS_DOWNLOADED) {
          navigate(ROUTES.DOWNLOAD);
        }
      } else {
        if (
          aboutYou?.StatusVal === ADMIN_REGISTERED ||
          aboutYou?.StatusVal === ADMIN_VERIFIED
        ) {
          navigate(ROUTES.HOW_TO_BEGIN);
        } else if (
          aboutYou?.StatusVal === STATUS_REGISTERED ||
          aboutYou?.StatusVal === STATUS_PENDING_VERIFICATION ||
          aboutYou?.StatusVal === STATUS_VERIFICATION_FAILED ||
          aboutYou?.StatusVal === STATUS_VERIFICATION_COMPLETED ||
          aboutYou?.StatusVal === STATUS_PAYMENT_FAILED ||
          aboutYou?.StatusVal === STATUS_PAYMENT_SUCCESS ||
          aboutYou?.StatusVal === STATUS_DOWNLOADABLE ||
          aboutYou?.StatusVal === STATUS_DOWNLOADED
        ) {
          navigate(ROUTES.LANDING_PAGE);
        }
      }
    }
  }, [aboutYou]);

  return (
    <>
      <div className="thank-box inner-content-box">
        <div className="container">
          <div className="confirmation-details text-center">
            {!reasonCustom && (
              <div id="confirm-heading">
                <h2 className="text-center">Thank you for signing up!</h2>
                <p>
                  Profile verification is underway. In the meantime, feel free
                  to explore our full suite of solutions.
                </p>
              </div>
            )}

            {reasonCustom && (
              <p id="confirm-heading">
                Your profile verification is currently on hold due to reason -{" "}
                {reasonCustom}. If you have any questions or concerns, please
                contact our support team at{" "}
                <a
                  href="javascript:void(0)"
                  onClick={() => (window.location = "mailto:support@carbn.ai")}
                >
                  support@carbn.ai
                </a>
                .
              </p>
            )}
            {loading && <LoadingScreen open={loading} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
