import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import users from "./users";
import stages from "./stages";
import prices from "./prices";
import notifications from "./notifications";
import payments from "./payments";

const reducer = combineReducers({
  users: users,
  stage: stages,
  prices: prices,
  notifications : notifications,
  payments : payments
});

const store = configureStore({
  reducer,
  devTools: true,
});

export default store;
