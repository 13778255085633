import React from "react";
import { useLocation } from "react-router-dom";
import PaymentByStripe from "./PaymentByStripe";
import Razorpay from "./Razorpay";
import { useSelector } from "react-redux";

const PaymentOptions = () => {
  const location = useLocation();
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);

  const { state } = location;
  return (
    <>
      {hubspotYou?.Country === "India" ? (
        <Razorpay priceDetail={state} />
      ) : (
        <PaymentByStripe priceDetail={state}></PaymentByStripe>
      )}
    </>
  );
};

export default PaymentOptions;
