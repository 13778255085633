import React from "react";
import Letter from "../../assets/letter.jpg";
import "./SignLetter.css";
import "./StageOneAboutYou.css";

const SignLetter = ({ downloadSignLetter }) => {
  return (
    <>
      <div className="container">
        <div className="letter-main-box d-md-flex justify-space-between">
          <div className="letter-left">
            <h2>We need some documentation from your organisation.</h2>
            <p>
              Please have the head of your department sign this letter of
              undertaking.
            </p>
            <div className="letter-info">
              <label>Here's how:</label>
              <ul>
                <li>
                  You can download, get the letter signed and re-upload for
                  verification.
                </li>
                {/* <li>You can e-sign it and wait for the verification.</li> */}
              </ul>
            </div>

            <div className="letter-button-row">
              <button
                type="button"
                className="btn primary-border-btn"
                onClick={downloadSignLetter}
              >
                Download letter
              </button>
            </div>
            {/* <Button id="btn-e-sign" variant="outlined">
          E-Sign
        </Button> */}
          </div>
          <div className="letter-right">
            <img
              src={Letter}
              // width={"70%"}
              // height={"70%"}
              alt="e-sign-letter"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignLetter;
