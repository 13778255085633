import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ROUTES,
  STATUS_DOWNLOADABLE,
  STATUS_DOWNLOADED,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
  USER_APPLICANT,
  USER_REJECTED,
} from "../../utils/constants";
import File from "../../assets/Document-01.svg";
import downloadSvg from "../../assets/setupIcon.svg";
import User from "../../assets/user.svg";
// import video1 from "../../assets/videos/Add_case.mp4";
// import video2 from "../../assets/videos/Connections.mp4";
// import video3 from "../../assets/videos/Content_analysis.mp4";
// import video4 from "../../assets/videos/Pattern_life.mp4";
// import video5 from "../../assets/videos/Reports.mp4";
// import video6 from "../../assets/videos/Summary_page.mp4";
import {
  compareDate,
  formatBytes,
  getLicenseDate,
  getLicenseDisable,
  getStatusValue,
} from "../../utils/utils";
import "./Dashboard.css";
import ModalDashboard from "./ModalDashboard";
import chatIicon from "../../assets/chat-icon.png";
import mailIicon from "../../assets/mail-icon.png";
import callIicon from "../../assets/call-icon.png";

//import videoThub from "../../assets/video-thub.jpg";
//import playIcon from "../../assets/play-icon.png";
import VideoModel from "../../utils/VideoModel/VideoModel";
import InvoiceModal from "./InvoiceModal";

import videoThub1 from "../../assets/Add_case_new.jpg";
import videoThub2 from "../../assets/flagged_items.jpeg";
import videoThub3 from "../../assets/summary_page.jpeg";
import videoThub4 from "../../assets/Pattern_of_life.jpeg";
import videoThub5 from "../../assets/connections.jpeg";
import videoThub6 from "../../assets/content_analysis.jpeg";
import videoThub7 from "../../assets/transactions.jpeg";
import videoThub8 from "../../assets/Global_filters.jpeg";
import videoThub9 from "../../assets/reports.jpeg";
import playIcon from "../../assets/play-icon.png";
import installationThumb from "../../assets/installation-thumb.jpg";

import indiavideoThub1 from "../../assets/india-thumb-1.jpg";
import indiavideoThub2 from "../../assets/india-thumb-2.jpg";
import indiavideoThub3 from "../../assets/india-thumb-3.jpg";
import indiavideoThub4 from "../../assets/india-thumb-4.jpg";
import indiavideoThub5 from "../../assets/india-thumb-5.jpg";
import indiavideoThub6 from "../../assets/india-thumb-6.jpg";

const Dashboard = ({
  attributes,
  aboutYou,
  totalSize,
  progress,
  downloadEnable,
  pleaseWaitText,
  downloadZipWithTxtFile,
  planName,
  downloadLetter,
  downloadLicenseKey,
  downloadInvoice,
  openModal,
  licenseUrl,
  handleModalClose,
  progressBar,
  downloadClicked,
  showHistoryInvoice,
  handleInvoiceModal,
  openInvoiceModal,
  invoiceData,
  handleModalOpen,
  invoicePreviewModal,
  handleInvoicePreviewModal,
  invoiceUrl,
}) => {
  const vedioIds = [
    "911195430",
    "911195760",
    "911195986",
    "911217867",
    "911195645",
    "911195704",
    "911195365",
    "911195900",
    "911195923",
    "912260300",
  ];

  const indiaVedioIds = [
    "1034111302",
    "1034111370",
    "1034111346",
    "1034111323",
    "1034113237",
    "1034113516",
  ];

  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [videoId, setVideoId] = useState(0);
  const eventDay = useSelector((state) => state?.users?.eventDay);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const profile = useSelector(
    (state) => state?.users?.user?.attributes?.profile
  );
  const [videoStatus, setVideoStatus] = useState(false);

  const closeVideo = () => {
    setVideoStatus(false);
  };
  const openVideo = (index) => {
    setVideoId(vedioIds[index]);
    setVideoStatus(true);
  };

  const indiaopenVideo = (index) => {
    setVideoId(indiaVedioIds[index]);
    setVideoStatus(true);
  };

  const handleAccordion = (index) => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="profile-information-box">
        <div className="container d-md-flex justify-space-between">
          <div className="info-col">
            <div className="notification-header-profile">
              <img src={User} alt="User" />
            </div>
            <div className="user-name">
              <h4>Hi {attributes?.given_name},</h4>
              <p>{hubspotYou?.Desgn}</p>
            </div>

            <div className="user-detail">
              <ul>
                {hubspotYou?.PhNo && (
                  <li>
                    <strong>Mobile:</strong> {hubspotYou?.PhNo}
                  </li>
                )}
                {aboutYou?.Email && (
                  <li>
                    <strong>Email:</strong> {aboutYou?.Email}
                  </li>
                )}
                {hubspotYou?.Org && (
                  <li>
                    <strong>Organization:</strong> {hubspotYou?.Org}
                  </li>
                )}
                {hubspotYou?.Dept && (
                  <li>
                    <strong>Department:</strong> {hubspotYou?.Dept}
                  </li>
                )}
                {hubspotYou?.Mac && (
                  <li>
                    <strong>MAC Address:</strong> {hubspotYou?.Mac}
                  </li>
                )}
                {(aboutYou?.StatusVal || profile) && (
                  <li>
                    <strong>Status:</strong>{" "}
                    {getStatusValue(aboutYou?.StatusVal, profile)}
                  </li>
                )}
              </ul>
            </div>
          </div>

          {attributes?.profile !== USER_APPLICANT &&
            aboutYou?.StatusVal &&
            attributes?.profile !== USER_REJECTED && (
              <div className="info-col border-none padding-none">
                <div className="user-select-plan">
                  <div className="price">
                    {aboutYou?.Amount}
                    {aboutYou?.Plan &&
                      aboutYou?.StatusVal !== STATUS_PAYMENT_AWAITED &&
                      aboutYou?.StatusVal !== STATUS_PAYMENT_FAILED && (
                        <span>Your plan is ACTIVE</span>
                      )}
                    {aboutYou?.Plan &&
                      aboutYou?.StatusVal === STATUS_PAYMENT_AWAITED && (
                        <span>Payment pending</span>
                      )}
                    {aboutYou?.Plan &&
                      aboutYou?.StatusVal === STATUS_PAYMENT_FAILED && (
                        <span>Payment attempt failed</span>
                      )}
                  </div>
                  <p>
                    {aboutYou?.LicenseExpDate &&
                      `Ends on ${getLicenseDate(aboutYou?.LicenseExpDate)} `}
                  </p>

                  <div className="selected-plan">
                    {aboutYou?.Plan}{" "}
                    <button
                      className="btn plan-btn"
                      onClick={() => navigate(ROUTES.PRICING)}
                      disabled={
                        eventDay === "true" ||
                        (eventDay === "false" && !aboutYou?.Plan)
                      }
                    >
                      Upgrade Plan
                    </button>
                  </div>
                </div>

                {aboutYou && aboutYou?.TransactionID && (
                  <div className={aboutYou?.Plan=='Freemium' ? 'd-none contract-box d-flex align-center' :  'contract-box d-flex align-center'}>
                    <img src={File} alt="" />
                    <p>Invoice</p>
                    {/* <span onClick={downloadInvoice}>
                      <a>Preview</a>
                    </span> */}
                    <button
                      className="history-link"
                      onClick={showHistoryInvoice}
                    >
                      History
                    </button>
                  </div>
                )}
                <br />
                {aboutYou && aboutYou?.ContractS3Key && (
                  <div className="contract-box d-flex align-center">
                    <img src={File} alt="" />
                    <p>Signed LOA</p>
                    <button className="history-link" onClick={downloadLetter}>
                      Preview
                    </button>
                  </div>
                )}
              </div>
            )}

          {attributes?.profile !== USER_APPLICANT &&
            aboutYou?.StatusVal &&
            attributes?.profile !== USER_REJECTED && (
              <div className="info-col text-center">
                <div className="download-info">
                  <img src={downloadSvg} alt="" />
                  <div className="download-info-left">
                    CARBN.AI<sup>TM</sup> Installer <span>Application</span>
                  </div>
                  <div className="download-info-right">
                    {totalSize > 0 && formatBytes(totalSize, 2)}
                  </div>
                </div>

                <button
                  type="button"
                  className="btn primary-btn mb-15"
                  onClick={downloadZipWithTxtFile}
                  disabled={
                    (progressBar > 0 && progressBar <= 100) ||
                    (eventDay === "true" &&
                      aboutYou?.FirstTimeUser === false) ||
                    (eventDay === "false" && aboutYou?.FirstTimeUser === false && planName=="Freemium") ||
                    (eventDay === "false" &&
                      aboutYou?.StatusVal !== STATUS_DOWNLOADABLE &&
                      aboutYou?.StatusVal !== STATUS_DOWNLOADED)
                  }
                >
                  Download
                </button>
                
                {progressBar > 0 && progressBar <= 100 && (
                  <div className="download-progress-bar exe-progress-bar profile-download-progress">
                    <span style={{ width: `${progressBar}%` }} className="stripes">
                      <div className="progress-count">{progressBar?.toFixed(2)}%</div>
                    </span>
                  </div>
                )}
                <br />
                <div>
                  {pleaseWaitText && (
                    <p>We are preparing your Zip file. Please wait...</p>
                  )}
                </div>
                <button
                  className="download-license-text"
                  onClick={downloadLicenseKey}
                  style={{
                    pointerEvents:
                      (progressBar > 0 && progressBar <= 100) ||
                      (eventDay === "false" &&
                        aboutYou?.StatusVal !== STATUS_DOWNLOADABLE &&
                        aboutYou?.StatusVal !== STATUS_DOWNLOADED) ||
                      compareDate(aboutYou?.LicenseExpDate)
                        ? "none"
                        : "",
                  }}
                  disabled={
                    !attributes.sub ||
                    (progressBar > 0 && progressBar <= 100) ||
                    (eventDay === "false" &&
                      aboutYou?.StatusVal !== STATUS_DOWNLOADABLE &&
                      aboutYou?.StatusVal !== STATUS_DOWNLOADED) ||
                    compareDate(aboutYou?.LicenseExpDate)
                  }
                >
                  Download license key
                </button>
                {/* {hubspotYou?.Mac && (
                <p className="run-info">
                  Please run the application on the same system registered
                  before ({hubspotYou?.Mac})
                </p>
              )} */}
                {/* <button
                className="download-license-text"
                onClick={downloadLicenseKey}
                disabled={getLicenseDisable(eventDay, aboutYou)}
              >
                Download license key
              </button> */}
              </div>
            )}
        </div>
      </div>

      {aboutYou?.StatusVal &&
      <div className="download-main-box">
        <div className="download-tutorial-box">
          <div className="container">
            <div className="journey-title">
              <h3>
                How to install CARBN.AI<sup>TM</sup>
              </h3>
            </div>

            <div className="tutorial-col install-video-call">
              <img src={installationThumb} alt="Video" />
              <button
                type="button"
                className="play-btn"
                onClick={() => openVideo(9)}
              >
                <img src={playIcon} alt="Play Icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      }

      {aboutYou?.StatusVal &&
      <div className="cover-box">
        
          <>
            {hubspotYou?.Country && hubspotYou?.Country?.toLowerCase() === "india" ?

            <div className="journey-box">
            <div className="container">
              <div className="journey-title">
                <h3>Video tutorials to help you get started</h3>
              </div>

              <div className="tutorial-box-download">
                <div className="tutorial-col">
                  <img src={indiavideoThub1} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => indiaopenVideo(0)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>

                <div className="tutorial-col">
                  <img src={indiavideoThub2} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => indiaopenVideo(1)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>

                <div className="tutorial-col">
                  <img src={indiavideoThub3} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => indiaopenVideo(2)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>

                <div className="tutorial-col">
                  <img src={indiavideoThub4} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => indiaopenVideo(3)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>

                <div className="tutorial-col">
                  <img src={indiavideoThub5} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => indiaopenVideo(4)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>

                <div className="tutorial-col">
                  <img src={indiavideoThub6} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => indiaopenVideo(5)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>

                

                
              </div>
            </div>
            </div>

            :


            <div className="journey-box">
              <div className="container">
                <div className="journey-title">
                  <h3>Video tutorials to help you get started</h3>
                </div>

                <div className="tutorial-box-download">
                  <div className="tutorial-col">
                    <img src={videoThub1} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(0)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub2} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(1)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub3} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(2)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub4} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(3)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub5} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(4)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub6} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(5)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub7} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(6)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub8} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(7)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>

                  <div className="tutorial-col">
                    <img src={videoThub9} alt="Video" />
                    <button
                      type="button"
                      className="play-btn"
                      onClick={() => openVideo(8)}
                    >
                      <img src={playIcon} alt="Play Icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

        }
      </>
       
       

        {/* start */}
        <div className="journey-box">
          <div className="container">
            <div className="support-col-box d-md-flex">
              <div className="support-col">
                <div className="support-icon">
                  <img src={chatIicon} alt="" />
                </div>
                <h4>Chat</h4>
                <p>24/7 chat assistance</p>
              </div>

              <div className="support-col">
                <a
                  href="javascript:void(0)"
                  onClick={() => (window.location = "mailto:support@carbn.ai")}
                >
                  <div className="support-icon">
                    <img src={mailIicon} alt="" />
                  </div>
                  <h4>Email</h4>
                </a>
                <p>9 AM to 8 PM, Mon to Sat</p>
              </div>

              <div className="support-col">
                <a
                  href="javascript:void(0)"
                  onClick={() => window.open("tel:+91 7389905551")}
                >
                  <div className="support-icon">
                    <img src={callIicon} alt="" />
                  </div>
                  <h4>Call</h4>
                </a>
                <p>9 AM to 6 PM, Mon to Fri</p>
              </div>
            </div>
          </div>
        </div>
        {/* end */}

        {/* start */}
        <div className="journey-box text-center">
          <div className="container">
            <div className="journey-title">
              <h3>Frequently Asked Questions</h3>
            </div>

            {/* accordion box start */}
            <div
              className="accordion wow fadeInUp"
              data-wow-delay="0.2s"
              id="FAQ_accordion"
            >
              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-1"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-1"
                  >
                    I've downloaded the executable file, now what?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-1"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-1"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    Double click or run the downloaded .exe file as an
                    Administrator and follow the on-screen instructions to
                    install the CARBN.AI<sup>TM</sup>.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-2"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-2"
                  >
                    How do I install CARBN.AI<sup>TM</sup>?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-2"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-2"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      To begin the installation of CARBN.AI<sup>TM</sup>{" "}
                      software, follow these steps:
                    </p>
                    <ul>
                      <li>
                      Double click or run the downloaded .exe file as an Administrator. The{" "}
                        <b>
                          Welcome to CARBN.AI<sup>TM</sup> Setup
                        </b>{" "}
                        dialog appears. This window will check the system compatibilities including Operating System, CPU threads and RAM, before proceeding with the installation.
                      </li>
                      <li>
                        Click <b>Next</b>. The <b>License Agreement</b>{" "}
                        dialog appears. Read and click the <b>I Agree</b> button to accept the End User License Agreement. The <b>Installation and Data Path Locations</b> dialog appears.
                      </li>
                      <li>
                      The default installation directory for CARBN.AI™ is C:\Program Files\ which is non-editable. Choose a data path directory, which is by default set to C:\. This path is the drive where you want the data to be stored.
                      </li>
                      
                      <li>
                      Click <b>Install</b>. The next window will verify disk space for both installation and data drives.
                      </li>
                      <li>
                      If the prerequisites for the installation are met, the installation would proceed to install the CARBN.AI
                        <sup>TM</sup> software.
                      </li>
                      <li>
                      A window will appear after the installation is complete. Select the option for rebooting your system and click <b>Finish</b>. The CARBN.AI<sup>TM</sup> software is successfully installed in your system now.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-3"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-3"
                  >
                    What is CARBN.AI<sup>TM</sup>?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-3"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-3"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> is an offline IP data decoding and
                    analysis solution that helps analysts uncover correlations,
                    build a suspect’s profile, and connect all the data points
                    across cleartext, encrypted and unknown data transactions.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-4"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-4"
                  >
                    How does CARBN.AI<sup>TM</sup> work?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-4"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-4"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> works by processing and analyzing
                    imported PCAP data. Once the data has been decoded, CARBN.AI
                    <sup>TM</sup>'s analysis engine works to uncover patterns, correlations, and links between distinct data
points and displays the results in a user-friendly interface so that analysts can get useful insights about targets.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-5"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-5"
                  >
                    Are there specific system requiremens for the CARBN.AI
                    <sup>TM</sup> installation?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-5"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-5"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                    Yes, CARBN.AI<sup>TM</sup> requires the following system specifications:
                    </p>
                    <ul>
                      <li>
                        <b>CPU</b>: 8HT.
                        (Professional or Enterprise)
                      </li>
                      <li>
                        <b>RAM</b>: 16 GB DDR4.
                      </li>
                      <li>
                        <b>Disk Storage</b>: 1 x 512GB NVMe SSD
                      </li>
                      <li>
                        <b>NIC</b>: 1 X GE
                      </li>
                      <li>
                        <b>Operating System (OS)</b>: Windows 10 or 11 64-bit OS (Professional or Enterprise)
                      </li>
                    </ul>
                    <p>
                    Meeting these specifications will ensure that your system performs optimally and is compatible with CARBN.AI
                      <sup>TM</sup>.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-6"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-6"
                  >
                    Is technical support available during the installation process?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-6"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-6"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                  We provide a Readme file along with the installer to assist you in a smooth installation experience. However, if you encounter any issues or have questions, please connect with our Technical Operations Support available 8/5 through the chat option available on CARBN.AI<sup>TM</sup> microsite. For any additional queries, please call <a href="tel:8062281345">+91 8062281345</a> or email at <a href="mailto:support@carbn.ai">support@carbn.ai</a>.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-7">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-7"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-7"
                  >
                    Can I choose a custom installation directory, If not why?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-7"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-7"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      No, custom installation directories are not supported.
                    </p>
                    <p>
                      The installation is done at the default path - C:\Program
                      Files. This default location is necessary as certain
                      essential services need to be installed in Program Files
                      for the proper functioning of CARBN.AI<sup>TM</sup>.
                      Deviating from the default path may lead to system
                      instability. Therefore, the installation directory is
                      predefined and non-editable to ensure the stability and
                      integrity of the software on your system.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-8">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-8"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-8"
                  >
                    What is a letter of authorisation?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-8"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-8"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    A letter of verification is a written communication that is
                    requested before providing CARBN.AI<sup>TM</sup>{" "}
                    downloadable to confirm the authenticity and legitimacy of a
                    customer/agency/organization. This letter is provided by
                    ClearTrail that owns the CARBN.AI<sup>TM</sup> software, to
                    be signed and shared back by the
                    customer/agency/organization.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-9">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-9"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-9"
                  >
                    Is there a demo or trial version available?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-9"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-9"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    Yes, a freemium model is available to try for 1 month with a
                    data limit of up to 1 GB.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-10">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-10"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-10"
                  >
                    Is this software compatible with other operating Systems
                    besides Windows?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-10"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-10"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    At present, it is compatible only with Windows 10, 11 Pro
                    and enterprise versions with 64-bit system type.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-11">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-11"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-11"
                  >
                    What should I do if I experience technical issues?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-11"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-11"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      If you encounter technical issues, please contact our
                      support team for assistance and follow these steps:
                    </p>
                    <ul>
                      <li>
                        Navigate to the CARBN.AI<sup>TM</sup> user profile
                        section.
                      </li>
                      <li>
                        Click the <b>Product Logs</b> option to download the log
                        details.
                      </li>
                      <li>
                        Provide these log details to the technical support team.
                        This information will assist our support team in quick
                        diagnosing and addressing the specific issues you are
                        facing.
                      </li>
                    </ul>
                    <p>
                      <b>NOTE</b>: These log details don’t contain any sensitive
                      information of your data.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-12">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-12"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-12"
                  >
                    Why is the website not responding?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-12"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-12"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>Reasons for website unresponsiveness could include:</p>
                    <ul>
                      <li>
                        Network issues: Check your internet connection to ensure
                        it's stable.
                      </li>
                      <li>
                        Server overload: High server demand may cause temporary
                        unresponsiveness.
                      </li>
                      <li>
                        System maintenance: Scheduled maintenance might
                        temporarily impact website availability.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-13">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-13"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-13"
                  >
                    What do I do if I encounter an error message?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-13"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-13"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    Look for the solution in the Help section or try asking the
                    chatbot.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-14">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-14"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-14"
                  >
                    What types of licenses are available?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-14"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-14"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      <b>Freemium</b>: This license allows users to access a
                      basic version of CARBN.AI<sup>TM</sup> without charge. The
                      complimentary version serves as an entry point for users
                      to comprehend the core value of the product without a
                      financial commitment. Qualification for this option
                      requires at least a DSP level letter.
                    </p>
                    <p>
                      <b>Bundled Credit Pack</b>: Under this license, users can
                      make upfront purchases of credit packs. These credits
                      directly correspond to the volume of data processed by the
                      system, deducting a specific number of credits from the
                      pack for each transaction. Once the credits are depleted,
                      users have the option to acquire additional packs as
                      needed.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-15">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-15"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-15"
                  >
                    How is the pricing structured?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-15"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-15"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      CARBN.AI<sup>TM</sup> license pricing is structured as
                      shown
                    </p>
                    <div className="accordion-table">
                      {hubspotYou?.Country?.toLowerCase() === "india" ? (
                        <table>
                          <tr>
                            <td>Pricing</td>
                            <td>Free</td>
                            <td>&#8377;1,49,999 + GST</td>
                            <td>&#8377;2,49,999 + GST</td>
                            <td>&#8377;3,99,999 + GST</td>
                          </tr>

                          <tr>
                            <td>Data Limit</td>
                            <td>Up to 2GB</td>
                            <td>100 GB one-time credit</td>
                            <td>200 GB one-time credit</td>
                            <td>300 GB one-time credit</td>
                          </tr>

                          <tr>
                            <td>Time Limit</td>
                            <td>1 month</td>
                            <td>Valid up to 3 months</td>
                            <td>Valid up to 6 months</td>
                            <td>Valid up to 12 months</td>
                          </tr>
                        </table>
                      ) : (
                        <table>
                          <tr>
                            <td>Pricing</td>
                            <td>Free</td>
                            <td>$1,999</td>
                            <td>$2,999</td>
                            <td>$4,999</td>
                          </tr>

                          <tr>
                            <td>Data Limit</td>
                            <td>Up to 2GB</td>
                            <td>100 GB one-time credit</td>
                            <td>200 GB one-time credit</td>
                            <td>300 GB one-time credit</td>
                          </tr>

                          <tr>
                            <td>Time Limit</td>
                            <td>1 month</td>
                            <td>Valid up to 3 months</td>
                            <td>Valid up to 6 months</td>
                            <td>Valid up to 12 months</td>
                          </tr>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-16">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-16"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-16"
                  >
                    How does CARBN.AI<sup>TM</sup>'s pricing work?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-16"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-16"
                  data-bs-parent="#FAQ_accordion"
                >
                  {hubspotYou?.Country?.toLowerCase() === "india" ? (
                    <div className="accordion-body">
                      <p>
                        Users can choose the pricing plan that aligns with their
                        needs, considering factors such as data requirements and
                        the desired duration of access. The one-time credit
                        provided with each paid version allows users to consume
                        the allotted data over the specified timeframe.
                      </p>
                      <p>
                        Pricing details are structured into different plans with
                        varying data limits and durations:
                      </p>

                      <ul>
                        <li>
                          <b>
                            Free Version with Data Limit up to 2GB for 1 month:
                          </b>
                          <ul>
                            <li>
                              Description: This is a basic version of the
                              software provided at no cost.
                            </li>
                            <li>
                              Data Limit: Users can utilize the software with a
                              data limit of up to 2GB.
                            </li>
                            <li>
                              Duration: The free version is valid for 1 month.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            &#8377;1,49,999 + GST Version with 100 GB one-time
                            credit valid up to 3 months:
                          </b>
                          <ul>
                            <li>
                              Description: This is a paid version of the
                              software with enhanced features.
                            </li>
                            <li>
                              Data Limit: Users receive a one-time credit of 100
                              GB to use within the allotted period.
                            </li>
                            <li>
                              Duration: The &#8377;1,49,999 + GST version is
                              valid for 3 months from the date of purchase.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            &#8377;2,49,999 + GST Version with 200 GB one-time
                            credit valid up to 6 months:
                          </b>
                          <ul>
                            <li>
                              Description: A higher tier paid version with
                              increased data allowance and extended validity.
                            </li>
                            <li>
                              Data Limit: Users receive a one-time credit of 200
                              GB for usage within the specified timeframe.
                            </li>
                            <li>
                              Duration: The &#8377;2,49,999 + GST version is
                              valid for 6 months from the date of purchase.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            &#8377; 3,99,999 + GST Version with 300 GB one-time
                            credit valid up to 12 months:
                          </b>
                          <ul>
                            <li>
                              Description: The premium version of the software
                              with the highest data limit and longest validity.
                            </li>
                            <li>
                              Data Limit: Users are granted a one-time credit of
                              300 GB for usage throughout the designated period.
                            </li>
                            <li>
                              Duration: The &#8377; 3,99,999 + GST version is
                              valid for 12 months from the date of purchase.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="accordion-body">
                      <p>
                        Users can choose the pricing plan that aligns with their
                        needs, considering factors such as data requirements and
                        the desired duration of access. The one-time credit
                        provided with each paid version allows users to consume
                        the allotted data over the specified timeframe.
                      </p>
                      <p>
                        Pricing details are structured into different plans with
                        varying data limits and durations:
                      </p>

                      <ul>
                        <li>
                          <b>
                            Free Version with Data Limit up to 2GB for 1 month:
                          </b>
                          <ul>
                            <li>
                              Description: This is a basic version of the
                              software provided at no cost.
                            </li>
                            <li>
                              Data Limit: Users can utilize the software with a
                              data limit of up to 2GB.
                            </li>
                            <li>
                              Duration: The free version is valid for 1 month.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            $1999 Version with 100 GB one-time credit valid up
                            to 3 months:
                          </b>
                          <ul>
                            <li>
                              Description: This is a paid version of the
                              software with enhanced features.
                            </li>
                            <li>
                              Data Limit: Users receive a one-time credit of 100
                              GB to use within the allotted period.
                            </li>
                            <li>
                              Duration: The $1999 version is valid for 3 months
                              from the date of purchase.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            $2999 Version with 200 GB one-time credit valid up
                            to 6 months:
                          </b>
                          <ul>
                            <li>
                              Description: A higher tier paid version with
                              increased data allowance and extended validity.
                            </li>
                            <li>
                              Data Limit: Users receive a one-time credit of 200
                              GB for usage within the specified timeframe.
                            </li>
                            <li>
                              Duration: The $2999 version is valid for 6 months
                              from the date of purchase.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            $4999 Version with 300 GB one-time credit valid up
                            to 12 months:
                          </b>
                          <ul>
                            <li>
                              Description: The premium version of the software
                              with the highest data limit and longest validity.
                            </li>
                            <li>
                              Data Limit: Users are granted a one-time credit of
                              300 GB for usage throughout the designated period.
                            </li>
                            <li>
                              Duration: The $4999 version is valid for 12 months
                              from the date of purchase.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-17">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-17"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-17"
                  >
                    Is CARBN.AI<sup>TM</sup> free to use?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-17"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-17"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      Yes, there is a free version of the CARBN.AI<sup>TM</sup>{" "}
                      available. However, it's important to note the specific
                      terms and limitations associated with the free version.
                      Here are the key details:
                    </p>
                    <p>
                      <b>Free Version:</b>
                    </p>
                    <ul>
                      <li>Data Limit: Up to 2GB.</li>
                      <li>Duration: Valid for 1 month.</li>
                    </ul>
                    <p>
                      While the free version is accessible without a monetary
                      cost, users are bound by the 2GB data limit and the
                      1-month duration. If users require more data or an
                      extended duration, they may consider upgrading to one of
                      the paid versions with higher data limits and longer
                      validity periods.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-18">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-18"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-18"
                  >
                    How does the freemium plan work?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-18"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-18"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    Once you sign up, you have 1 month to use CARBN.AI
                    <sup>TM</sup> free of charge. You don’t need to provide your
                    credit/debit card details until the trial period is over.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-19">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-19"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-19"
                  >
                    What payment methods are accepted?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-19"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-19"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>Accepted payment methods for license purchase are:</p>
                    <p>
                      <b>Offline</b>: We offer the option to receive an invoice
                      based on provided bank details, allowing users to make
                      payments later through banking channels or online
                      transactions.
                    </p>
                    <p>
                      <b>Online</b>: Payment can be made via online debit or
                      credit cards.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-20">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-20"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-20"
                  >
                    Are there any hidden fees?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-20"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-20"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    No, there are no hidden fees.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-21">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-21"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-21"
                  >
                    What happens when I hit my usage limits?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-21"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-21"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    When you reach your usage limits, a warning message will be
                    displayed to alert you about the constraints. Prior to
                    reaching these limits, users have the option to purchase
                    upgrade plans. Acquiring a new plan provides an invoice,{" "}
                    <span className="text-primary">
                      a downloadable .exe file
                    </span>
                    , and a new license key.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-22">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-22"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-22"
                  >
                    Is CARBN.AI<sup>TM</sup> an online or offline software?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-22"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-22"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> is primarily designed as offline
                    software; nevertheless, it is also capable of functioning
                    within an online environment.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-23">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-23"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-23"
                  >
                    Can I upgrade or downgrade my license?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-23"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-23"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                  No, there is no facility to downgrade the CARBN.AI
                    <sup>TM</sup> license. But you can always upgrade your license with higher data limits and longer validity periods.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-24">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-24"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-24"
                  >
                    Can I install CARBN.AI<sup>TM</sup> on multiple devices with
                    the same license key?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-24"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-24"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    No, a single license key cannot be used to install the
                    CARBN.AI<sup>TM</sup> software. Since the license is MAC
                    bind, it can only be used on one device.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-25">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-25"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-25"
                  >
                    What should I do if CARBN.AI<sup>TM</sup> installation is
                    successful and CARBN.AI<sup>TM</sup> services are not up?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-25"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-25"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      Check that the following services are properly functioning
                      in the background.
                    </p>
                    <ul>
                      <li>Maven1</li>
                      <li>RabbitMQ</li>
                      <li>
                        CARBN.AI<sup>TM</sup> Core Service
                      </li>
                      <li>
                        CARBN.AI<sup>TM</sup> Elasticsearch
                      </li>
                      <li>
                        CARBN.AI<sup>TM</sup> UI Service
                      </li>
                      <li>CARBN.AI<sup>TM</sup> Data Enrichment API</li>
                      <li>CARBN.AI<sup>TM</sup> Data Enrichment Authentication Server</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-26">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-26"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-26"
                  >
                    Can I install the CARBN.AI<sup>TM</sup> without
                    administrative privileges?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-26"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-26"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      No, User cannot install CARBN.AI<sup>TM</sup> without
                      Administrative privileges.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-27">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-27"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-27"
                  >
                    Do I need to reboot the system after installing CARBN.AI
                    <sup>TM</sup>?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-27"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-27"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p className="text-primary">
                      Yes, the system ask for reboot after the installation is complete.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-28">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-28"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-28"
                  >
                    Windows defender screens are appearing as a user, Can I
                    accept or cancel the screens?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-28"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-28"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      Yes, accept to run anyway. This is not a
                      malware/bug/virus.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-29">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-29"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-29"
                  >
                    Upon uninstallation, is a reboot necessary?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-29"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-29"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      Yes, Rebooting the system after uninstalling software is
                      necessary to remove residual file/data and processes
                      associated with the software may still be in use or loaded
                      into memory. Rebooting ensures that all components are
                      fully removed from the system.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-30">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-30"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-30"
                  >
                    Why is the installation done on the default path?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-30"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-30"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      Installation is done at default path because there are
                      certain services required to be installed at Program files
                      and hence if user provide any other path, it will make the
                      system unstable.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-31">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-31"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-31"
                  >
                    Why there is a need to provide data path?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-31"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-31"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      As CARBN.AI<sup>TM</sup> is an offline software, we need a data path for efficient management of fluctuations in data volume, especially when scaling up or down operations and to save all the resources generated during processing.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-32">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-32"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-32"
                  >
                    How can I generate PCAP
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-32"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-32"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                    To try out CARBN.AI<sup>TM</sup>, you can generate PCAP (Packet Capture) files using network traffic capture tools like PCAPdroid, Wireshark etc. These tools allow you to capture packets on a network interface and save them into a PCAP file for testing and analysis.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/*******IPDR FAQ only for INDIA user******/}
              {hubspotYou?.Country && hubspotYou?.Country?.toLowerCase() === "india" && <>
 
                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-37">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-37"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-37"
                    >
                      How can I generate IPDR? Or Can I generate IPDR to try out CARBN.AI<sup>TM</sup>?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-37"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-37"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                      You can export PCAP data into CSV using Wireshark and use Excel to reformat the data into the desired IPDR format.
                      </p>
                    </div>
                  </div>
                </div>
              

                <div className="accordion-item">
                  <h2 className="accordion-header" id="FAQ-list-38">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#FAQ-collapse-38"
                      aria-expanded="true"
                      aria-controls="FAQ-collapse-38"
                    >
                      What is the maximum size of IPDR data allowed to ingest at one time for freeware and licensed versions?
                    </button>
                  </h2>
                  <div
                    id="FAQ-collapse-38"
                    className="accordion-collapse collapse"
                    aria-labelledby="FAQ-list-38"
                    data-bs-parent="#FAQ_accordion"
                  >
                    <div className="accordion-body">
                      <p>
                      For Freeware: Access to 1 month of IPDR data for a single target based on the assumption of around 1,000 IPDR records per day.
                      </p>
                    </div>
                  </div>
                </div>
              </>
              }


              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-33">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-33"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-33"
                  >
                    What is the maximum size of PCAP data allowed to ingest at one time for freeware version?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-33"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-33"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                    For Freeware - Maximum 2 GB PCAP data is allowed to ingest ( Either you can process one PCAP with 2 GB size or several PCAP files of total 2 GB size)
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-34">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-34"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-34"
                  >
                    What happens if there is less RAM available for use when processing PCAP data?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-34"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-34"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                    Insufficient RAM during processing will primarily affect the speed of PCAP processing.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-35">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-35"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-35"
                  >
                    How much free RAM is required for CARBN.AI<sup>TM</sup> to install?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-35"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-35"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                    A minimum of 16 GB RAM is required to initiate the installation of CARBN.AI<sup>TM</sup> and a minimum of 2 GB free RAM is required to launch the CARBN.AI<sup>TM</sup> user interface.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-36">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-36"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-36"
                  >
                    While starting CARBN.AI<sup>TM</sup>, I received a fatal error - “Not enough RAM in the system”. What should I do?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-36"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-36"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                    You are required to close some of the applications which are running and utilizing more RAM, so that necessary resources get free to launch the CARBN.AI<sup>TM</sup> UI. If not, try restarting your system.
                    </p>
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-32">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-32"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-32"
                  >
                    Is technical support available during the installation
                    process?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-32"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-32"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    <p>
                      We provide a Readme file along with the installer to
                      assist you in a smooth installation experience. However,
                      if you encounter any issues or have questions, please
                      connect with our Technical Operations Support available
                      8/5 through the chat option available on CARBN.AI
                      <sup>TM</sup> microsite. For any additional queries,
                      please call +91 8062281345 or email at{" "}
                      <a href="mailto:support@carbn.ai">support@carbn.ai</a>.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* Item */}
            </div>
            {/* accordion box end 

            <div className="text-center">
              <a href="#" className="btn primary-border-btn inline-block">
                View all
              </a>
            </div>*/}
          </div>
        </div>
        {/* end */}
      </div>
      }

      {/* end */}
      <ModalDashboard
        openModal={openModal}
        appUrl={licenseUrl}
        handleModalClose={handleModalClose}
      />
      <InvoiceModal
        handleModalOpen={handleModalOpen}
        openInvoiceModal={openInvoiceModal}
        invoiceData={invoiceData}
        handleInvoiceModal={handleInvoiceModal}
        invoicePreviewModal={invoicePreviewModal}
        handleInvoicePreviewModal={handleInvoicePreviewModal}
        downloadInvoice={downloadInvoice}
        invoiceUrl={invoiceUrl}
      />
      <VideoModel
        videoStatus={videoStatus}
        videoId={videoId}
        closeVideo={closeVideo}
      ></VideoModel>
    </>
  );
};

export default Dashboard;
