import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setPasswordStage } from "../../redux/users";
import { EMAIL_ERROR, PASSWORD_ERROR, ROUTES } from "../../utils/constants";
import FormFooter from "../FormFooter";
import ResetPasswordStageOne from "../ResetPassword/ResetPasswordStageOne";
import ResetPasswordStageThree from "../ResetPassword/ResetPasswordStageThree";
import ResetPasswordStageTwo from "../ResetPassword/ResetPasswordStageTwo";
import SnackBarHome from "../Snackbar/SnackbarHome";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import "./Login.css";
import SignupStageOne from "../Signup/SignupStageOne";
import ResetPasswordStageFour from "../ResetPassword/ResetPasswordStageFour";

const style = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#000000",
    },
  },
  "& label.Mui-focused": {
    color: "#000000",
  },
};
function Login({
  user,
  userError,
  handleChange,
  handleSubmit,
  forgotPasswordStage,
  setForgotPasswordStage,
  handleForgotPassword,
  handleForgotPasswordSubmit,
  handleCodeResend,
  recaptchaRef,
  resetUserPassword,
  handleLoginCodeSubmit,
  open,
  setOpen,
  message,
  handleIconToggle,
  iconVisible,
}) {
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

  const dispatch = useDispatch();
  const passwordStage = useSelector((state) => state?.users?.passwordStage);

  useEffect(() => {
    if (passwordStage === 0) {
      setForgotPasswordStage(0);
    }
  }, [passwordStage]);

  return (
    <>
      <div className="inner-content-box">
        {/* breadcrumb start */}
        <div className="breadcrumb-box">
          <div className="container">
            <ul>
              <li className="breadcrumb-item">
                <Link className="navLink" to={ROUTES.LANDING_PAGE}>
                  CARBN.AI<sup>TM</sup>
                </Link>
                {/* CARBN.AI<sup>TM</sup> */}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Log In
              </li>
            </ul>
          </div>
        </div>
        {/* breadcrumb end */}

        <div className="signup-box">
          <div className="container">
            <div className="letter-main-box d-md-flex justify-space-between">
              <div className="letter-left">
                <h2>Welcome!</h2>
                <p>Log in to stay ahead of the curve with:</p>
                <div className="letter-info">
                  <ul>
                    <li>Real-time analysis for rapid breakthroughs. </li>
                    <li>Efficient workflow with minimised delays. </li>
                    <li>
                      Powerful communication analytics for staying ahead of
                      threats.
                    </li>
                  </ul>
                </div>

                {/* <img className="sign-up-img" src={TempImg} alt="temp" /> */}
              </div>

              <div className="letter-right">
                <div className="">
                  {forgotPasswordStage === 0 ? (
                    <div className="field-row-login">
                      <div className="field-col full-width">
                        <label className="field-title">
                          Email<span>*</span>
                        </label>

                        <input
                          className="field-input-login"
                          label="email"
                          fullWidth
                          name="email"
                          type="text"
                          required
                          value={user?.email}
                          onChange={handleChange}
                          error={userError?.emailError}
                          helperText={userError?.emailError ? EMAIL_ERROR : ""}
                        />
                        <span className="error-message">
                          {userError?.emailError ? EMAIL_ERROR : ""}
                        </span>
                      </div>

                      <div className="field-col full-width">
                        <label className="field-title">
                          Password<span>*</span>
                        </label>
                        <div className="input-password-login">
                          <input
                            className="field-input-login"
                            label="password"
                            fullWidth
                            name="password"
                            type={iconVisible ? "password" : "text"}
                            required
                            value={user?.password}
                            onChange={handleChange}
                            error={userError?.passwordError}
                            helperText={
                              userError?.passwordError ? PASSWORD_ERROR : ""
                            }
                          />
                          <span
                            className="input-icon-login"
                            onClick={handleIconToggle}
                          >
                            {iconVisible ? (
                              <EyeSlash color="black" size={24} />
                            ) : (
                              <Eye color="black" size={24} />
                            )}
                          </span>
                        </div>
                        <span className="error-message">
                          {userError?.passwordError ? PASSWORD_ERROR : ""}
                        </span>

                        <label style={{ "padding-top": "10px" }}>
                          <Link
                            className="navLink"
                            onClick={() => {
                              setForgotPasswordStage(1);
                              dispatch(setPasswordStage(1));
                            }}
                          >
                            &nbsp;Forgot your password?
                          </Link>
                        </label>
                      </div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={captchaKey}
                      />
                      <SnackBarHome
                        open={open}
                        setOpen={setOpen}
                        severity="error"
                        message={message}
                      />
                      <div className="field-col">
                        <button
                          className="btn primary-btn"
                          onClick={handleSubmit}
                          disabled={!user?.email || !user?.password}
                        >
                          Log In
                        </button>
                      </div>
                      <p id="login-account-text">
                        Don't have an account ?{" "}
                        <Link className="navLink" to={ROUTES.SIGNUP}>
                          Sign up
                        </Link>
                      </p>
                      <p>
                        For any queries, please call{" "}
                        <a href="tel:+918062281345">+91 80622 81345</a> or email{" "}
                        <a href="mailto:support@carbn.ai">support@carbn.ai</a>
                      </p>
                    </div>
                  ) : forgotPasswordStage === 1 ? (
                    <ResetPasswordStageOne
                      setForgotPasswordStage={setForgotPasswordStage}
                      user={user}
                      userError={userError}
                      handleChange={handleChange}
                      handleForgotPassword={handleForgotPassword}
                      open={open}
                      setOpen={setOpen}
                      message={message}
                    />
                  ) : forgotPasswordStage === 2 ? (
                    <ResetPasswordStageTwo
                      setForgotPasswordStage={setForgotPasswordStage}
                      user={user}
                      userError={userError}
                      handleChange={handleChange}
                      handleForgotPasswordSubmit={handleForgotPasswordSubmit}
                      handleCodeResend={handleCodeResend}
                      open={open}
                      setOpen={setOpen}
                      message={message}
                      iconVisible={iconVisible}
                      handleIconToggle={handleIconToggle}
                    />
                  ) : forgotPasswordStage === 3 ? (
                    <ResetPasswordStageThree
                      user={user}
                      userError={userError}
                      handleChange={handleChange}
                      resetUserPassword={resetUserPassword}
                      open={open}
                      setOpen={setOpen}
                      message={message}
                      iconVisible={iconVisible}
                      handleIconToggle={handleIconToggle}
                    />
                  ) : (
                    <>
                      <ResetPasswordStageFour
                        user={user}
                        userError={userError}
                        handleChange={handleChange}
                        handleLoginCodeSubmit={handleLoginCodeSubmit}
                        open={open}
                        setOpen={setOpen}
                        message={message}
                      />
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={captchaKey}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <FormFooter /> */}
      </div>
    </>
  );
}

export default Login;
