import { Alert, Box, Snackbar } from "@mui/material";
import React from "react";

function SnackBarHome({ open, setOpen, severity, message }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: `${open ? (message?.length > 50 ? "100px" : "70px") : ""}`,
        height: `${open ? "50px" : ""}`,
      }}
    >
      <Snackbar
        sx={{ position: "absolute" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SnackBarHome;
