import React from "react";
import { Modal } from "@mui/material";
import { style } from "./styles";
import "./LetterUpload.css";

function ModalDashboard({
  openConfirmModal,
  handleConfirmModalClose,
  uploadFile,
  fileName,
}) {
  const handleClose = () => {
    handleConfirmModalClose(false);
  };
  return (
    <Modal
      open={openConfirmModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="proceed-inner-box">
        {fileName && <h4>{fileName}</h4>}
        <p>Proceed with this file? Select 'No' to change it.</p>
        <button
          className="btn primary-btn confirm-close-btn"
          onClick={uploadFile}
        >
          YES
        </button>
        <button className="btn primary-border-btn" onClick={handleClose}>
          NO
        </button>
      </div>
    </Modal>
  );
}

export default ModalDashboard;
