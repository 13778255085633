import React from "react";

const CoverBox = () => {
  return (
    <>
      <div className="cover-box">
        {/* FAQ box start */}
        <div className="FAQ-box">
          <div className="container">
            <h2 className="section-title text-center wow fadeInUp">
              Frequently Asked Questions
            </h2>

            {/* accordion box start */}
            <div
              className="accordion wow fadeInUp"
              data-wow-delay="0.2s"
              id="FAQ_accordion"
            >
              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-1"
                    aria-expanded="true"
                    aria-controls="FAQ-collapse-1"
                  >
                    What is CARBN.AI<sup>TM</sup>?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-1"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-1"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> is a high-tech tool for IP data
                    analysis, primarily designed to process and analyze large
                    data volumes, such as IP network traffic, for intelligence
                    purposes.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-6"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-6"
                  >
                    What is the minimum hardware configuration to run CARBN.AI
                    <sup>TM</sup>
                    &nbsp;effectively?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-6"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-6"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> requires the following system
                    specifications: 64-bit Windows 10 or 11, 16 GB RAM. Meeting
                    these specifications will ensure that your system performs
                    optimally and is compatible with CARBN.AI<sup>TM</sup>.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-8">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-8"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-8"
                  >
                    Could you clarify the meaning of 'official email address'
                    required during registration?'
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-8"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-8"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    'Official email address' usually refers to your government
                    email ID, used for verification of your affiliation.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-9">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-9"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-9"
                  >
                    Do I need specific technical qualifications to operate this
                    tool?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-9"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-9"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    You don't need any technical expertise to operate CARBN.AI
                    <sup>TM</sup>.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-2"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-2"
                  >
                    Can non-law enforcement individuals download it for
                    educational purposes?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-2"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-2"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    The tool can be used only by authorised law enforcement
                    personnel.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-3"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-3"
                  >
                    What is the typical duration for verifying a user profile?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-3"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-3"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    Since we serve to law enforcement only, we will request for
                    information to verify your identity as a law enforcement
                    officer. This can take a few hours to 2 days depending on
                    open requests.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-4"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-4"
                  >
                    For how long is the free trial of this product available?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-4"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-4"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    Free trial is available for 1 month from the date of
                    download of the product.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-5"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-5"
                  >
                    Is CARBN.AI<sup>TM</sup> an online-based or offline-based
                    product?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-5"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-5"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> is an offline product, it doesn't
                    connect to the internet.
                  </div>
                </div>
              </div>
              {/* Item */}

              

              {/* Item */}
              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-7">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-7"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-7"
                  >
                    What's the maximum file size for PCAP uploads in CARBN.AI
                    <sup>TM</sup>?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-7"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-7"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    The maximum PCAP file size that a user can upload is 1 GB.
                  </div>
                </div>
              </div> */}
              {/* Item */}

              

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-10">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-10"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-10"
                  >
                    Can this tool be used for real-time monitoring of suspects?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-10"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-10"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI
                    <sup>TM</sup> provides only offline decoding and analysis.
                    ClearTrail, however, has a suite of products for real-time
                    monitoring. Please contact us to know more.
                  </div>
                </div>
              </div>
              {/* Item */}

              {/* Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="FAQ-list-11">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ-collapse-11"
                    aria-expanded="false"
                    aria-controls="FAQ-collapse-11"
                  >
                    How does CARBN.AI<sup>TM</sup> work?
                  </button>
                </h2>
                <div
                  id="FAQ-collapse-11"
                  className="accordion-collapse collapse"
                  aria-labelledby="FAQ-list-11"
                  data-bs-parent="#FAQ_accordion"
                >
                  <div className="accordion-body">
                    CARBN.AI<sup>TM</sup> functions by decoding and analyzing
                    large IP data sets, using AI and machine learning to extract
                    and present actionable intelligence such as patterns of life
                    of suspects, their connections and correlations with other
                    users using IP network data.
                  </div>
                </div>
              </div>
              {/* Item */}
            </div>
            {/* accordion box end */}

            <div className="text-center">
              <button type="button" className="load-btn">
                <span className="view">View More</span>
                <span className="less">View Less</span>
              </button>
            </div>
          </div>
        </div>
        {/* FAQ box end */}

        {/* product info box start */}
        <div className="product-info-box">
          <div className="container">
            <h2 className="section-title text-center mb-5 wow fadeInUp">
              Powerful Intelligence Software for
              <br /> Billions of Communication Data Points
            </h2>

            <div className="product-info-inner-box d-md-flex justify-content-center flex-wrap">
              {/* col start */}
              <div
                className="product-info-col wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h3>Lawful Interception</h3>
                <p>Monitoring Centre. Reimagined</p>
                <a
                  className="btnlandingpage access-btn-l"
                  href="https://www.clear-trail.com/products/lawful-interception/"
                  target="_blank"
                >
                  Learn More
                </a>
              </div>
              {/* col end */}

              {/* col start */}
              <div
                className="product-info-col wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <h3>Intelligence Fusion Platform</h3>
                <p>AI-Powered Fusion Platform</p>
                <a
                  href="https://www.clear-trail.com/products/intelligence-fusion-platform/"
                  target="_blank"
                  className="btnlandingpage access-btn-l"
                >
                  Learn More
                </a>
              </div>
              {/* col end */}

              {/* col start */}
              {/* <div
                className="product-info-col wow fadeInUp"
                data-wow-delay="1s"
              >
                <h3>Open Source Intelligence</h3>
                <p>Monitor. Discover. Track.</p>
                <a
                  href="https://www.clear-trail.com/products/open-source-intelligence/"
                  target="_blank"
                  className="btnlandingpage access-btn-l"
                >
                  Learn More
                </a>
              </div> */}
              {/* col end */}
            </div>
          </div>
        </div>
        {/* product info box end */}
      </div>
    </>
  );
};
export default CoverBox;
