//import Cookies from "js-cookie";

function setTrackingCookie() {
  // Check if the cookie exists
  const cookieExists = localStorage.getItem("_cr_ct");

  var currentTimeInSeconds = Math.floor(Date.now() / 1000); //unix timestamp in seconds

  function generateToken(characters) {
    var text = "";
    var possible =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (var i = 0; i < characters; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  // If the cookie doesn't exist, set it
  if (!cookieExists) {
    // var cookieValue = generateToken(15) + currentTimeInSeconds + generateToken(15);
    // Set the cookie with a value and expiration time (optional)
    // Cookies.set("_cr_ct", cookieValue, { expires: 30 }); // Expires in 30 days
    var localStateValue =
      generateToken(15) + currentTimeInSeconds + generateToken(15);
    localStorage.setItem("_cr_ct", localStateValue);
  } else {
    //console.log("Tracking Cookie already exists.");
  }
}

export default setTrackingCookie;
