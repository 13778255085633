import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HowToBegin from "../../Components/HowToBegin";
import { useSelector } from "react-redux";
import {
  PAGES,
  ROUTES,
  USER_APPLICANT,
  USER_REJECTED,
} from "../../utils/constants";
import { callTrackingApi } from "../../utils/helperCookie";

const Begin = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const emailId = useSelector((state) => state?.users?.user?.attributes?.email);
  const profile = useSelector(
    (state) => state?.users?.user?.attributes?.profile
  );

  useEffect(() => {
    if (emailId) {
      callTrackingApi({
        email: emailId,
        pagetitle: PAGES.BEGIN,
        pagelink: pathname,
      });
    }
  }, [emailId]);

  useEffect(() => {
    if (profile === USER_APPLICANT || profile === USER_REJECTED) {
      navigate(ROUTES.LANDING_PAGE);
    }
  }, [profile]);

  const handleStartNow = () => {
    navigate(ROUTES.APP_STAGE);
  };
  return (
    <>
      <HowToBegin handleStartNow={handleStartNow} />
    </>
  );
};

export default Begin;
