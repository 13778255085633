export const NAME_ERROR = "Please enter your first name";
export const LAST_NAME_ERROR = "Please enter your last name";
export const EMAIL_ERROR = "Please enter a correct email address";
export const PASSWORD_ERROR = "Please enter your password";
export const PASSWORD_LESS_ERROR = "Password less than 8 characters";
export const PASSWORD_MORE_ERROR = "Password more than 24 characters";
export const RE_PASSWORD_ERROR = "Passwords don't match";
export const CODE_ERROR = "Please enter the correct OTP";
export const TOKEN_ERROR = "Please enter the captcha to continue !";
export const LOGIN_ERROR = "Login failed !";
export const AGREE_ERROR = "Please select I agree to the CARBN.AI<sup>TM</sup> Privacy Policy";
export const REGISTERATION_FAILED = "Registeration failed !";
export const SIGN_LETTER_ERROR = "Error downloading letter";
export const DOWNLOAD_EXE_ERROR = "Error downloading file";
export const EMAIL_NOT_VERIFIED = "Email address is not verified";
export const PASSWORD_MATCH_ERROR =
  "Passwords don't match. Please ensure that both fields contain the same information before proceeding.";
export const CONGRATS_TEXT =
  "Congratulations on successfully completing the signup process! Kindly use your credentials to login.";
export const VALID_EMAIL_ERROR = "Please provide your government authorized email ID.";
export const NON_PDF_FILE_ERROR = "Please upload a pdf file";
export const SIZE_PDF_FILE_ERROR = "File size should be less than 10MB";
export const PDF_FILE_FORMAT_ERROR = "Error uploading file";
export const PDF_FILE_UPLOADED_SUCCESS = "File uploaded successfully";
export const OTP_ERROR =
  "Incorrect OTP. Please enter the correct OTP or click on resend button to receive the OTP again.";
export const UPLOAD_ERROR = "Please upload letter to continue";
//export const VERIFICATION_FAILED_ERROR = "Letter verification is failed";
export const VERIFICATION_FAILED_ERROR = "Please upload the LOA file";
export const VERIFICATION_PENDING_ERROR = "Letter verification is pending";
export const TRANSACTION_ID_MISSING = "Transaction Id is missing";
export const EMAIL_NOT_EXIST = "Email does not exist";
export const ADMIN_SUPPORT_ERROR = "An Admin or support user cannot login !";
export const LICENSE_ERROR = "License is not present in server";
export const OTPNEWUSER = "Invalid verification code, please re enter the correct code.";


export const STATUS_HOW_TO_BEGIN = "HowToBegin";
export const STATUS_REGISTERED = "Registered"; // app stage 2 - download letter
export const STATUS_PENDING_VERIFICATION = "PendingVerification"; // app stage 3 - Your letter is being reviewed
export const STATUS_VERIFICATION_FAILED = "VerificationFailed"; // app stage 3 - We could not verify your letter.
export const STATUS_VERIFICATION_COMPLETED = "VerificationCompleted"; // app stage 4
export const STATUS_PAYMENT_FAILED = "PaymentFailed"; // app stage 4 - Reason in notification strip from API/ disable plan buttons
export const STATUS_PAYMENT_AWAITED = "PaymentAwaited"; // app stage 4 - Reason in notification from API (We have sent you an email) /  disable plan buttons
export const STATUS_PAYMENT_SUCCESS = "PaymentSuccess"; // app stage 5
export const STATUS_DOWNLOADABLE = "AppDownloadable"; // app stage 5 - show notification strip on all screens for both event and non-event

export const STATUS_DOWNLOADED = "AppDownloaded";
// contracts3key -> empty -> not verified -> app stage
// contracts3key -> some link -> verified -> download page
export const ADMIN_REGISTERED = "AdminRegistered"; // app stage 5
export const ADMIN_VERIFIED = "AdminVerified"; // app stage 5

// TODO
export const STATUS_VERIFIED = "Verified Profile";
export const STATUS_ON_HOLD = "On-hold";
export const STATUS_PROGRESS = "Verification In Progress";

export const USER_ROLE = "user";
export const USER_APPLICANT = "applicant";
export const USER_SIGNUP_APPLICANT = "unverified_applicant";
export const USER_REJECTED = "rejected";
export const MIN_PASSWORD = 8;
export const MAX_PASSWORD = 24;
export const COOKIE_EXPIRY_SEC = 45; // 45 mins
export const AUTH_INTERVAL_TIME = 900000; // 15 mins
export const IDLE_TIMEOUT = 600000; // 10 mins
export const SESSION_EXPIRED = "Your session has expired.";

export const ROUTES = {
  LANDING_PAGE: "/",
  LANDING_PAGE_IN: "/in",
  SIGNUP: "/sign-up",
  LOGIN: "/log-in",
  HOW_TO_BEGIN: "/how-to-begin",
  APP_STAGE: "/app-stage",
  DASHBOARD: "/profile",
  RESOURCES: "/resources",
  PRICING: "/pricing",
  FAQ: "/faq",
  TERMS_SERVICES: "/terms-services",
  PRIVACY_POLICY: "/privacy-policy",
  DOWNLOAD: "/download",
  PAY: "/pay",
  PAYMENTDETAIL: "/paymentDetail",
  PAYMENTSUCCESS: "/return",
  RAZORPAY_SUCCESS: "/paymentStatus",
  FREEMIUM_SUCCESS: "/freemiumStatus",
  PAYMENTCANCEL: "/paymentCancel",
  PAYMENTOPTION: "/paymentoption",
  NOTIFICATION: "/notification",
  ADMIN: "/admin",
  CONFIRMATION: "/profile-verification",
  PAGENOTFOUND: "*",
  LOGOUT: "/logout",
};

export const PAGES = {
  HOME_PAGE: "home",
  SIGNUP_PAGE: "sign-up",
  SIGIN_PAGE: "log-in",
  BEGIN: "how-to-begin",
  STAGES: "app-stage",
  DOWNLOAD: "download",
  DASHBOARD: "dashboard",
  NOTIFICATION: "notification",
  PRICING_PLAN: "pricing",
  RESOURCES: "resources",
};

export const steps = [
  "About you",
  "Sign Letter",
  "Letter Verification",
  "Payment Detail",
  "Download Exe",
];

export const hubspotSteps = [
  "About you",
  "Payment Detail",
  "Download Exe",
];

export const ENDPOINT = {
  REGISTER: "/users/register",
  FILE_UPLOAD: "/fileUpload",
};

export const NAVBARLINKSBEFORELOGIN = [
  { name: "Features", href: "/#features" },
  { name: "KPIs", href: "/#KPI" },
  { name: "Resources", href: "/resources" },
  { name: "FAQ", href: "/#FAQ" },
  { name: "Contact Us", href: "/#ContactUS" },
];

export const NAVBARLINKSAFTERLOGIN = [
  { name: "Features", href: "/#features" },
  { name: "KPIs", href: "/#KPI" },
  { name: "Resources", href: "/resources" },
  { name: "FAQ", href: "/#FAQ" },
  { name: "Contact Us", href: "/#ContactUS" },
  { name: "Pricing", href: "/pricing" },
];

export const eventMouse = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

export const freemiumObj = {
  id: 0,
  object: "price",
  active: true,
  billing_scheme: "per_unit",
  created: 1234,
  currency: "usd",
  custom_unit_amount: null,
  livemode: false,
  lookup_key: null,
  metadata: {
    DataLimit: "Up to 2GB",
    Description:
      "Allow users to access a basic version of the CARBN.AI for free. The free version serves as a gateway for users to understand the core value of the product without financial commitment. User to be qualified by at least a DSP level letter.",
    "Feature list":
      "Data Limit: Up to 2GB one time credit, Valid up to 1 month",
    Name: "Freemium",
    TimeLimit: "1M",
  },
  nickname: "International Payment",
  product: "prod_PIiCiFs8z8fSpO",
  recurring: null,
  tax_behavior: "unspecified",
  tiers_mode: null,
  transform_quantity: null,
  type: "one_time",
  unit_amount: 0,
  unit_amount_decimal: "0",
};

export const priceData = [
  {
    Id: 0,
    title: "Freemium",
    price: "0",
    decription:
      "Allow users to access a basic version of the CARBN.AI for free. The free version serves as a gateway for users to understand the core value of the product without financial commitment. User to be qualified by at least a DSP level letter.",
    dataLimit: "Up to 2GB ",
    timeLimit: "1 month",
    currentPlan: false,
    planName: "Freemium",
    priceId: "",
  },
  {
    Id: 1,
    title: "Basic",
    price: "250",
    decription:
      "Users purchase credit packs upfront. Credits directly translate into volume of data processed by the system, and deducts a specific number of credits from the pack. When credits run out, users can purchase more packs.",
    dataLimit: "150 GB one time credit",
    timeLimit: "Valid up to 3 months",
    currentPlan: false,
    planName: "Basic",
    priceId: "price_1OU6hQSI7RoV16YmnlFUJXtN",
    priceIdINR: "price_1ObeTtSI7RoV16Ymb3iuxadS",
  },
  {
    Id: 2,
    title: "Bundled Credit Packs",
    price: "500",
    decription:
      "Users purchase credit packs upfront. Credits directly translate into volume of data processed by the system, and deducts a specific number of credits from the pack. When credits run out, users can purchase more packs.",
    dataLimit: "300 GB one time credit",
    timeLimit: "Valid up to 6 months",
    currentPlan: false,
    planName: "Premium",
    priceId: "price_1OU6jNSI7RoV16YmZNcDjaDh",
    priceIdINR: "price_1ObeYBSI7RoV16YmFN8PYMAn",
  },
  {
    Id: 3,
    title: "Premium",
    price: "750",
    decription:
      "Users purchase credit packs upfront. Credits directly translate into volume of data processed by the system, and deducts a specific number of credits from the pack. When credits run out, users can purchase more packs.",
    dataLimit: "600 GB one time credit",
    timeLimit: "Valid up to 12 months",
    currentPlan: false,
    planName: "Enterprise",
    priceId: "price_1OU6lgSI7RoV16Ym8pBCiarF",
    priceIdINR: "price_1OXhbZSI7RoV16YmQ92KabMq",
  },
];

export const checkSumText =
  "https://carbn-document-test.s3.us-east-1.amazonaws.com/checksum.txt?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEB4aCmFwLXNvdXRoLTEiSDBGAiEAmB3MMYS5%2BTBbSLtJi%2FgGq%2FYyVu6J3DnVv4qWl2qOhXwCIQC%2BJ%2BPNhWyEvDe7CDkOVn39Bl8skq%2F%2BnLC%2B%2Fm1E6TTCCiqGAwjo%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAEaDDQzNDI2MjMxNDMyMyIMw8Nh0uLVzCFNUrJ3KtoCQAcCNngoPuURewqsqXZnrrXqJWy%2B0arTA75zSdSYzxXiHGQg%2FARM618HEODOs6Ar%2FnOIFlBX6bHPne5%2FCbpI67xx3J0uotA65Y%2B8GlbwY7%2BYWi%2BOMyZ8fsuiORMlmiPvJiqcLnPH0Vf7fZ2ZKDXtFCDacREuJmfFOHNz2iD2HDLMVPSyZwLpkLNu7sq1oGxFuADlVjUyn6gY3dK9Hd4ru5CCvHelWJ%2FZ9xllkspXvjwJPjm4fog0Yy50Pslm2BXKiAfE0G659768Mrv9BFlypAvweeVzOCo4pMmT9yVOfQa%2FfAOOEMC%2FTHgIFsdlF4O98BgwDqA6GBlMw9aiICPkW%2Bq84CmBxrltvtULlL623MeX%2F28NdRgRhiqIvjcXQrmtYCymghJ8yWAZh2NUWr2lKlVubQbHeXC0of%2F2N0A00a6ahpzOO6HEennFCIU8%2B1DDsdWfnjNwkcgZCDC65pGuBjqyAs1FH9qd2KnMUbpCPGbTddJYn6u5FvoOtWPB3gsbu%2Bhnp3XvcQ3UY9bgwe%2FP6r1Zkdbs3Imdavw%2FZrZdflvgWD4Ud%2BmdOqp4jZw5nOy4Qg90hYoqb5EanUNiRi45xz%2FvcdBf2CuxyUJty48sl6SDk0T8y%2F4ZDSFdo1Ep2I0OkOfEb%2F3a8Cqiy35BmEOGBVLLkYI0G3l4CZ5u4T6pQ5KKgSDVB648wfIsw1bO3XMbvtywD%2Fd07tIheNjhrarYUj0MIp9adxVHzwa7G%2FUc5XGloIzOYqXtyKbE%2B8pDL2mBf%2ByPB7eQWsfTYePqRyfTfj1Nk99yQhykmknnfhrXavTDt7vaubf0XD3%2BnRIzkWq8OlrQ%2BYeH9ng7%2Fqvd0ehWt6WAgNWuwUFoKxtvkEU%2Bo0ntBW8tvg%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240208T135244Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAWKHANBVJ426ERMVN%2F20240208%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=cc8f0a3d19e8ab764ba05af22339c28aebcb7080f60dc233f6b17be8785299e0";
