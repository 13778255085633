import React from "react";
//import "../index.css";

const ReachFooter = () => {
  return (
    <>
      <div className="reach-box">
        <div className="container">
          <h2 className="section-title text-center wow fadeInUp">
            Reach Us
          </h2>

          <div className="reach-col-box d-lg-flex justify-content-center">
            <div className="reach-col wow fadeInUp" data-wow-delay="0.5s">
              <h4>General Inquiries</h4>
              <a
                href="javascript:void(0)"
                onClick={() =>
                  (window.location = "mailto:info@clear-trail.com")
                }
              >
                info@clear-trail.com
              </a>{" "}
              <a
                href="javascript:void(0)"
                onClick={() => window.open("tel:+91 7389905551")}
              >
                +91 7389905551
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => window.open("tel:+91 9630633332")}
              >
                +91 963 063 3332
              </a>
            </div>

            <div className="reach-col wow fadeInUp" data-wow-delay="0.5s">
              <h4>Product Support</h4>
              <a
                href="javascript:void(0)"
                onClick={() => (window.location = "mailto:support@carbn.ai")}
              >
                support@carbn.ai
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReachFooter;
