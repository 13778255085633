import React from "react";
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance";

class ApiService extends React.Component {
  static showErrorMessage(e) {
    toast.error(e?.message, {
      position: "top-center",
    });
  }
  static async getApiData(params, token) {
    const response = await axiosInstance.get(params, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  }

  static async getApiDataDownload(params, body, token) {
    const response = await axiosInstance
      .get(params, {
        headers: {
          Authorization: token,
          docName: body?.docName,
          UserID: body?.UserID,
          TransactionID: body?.TransactionID,
        },
      })
      .catch((e) => {
        // this.showErrorMessage(e);
      });
    return response;
  }

  static async getApiDataWToken(params) {
    const response = await axiosInstance.get(params).catch((e) => {
      this.showErrorMessage(e);
    });
    return response;
  }

  static async postApiData(params, body, token) {
    const response = await axiosInstance.post(params, body, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  }

  static async postApiDataWithUpload(params, body, token, configProgress) {
    const response = await axiosInstance.post(params, body, {
      headers: {
        Authorization: token,
      },
      onUploadProgress: configProgress,
    });
    return response;
  }

  static async patchApiData(params, body, token) {
    const response = await axiosInstance
      .patch(params, body, {
        headers: {
          Authorization: token,
        },
      })
      .catch((e) => {
        this.showErrorMessage(e);
      });
    return response;
  }

  static async deleteApiData(params, token) {
    const response = await axiosInstance
      .delete(params, {
        headers: {
          Authorization: token,
        },
      })
      .catch((e) => {
        this.showErrorMessage(e);
      });
    return response;
  }
}

export default ApiService;
