import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dealStageAdminHubspot,
  getHubspotUser,
  registerMacHubspot,
} from "../../service/hubspotAxios";
import { Grid, Box, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
// import StripePayment from "./StripePayment";
// import PaymentOptions from "./PaymentOptions";
import { ROUTES } from "../../utils/constants";
import ApiService from "../../service/axiosUtils";
import LoadingScreen from "../LoadingScreen";
import { countriesCodes } from "../../utils/countryCode";
import "./payment.css";
import { getCurrency } from "../../utils/utils";
import { setAboutYouStage, setHubspotStage } from "../../redux/stages";
import BillingForm from "./Razorpay/BillingForm";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const userDetails = useSelector((state) => state?.users?.user);
  const country = useSelector((state) => state?.stage?.hubspotYou?.Country);
  const attributes = useSelector((state) => state?.users?.user?.attributes);
  const aboutYouData  = useSelector((state) => state?.stage?.aboutYou);
  const [paymentMode, setPayamentMode] = useState("online");
  const [buttonText, setButtonText] = useState("Pay Now");
  const [loading, setLoading] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [macAddressError, setMacAddressError] = useState(false);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const [macAddress, setMacAddress] = useState("");
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const [formData, setFormData] = useState({
    country: country || "United States",
    state: "",
    addresslineone: "",
    addresslinetwo: "",
    city: "",
    zipcode: "",
  });
  const [isBillingDetailModal, setIsBillingDetailModal] = useState(false);

  useEffect(() => {
    setMacAddress(aboutYouData?.Mac || "");
  }, [aboutYouData]);

  const handleChange = (event) => {
    setPayamentMode(event.target.value);
    if (event.target.value == "online") {
      setButtonText("Pay Now");
    } else {
      setButtonText("Place Order");
    }
  };
  const handelButtonClick = () => {
    if (macAddressError || !macAddress) {
      toast.error("Please fill mac address correctly", {
        position: "top-center",
      });
      return;
    }
    registerMacHubspot({ Mac: macAddress }, attributes?.email);
    // UPDATE status by id API
    ApiService.patchApiData(`/users/id?Mac=${macAddress}`, {}, tokenId)
      .then(() => {
        ApiService.getApiData(`/users/id`, tokenId)
          .then((res) => {
            if (res?.data && res?.data?.length > 0) {
              dispatch(setAboutYouStage(res?.data[0]));
            }
          })
          .catch((e) => {
            console.log(e?.message);
          });
      })
      .catch((e) => {
        toast.error(e?.message, {
          position: "top-center",
        });
      });

    const obj = { ...hubspotYou, Mac: macAddress };
    dispatch(setHubspotStage(obj));

    if (paymentMode == "online") {
      if (country?.toLowerCase() === "india") {
        setIsBillingDetailModal(true);
      } else {
        navigate(ROUTES.PAYMENTOPTION, {
          state: state,
        });
      }
    } else {
      // CALL DEAL HUBSPOT API - OFFLINE
      const p = getHubspotUser(attributes?.email);
      p.then((r) => r.json())
        .then((res) => {
          const payload = {
            dealname: attributes?.given_name + " " + attributes?.family_name,
            dealstage: "147595019",
            amount: state?.price,
          };
          dealStageAdminHubspot(payload, attributes?.email);
        })
        .catch((e) => {
          console.log(e);
        });

      offLinePayment();
    }
  };

  const offLinePayment = () => {
    if (zipCodeError) {
      return;
    }
    setLoading(true);
    const tokenId = userDetails.token;
    const userID = userDetails.username;
    const param = {
      name: `${userDetails?.attributes?.given_name} ${userDetails?.attributes?.family_name}`,
      priceId: state.priceId,
      // country: country,
      amount: state.price,
      DataLimit: state?.dataLimit,
      TimeLimit: state?.timeLimit,
      PlanName: state?.title,
      MacAddress: macAddress,
      address: {
        country: formData.country,
        state: formData.state,
        line1: formData.addresslineone,
        line2: formData.addresslinetwo,
        city: formData.city,
        postal_code: formData.zipcode,
      },
    };
    ApiService.postApiData(
      `${process.env.REACT_APP_PROD_BASE_URL}/payment/offline`,
      param,
      tokenId
    )
      .then((response) => {
        setLoading(false);
        if (response.status == 200) {
          navigate(`${ROUTES.PAYMENTSUCCESS}?paymentmode=offline`);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.message, {
          position: "top-center",
        });
      });
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    if (name === "zipcode") {
      if (country?.toLowerCase() === "india") {
        const regex = /^\d{6}$/;
        if (regex.test(value)) {
          setZipCodeError(false);
        } else {
          setZipCodeError(true);
        }
      } else {
        const regex = /^\d{5}(-\d{4})?$/;
        const regex2 = /^\d{5}$/;
        if (regex.test(value) || regex2.test(value)) {
          setZipCodeError(false);
        } else {
          setZipCodeError(true);
        }
      }
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const getCurr = () => {
    if (country?.toLowerCase() === "india") {
      return "inr";
    } else {
      return "usd";
    }
  };

  const handleMacChange = (e) => {
    setMacAddress(e.target.value);
    let m = e.target.value;
    if (m) {
      setMacAddressError(false);
      let macRegex = new RegExp(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/);
      if (macRegex.test(m)) {
        setMacAddressError(false);
      } else {
        setMacAddressError(true);
      }
    }
  };

  return (
    <div className="about-box">
      <div className="container">
        <div className="payment-main-box d-lg-flex justify-space-between">
          <div className="payment-left">
            <p className="select-plan">Your plan</p>
            <h4 className="mb-20">{state.title}</h4>
            <div className="price-label">{getCurrency(getCurr(), state.price)}</div>
            <p className="mb-20">{state.decription}</p>

            <ul>
              <li>{state.dataLimit}</li>
              <li>{state.timeLimit}</li>
            </ul>
            <a
              href="#"
              className="btn secondary-border-btn"
              onClick={() => {
                navigate(ROUTES.PRICING);
              }}
            >
              Change Plan
            </a>
          </div>
          <div className="payment-right">
            <div className="field-col mac-address-label">
              <label className="field-title">
                MAC Address (Laptop/Desktop)<span>*</span>
                <div className="tooltip-dropdown">
                  <div className="tooltip-icon">
                    <span className="material-icons-outlined">info</span>
                  </div>
                  <div className="tooltip-dropdown-content">
                    <p>
                      To find out the Mac address of your system follow the
                      below steps:
                    </p>
                    <ul>
                      <li>
                        open command prompt using the start menu in the system
                        where you would like to install CARBN.AI<sup>TM</sup>.{" "}
                      </li>
                      <li>
                        Type the following command “ipconfig &nbsp; /all”.{" "}
                      </li>
                      <li>
                        Copy and paste the 12-digit Mac address. For example.
                        XX-XX-XX-XX-XX-XX
                      </li>
                    </ul>
                  </div>
                </div>
              </label>

              <input
                name="MacAddress"
                type="text"
                placeholder=""
                value={macAddress}
                onChange={(e) => handleMacChange(e)}
                className="field-input"
                disabled={aboutYouData?.Mac?.length > 0}
                required
              />
              <span className="error-message">
                {macAddressError ? "MAC Address is mandatory*" : ""}
              </span>
            </div>

            <div className="total-row d-flex justify-space-between align-center">
              <div>Total</div>
              <div>{getCurrency(getCurr(), state.price)}</div>
            </div>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="online"
              name="radio-buttons-group"
              value={paymentMode}
              onChange={handleChange}
            >
              <Box className="payment-option mb-10">
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label="Online Payment"
                />
              </Box>
              <Box className="payment-option mb-10">
                <FormControlLabel
                  value="offline"
                  control={<Radio />}
                  label="Offline Payment"
                />
              </Box>
            </RadioGroup>
            {paymentMode !== "online" ? (
              <Box className="offline-field-box">
                <div className="billingAddress">
                  <div className="field-row">
                    <div className="field-col">
                      <label className="field-title">Country</label>
                      <select
                        name="Country"
                        value={country || "United States"}
                        className="field-input"
                        disabled={true}
                      >
                        {countriesCodes.map((c) => {
                          return <option value={c?.name}>{c?.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="field-col">
                      <label className="field-title">State</label>
                      <input
                        className="field-input-signup"
                        label="state"
                        variant="outlined"
                        name="state"
                        type="text"
                        value={formData?.state}
                        onChange={handleAddressChange}
                      />
                    </div>
                    <div className="field-col">
                      <label className="field-title">Address line 1</label>
                      <input
                        className="field-input-signup"
                        label="Address line 1"
                        variant="outlined"
                        name="addresslineone"
                        type="text"
                        value={formData?.addresslineone}
                        onChange={handleAddressChange}
                      />
                    </div>
                    <div className="field-col">
                      <label className="field-title">Address line 2</label>
                      <input
                        className="field-input-signup"
                        label="Address line 2"
                        variant="outlined"
                        name="addresslinetwo"
                        type="text"
                        value={formData?.addresslinetwo}
                        onChange={handleAddressChange}
                      />
                    </div>
                    <div className="field-col">
                      <label className="field-title">City</label>
                      <input
                        className="field-input-signup"
                        label="City"
                        variant="outlined"
                        name="city"
                        type="text"
                        value={formData?.city}
                        onChange={handleAddressChange}
                      />
                    </div>
                    <div className="field-col">
                      <label className="field-title">Zip Code</label>
                      <input
                        className="field-input-signup"
                        label="Zip Code"
                        variant="outlined"
                        name="zipcode"
                        type="text"
                        value={formData?.zipcode}
                        onChange={handleAddressChange}
                      />
                      {zipCodeError && (
                        <span className="error-message">
                          Enter valid zip code
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
            ) : (
              ""
            )}

            <button
              type="button"
              onClick={handelButtonClick}
              className="btn primary-btn"
            >
              {buttonText}
            </button>
          </div>
          {loading && <LoadingScreen open={loading} />}
        </div>
      </div>
      {isBillingDetailModal && (
        <BillingForm
          state={state}
          setIsBillingDetailModal={setIsBillingDetailModal}
        />
      )}
    </div>
  );
};

export default Payment;
