import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import video1 from "../../../assets/videos/Add_case.mp4";
import "./HubspotModel.css";

const HowItWorks = ({ howItWorks, closeHowItWorks }) => {
  return (
    <>
      <Modal
        className="video-modal modal-box"
        open={howItWorks}
        onClose={closeHowItWorks}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-dialog">
          {/* <button type="button" className="btn-close"><span className="material-icons-outlined">close</span> Close</button> */}
          <div className="modal-body">
            <iframe
              src="https://player.vimeo.com/video/923267810??autoplay=1&loop=1&autopause=0"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Add case"
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default HowItWorks;
