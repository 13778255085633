import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AUTH_INTERVAL_TIME,
  IDLE_TIMEOUT,
  ROUTES,
  eventMouse,
} from "./utils/constants";
import PrivateComponent from "./Components/PrivateComponent";
import PublicComponent from "./Components/PublicComponent";
import Signup from "./containers/authentication/Signup";
import Login from "./containers/authentication/Login";
import HowToBegin from "./containers/HowToBegin";
import CTHeader from "./Components/CTHeader";
import CTFooter from "./Components/Footer/Footer";
import Header from "./Components/Header";
import { Amplify } from "aws-amplify";
import { awsExports } from "./aws-exports";
import LandingPage from "./Components/LandingPage";
import LandingPageIN from "./Components/LandingPage/index-india";
import "@fontsource/roboto";
import "./App.css";
import Stepper from "./containers/stages";
import "@fontsource/plus-jakarta-sans";
import Dashboard from "./containers/Dashboard";
import DownloadExe from "./Components/ProductRegistration/DownloadExe";
import Notifications from "./Components/Notification";
import setTrackingCookie from "./utils/TrackingCookie";
import Pricing from "./Components/Pricing";
import LandingPrivateComponent from "./Components/PrivateComponent/LandingPrivateComponent";
import Payment from "./Components/Payment";
import PaymentSuccess from "./Components/Payment/PaymentSuccess";
import PaymentOptions from "./Components/Payment/PaymentOptions";
import PageNotFound from "./Components/PageNotFound";
import ConfirmationPage from "./Components/Comfirmation";
import Logout from "./containers/authentication/Logout";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { setActiveUser, setLoggedIn, setUserData } from "./redux/users";
import RazorpaySuccess from "./Components/Payment/Razorpay/RazorpaySuccess";
import { setAboutYouStage, setHubspotStage } from "./redux/stages";
import store from "./redux/store";
import FreemiumSuccess from "./Components/Payment/FreemiumSuccess";
import Version from './Components/Version';

window.$ = $;

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const dispatch = useDispatch();
  let timer;

  useEffect(() => {
    setTrackingCookie();

    //Scroll To Add Class
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 10) {
        $(".header").addClass("darkHeader");
      } else {
        $(".header").removeClass("darkHeader");
      }
    });
    
  }, []);

  useEffect(() => {
    setInterval(() => {
      Auth.currentSession()
        .then((session) => {
          const refreshToken = session.getRefreshToken();

          Auth.currentAuthenticatedUser()
            .then((res) => {
              console.log(res);
              res.refreshSession(refreshToken, (err, data) => {
                console.log(data.getIdToken().getJwtToken());
                if (err) {
                  if (store?.getState()?.users?.activeUser) {
                    handleLogout();
                  }
                } else {
                  Auth.currentUserInfo()
                    .then((resp) => {
                      const userObj = {
                        username: res?.username,
                        attributes: resp?.attributes,
                        token: data.getIdToken().getJwtToken(),
                      };
                      dispatch(setActiveUser(true));
                      dispatch(setUserData(userObj));
                    })
                    .catch((e) => console.log(e));
                }
              });
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }, AUTH_INTERVAL_TIME);
  }, []);

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(eventMouse).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(eventMouse).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, IDLE_TIMEOUT); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    if (
      store?.getState()?.users?.progress > 0 &&
      store?.getState()?.users?.progress <= 100
    ) {
      // toast.error(
      //   "Download in progress. Logging out or leaving the page will disrupt the process.",
      //   {
      //     position: "top-center",
      //   }
      // );
    } else {
      if (store?.getState()?.users?.activeUser) {
        handleLogout();
      }
    }
  };

  const handleLogout = () => {
    Auth.signOut().then(() => {
      dispatch(setUserData(null));
      dispatch(setActiveUser(false));
      dispatch(setAboutYouStage(null));
      dispatch(setLoggedIn(false));
      dispatch(setHubspotStage(null));
      window.location.href = "/";
    });
  };

  return (
    <>
      <div className="App scrollbar">
        <Router>
          <ScrollToTop />
          <CTHeader />
          <Header />
          <Routes>
            <Route path={ROUTES.LOGOUT} element={<Logout />} />
            <Route element={<LandingPrivateComponent />}>
              <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
              <Route path={ROUTES.LANDING_PAGE_IN} element={<LandingPage />} />
            </Route>
            <Route element={<PrivateComponent />}>
              <Route path={ROUTES.DOWNLOAD} element={<DownloadExe />} />
              <Route path={ROUTES.HOW_TO_BEGIN} element={<HowToBegin />} />
              <Route path={ROUTES.APP_STAGE} element={<Stepper />} />
              <Route
                path={ROUTES.CONFIRMATION}
                element={<ConfirmationPage />}
              />
              <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
              <Route path={ROUTES.NOTIFICATION} element={<Notifications />} />
              <Route path={ROUTES.PAYMENTDETAIL} element={<Payment />} />
              <Route path={ROUTES.PRICING} element={<Pricing />} />
              <Route path={ROUTES.PAYMENTOPTION} element={<PaymentOptions />} />
              <Route
                path={ROUTES.PAYMENTSUCCESS}
                element={<PaymentSuccess />}
              />
              <Route
                path={ROUTES.RAZORPAY_SUCCESS}
                element={<RazorpaySuccess />}
              />
              <Route
                path={ROUTES.FREEMIUM_SUCCESS}
                element={<FreemiumSuccess />}
              />
            </Route>
            <Route element={<PublicComponent />}>
              <Route path={ROUTES.SIGNUP} element={<Signup />} />
              <>
                <Route path={ROUTES.LOGIN} element={<Login />} />
              </>
            </Route>
            <Route path={ROUTES.PAGENOTFOUND} element={<PageNotFound />} />
          </Routes>
        </Router>
        <CTFooter />
        <Version />
        <ToastContainer autoClose={10000} />
      </div>
    </>
  );
}

export default App;
