import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import HubspotForm from "react-hubspot-form";
import LoadingScreen from "../../LoadingScreen";
import "./HubspotModel.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HubspotModel = ({ openEarlyAccess, handleModalClose }) => {
  const handelSubmit = () => {
    console.log("Submit!");
    setTimeout(() => {
      handleModalClose(false);
    }, 5000);
  };
  return (
    <>
      <Modal className="modal-box close-none"
        open={openEarlyAccess}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {/* <button type="button" className="btn-close"><span className="material-icons-outlined">close</span> Close</button> */}
          <div className="hubspot-form-box">
            <h3>Register Now</h3>
            <HubspotForm
              portalId="6875752"
              formId="9c40b0b2-0928-4876-a5c7-538739f6400a"
              onSubmit={handelSubmit}
              onReady={(form) => console.log("Form ready!")}
              loading={<LoadingScreen open={true}></LoadingScreen>}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default HubspotModel;
