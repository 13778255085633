import { createSlice } from '@reduxjs/toolkit';
import ApiService  from '../service/axiosUtils';
// Slice

const slice = createSlice({
    name: 'payments',
    initialState: {
        payments: '',
    },
    reducers: {
        paymentsSuccess: (state, action) => {
            state.payments = action.payload;
        }
    }
});

export default slice.reducer

// Actions

const { paymentsSuccess } = slice.actions

export const fetchPaymentDetail = (priceDetail,userDetails,tokenId,aboutYou,macAddress) => async dispatch => {
    // TODO: Add these 2 payloads = DataLimit, TimeLimit, planName
   try {
    let params =  JSON.stringify({
        priceID: priceDetail.priceId,
        email: userDetails?.attributes?.email,
        name: `${userDetails?.attributes?.given_name} ${userDetails?.attributes?.family_name}`,
        UserId: userDetails?.username,
        description: "",
        planName: priceDetail.planName,
        yourDomain: `${window.location.origin}`,
        DataLimit: priceDetail?.dataLimit,
        TimeLimit: priceDetail?.timeLimit,
        LicenseExpDate: aboutYou?.LicenseExpDate || 0,
        MacAddress: macAddress,
      });
    await ApiService.postApiData(`${process.env.REACT_APP_PROD_BASE_URL}/payment/client`,params,tokenId)
           .then((response) => dispatch(paymentsSuccess(response.data)));
   }    
   catch (e) {
       return console.error(e.message);
   }
}