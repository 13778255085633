export const signUpUserHubspot = (payload) => {

  /******check if referral code is exists then trim and reassign********/
  if (payload && payload?.referralcode) {
    payload.referralcode = payload.referralcode.trim();
  }

  const raw = JSON.stringify({
    properties: [
      {
        property: "email",
        value: payload?.email,
      },
      {
        property: "firstname",
        value: payload?.name,
      },
      {
        property: "lastname",
        value: payload?.lastName,
      },
      {
        property: "referralcode",
        value: payload?.referralcode,
      },
      {
        property: "createdfrom",
        value: "CARBNMicroSite",
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_HUBSPOT_URL}?method=hs&action=cr`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const registerFormHubspot = (payload, emailId) => {
  const raw = JSON.stringify({
    properties: [
      {
        property: "company",
        value: payload?.Org,
      },
      {
        property: "department_unit_org_chart",
        value: payload?.Dept,
      },
      {
        property: "phone",
        value: payload?.PhNo,
      },
      {
        property: "jobtitle",
        value: payload?.Desgn,
      },
      // {
      //   property: "macaddress",
      //   value: payload?.Mac,
      // },
      {
        property: "custom_country",
        value: payload?.Country,
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_HUBSPOT_URL}?method=hs&action=up&email=${emailId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const registerMacHubspot = (payload, emailId) => {
  const raw = JSON.stringify({
    properties: [
      {
        property: "macaddress",
        value: payload?.Mac,
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
  };

  const p = fetch(
    `${process.env.REACT_APP_HUBSPOT_URL}?method=hs&action=up&email=${emailId}`,
    requestOptions
  );
  return p;
};

export const getHubspotUser = async (email) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  let p = fetch(
    `${process.env.REACT_APP_HUBSPOT_URL}?method=hs&action=get&email=${email}`,
    requestOptions
  );
  return p;
};

export const getIfHubspotUserExist = async (email) => {
  try {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    let p = fetch(
      `${process.env.REACT_APP_HUBSPOT_URL}?method=hs&action=searchemail&email=${email}`,
      requestOptions
    );
    return p;
  } catch (e) {}
};

// offline payment and online success payment
export const dealStageAdminHubspot = (payload, emailId) => {
  const arr = Object.keys(payload);
  const deals = [];

  arr.forEach((d) => {
    if (payload[d]) {
      deals.push({
        name: d,
        value: payload[d],
      });
    }
  });

  const raw = JSON.stringify({
    properties: deals,
  });

  const requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_HUBSPOT_URL}?method=hs&action=deal&email=${emailId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

// export const trackingWebsitePage = (
//   emailId,
//   cookieId,
//   ip,
//   titlePage,
//   pageLink
// ) => {
//   const raw = JSON.stringify({
//     email: emailId,
//     cookieid: cookieId,
//     ipaddress: ip,
//     title: titlePage,
//     pagelink: pageLink,
//   });

//   const requestOptions = {
//     method: "POST",
//     body: raw,
//     redirect: "follow",
//   };

//   fetch(`https://www.clear-trail.com/external-apis/?method=cs`, requestOptions)
//     .then((response) => response.text())
//     .then((result) => console.log(result))
//     .catch((error) => console.log("error", error));
// };
