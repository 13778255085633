import { Grid } from "@mui/material";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import "../../styles/fontSizes.css";
import {
  LAST_NAME_ERROR,
  NAME_ERROR,
  AGREE_ERROR,
  RE_PASSWORD_ERROR,
  ROUTES,
} from "../../utils/constants";
import { getPassword, getValidEmail } from "../../utils/utils";
import FormFooter from "../FormFooter";
import SnackBarHome from "../Snackbar/SnackbarHome";
import "./Signup.css";
import SignupStageOne from "./SignupStageOne";
import { Eye, EyeSlash } from "react-bootstrap-icons";

function Signup({
  user,
  userError,
  handleChange,
  handleSubmit,
  signupStage,
  recaptchaRef,
  handleCodeResend,
  handleCodeSubmit,
  open,
  setOpen,
  message,
  iconVisible,
  handleIconToggle,
  resetIconVisible,
  setResetIconVisible,
  isCheckedCheckBox,
  setCheckedCheckBox,
  isFormValid,
}) {
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

  return (
    <>
      <div className="inner-content-box">
        {/* breadcrumb start */}
        <div className="breadcrumb-box">
          <div className="container">
            <ul>
              <li className="breadcrumb-item">
                <Link className="navLink" to={ROUTES.LANDING_PAGE}>
                  CARBN.AI<sup>TM</sup>
                </Link>
                {/* CARBN.AI<sup>TM</sup> */}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sign up
              </li>
            </ul>
          </div>
        </div>
        {/* breadcrumb end */}

        <div className="signup-box">
          <div className="container">
            <div className="letter-main-box d-md-flex justify-space-between">
              <div className="letter-left">
                <h2>
                Welcome to CARBN.AI<sup style={{ "font-size": "35%" }}>TM</sup>  Download Center
                  
                </h2>
                <p>
                Please sign-up to download; access to CARBN.AI<sup>TM</sup> will be granted upon the verification of your profile as a government user.
                </p>
                <div className="letter-info">
                  <p className="mb-2">
                  Use CARBN.AI<sup>TM</sup> to:
                  </p>
                  <ul>
                    <li>Paint a vivid picture of a suspect's digital life.</li>
                    <li>Expose devices used and suspect's web browsing behaviours.</li>
                    <li>Decode communication channels accessed, movements and more.</li>
                  </ul>
                </div>

                {/* <img className="sign-up-img" src={TempImg} alt="temp" /> */}
              </div>

              <div className="letter-right">
                <div>
                  {signupStage === 0 ? (
                    <div>
                      {/* <Typography
                        fontSize={"35px"}
                        fontWeight={700}
                        variant="body1"
                        color="initial"
                      >
                        Sign up
                      </Typography> */}

                      <div className="field-row">
                        <div className="field-col">
                          <label className="field-title">
                            First Name<span>*</span>
                          </label>

                          <input
                            className="field-input-signup"
                            id="outlined-basic"
                            required
                            label="First Name"
                            variant="outlined"
                            name="name"
                            type="text"
                            value={user?.name}
                            onChange={handleChange}
                            error={userError?.nameError}
                            helperText={userError?.nameError ? NAME_ERROR : ""}
                          />
                          <span className="error-message">
                            {userError?.nameError ? NAME_ERROR : ""}
                          </span>
                        </div>
                        <div className="field-col">
                          <label className="field-title">
                            Last Name<span>*</span>
                          </label>
                          <input
                            className="field-input-signup"
                            label="Last Name"
                            variant="outlined"
                            name="lastName"
                            type="text"
                            required
                            value={user?.lastName}
                            onChange={handleChange}
                            error={userError?.lastNameError}
                            helperText={
                              userError?.lastNameError ? LAST_NAME_ERROR : ""
                            }
                          />
                          <span className="error-message">
                            {userError?.lastNameError ? LAST_NAME_ERROR : ""}
                          </span>
                        </div>
                        
                        <div className="field-col full-width">
                          <label className="field-title">
                            Email<span>*</span>
                          </label>
                          <input
                            className="field-input-signup"
                            label="email ID"
                            fullWidth
                            variant="outlined"
                            name="email"
                            type="text"
                            required
                            value={user?.email}
                            onChange={handleChange}
                            error={userError?.emailError}
                            helperText={getValidEmail(userError)}
                          />
                          <span className="error-message">
                            {getValidEmail(userError)}
                          </span>
                        </div>


                        <div className="field-col">
                          <label className="field-title">
                            Password<span>*</span>
                          </label>

                          <div className="input-password-login">
                            <input
                              className="field-input-signup"
                              label="Password"
                              variant="outlined"
                              name="password"
                              type={iconVisible ? "password" : "text"}
                              required
                              value={user?.password}
                              onChange={handleChange}
                              error={userError?.passwordError}
                              helperText={getPassword(userError)}
                            />
                            <span
                              className="input-icon-login"
                              onClick={handleIconToggle}
                            >
                              {iconVisible ? (
                                <EyeSlash color="black" size={24} />
                              ) : (
                                <Eye color="black" size={24} />
                              )}
                            </span>
                          </div>
                          <span className="error-message">
                            {getPassword(userError)}
                          </span>
                        </div>
                        <div className="field-col">
                          <label className="field-title">
                            Re-enter Password<span>*</span>
                          </label>

                          <div className="input-password-login">
                            <input
                              className="field-input-signup"
                              label="Re Enter Password *"
                              variant="outlined"
                              name="repassword"
                              type={resetIconVisible ? "password" : "text"}
                              required
                              value={user?.repassword}
                              onChange={handleChange}
                              error={userError?.repasswordError}
                              helperText={
                                userError?.repasswordError
                                  ? RE_PASSWORD_ERROR
                                  : ""
                              }
                            />
                            <span
                              className="input-icon-login"
                              onClick={() =>
                                setResetIconVisible((prevState) => !prevState)
                              }
                            >
                              {resetIconVisible ? (
                                <EyeSlash color="black" size={24} />
                              ) : (
                                <Eye color="black" size={24} />
                              )}
                            </span>
                          </div>


                          <span className="error-message">
                            {userError?.repasswordError
                              ? RE_PASSWORD_ERROR
                              : ""}
                          </span>
                        </div>
                      </div>
                      

                      <p className="font-12 password-helper-text">
                      Your password should be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.
                      </p>

                      <div className="field-col full-width">
                          <label className="field-title">
                          Referral Code<span></span>
                          </label>
                          <input
                            className="field-input-signup"
                            label="referral code"
                            fullWidth
                            variant="outlined"
                            name="referralcode"
                            type="text"
                            required
                            value={user?.referralcode}
                            onChange={handleChange}
                          />
                        </div>

                      <SnackBarHome
                        open={open}
                        setOpen={setOpen}
                        severity="error"
                        message={message}
                      />
                      <div className="privacy-input-box">
                        <div className="privacy-checkbox">
                          <input
                            type="checkbox"
                            id="privacyCheckbox"
                            checked={isCheckedCheckBox}
                            onChange={setCheckedCheckBox}
                          />
                          <label for="privacyCheckbox"></label>
                        </div>
                        I agree to the CARBN.AI
                        <sup>TM</sup>{" "}
                        <a
                          className="navLink"
                          href="https://www.clear-trail.com/privacy-policy/"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        <span className="error-message">
                            {userError?.privacyError ?  <div dangerouslySetInnerHTML={{ __html: AGREE_ERROR }} /> : ""}
                        </span>
                      </div>
                      <div className="field-col">
                        <button
                          className="btn primary-btn"
                          onClick={handleSubmit}
                          disabled={!isFormValid()}
                        >
                          Sign up
                        </button>
                      </div>
                      <p>
                        Already have an account?{" "}
                        <Link className="navLink" to={ROUTES.LOGIN}>
                          &nbsp;Log In
                        </Link>
                      </p>
                      <div style={{ marginTop: "15px" }}>
                        For any queries, please call{" "}
                        <a href="tel:+918062281345">+91 80622 81345</a> or email{" "}
                        <a href="mailto:support@carbn.ai">support@carbn.ai</a>.
                      </div>
                    </div>
                  ) : (
                    <SignupStageOne
                      user={user}
                      userError={userError}
                      handleChange={handleChange}
                      handleCodeSubmit={handleCodeSubmit}
                      handleCodeResend={handleCodeResend}
                      open={open}
                      setOpen={setOpen}
                      message={message}
                      recaptchaRef={recaptchaRef}
                    />
                  )}
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={captchaKey}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <FormFooter /> */}
      </div>
    </>
  );
}

export default Signup;
