import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "stage",
  initialState: {
    aboutYou: null,
    hubspotYou: null,
  },
  reducers: {
    setAboutYouStage: (state, action) => {
      state.aboutYou = action.payload;
    },
    setHubspotStage: (state, action) => {
      state.hubspotYou = action.payload;
    },
  },
});

export default slice.reducer;

export const { setAboutYouStage, setHubspotStage } = slice.actions;
