import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../PaymentSuccess.css";
import { ROUTES } from "../../../utils/constants";

const RazorpaySuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.users?.user);
  const { state } = location;
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    if (state?.status === "Success") {
      setTimeout(() => {
        navigate(ROUTES.DOWNLOAD);
      }, 10000);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="pay-container-box">
      <div className="container">
      <div className="pay-inner-box">
        {state?.status === "Success" && (
          <>
            <h2 className={`pay-status-message pay-title`}>
              Payment successful!...
            </h2>
            <p className="message">
              {" "}
              You will be redirected to download exe page shortly. (
              {counter >= 0 ? counter : ""})
            </p>
            {counter < 1 ? (
              <NavLink to={ROUTES.DOWNLOAD} className={"pay-msg"}>
                &#8209; Go to Download
              </NavLink>
            ) : (
              ""
            )}
          </>
        )}

        {state?.status !== "Success" && (
          <>
            <p className={`message pay-error `}>
              Payment not successful!...
            </p>
          </>
        )}
      </div>
      </div>
    </div>
  );
};

export default RazorpaySuccess;
