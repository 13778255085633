import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { Auth } from "aws-amplify";
import { handleAnalyticsClick } from "../../utils/helperCookie";
import { useSelector, useDispatch } from "react-redux";
import { getPageTitle } from "../../utils/utils";
import { setActiveUser, setLoggedIn, setUserData, setPasswordStage } from "../../redux/users";
import { setAboutYouStage, setHubspotStage } from "../../redux/stages";
import { markAllAsRead } from "../../redux/notifications";
import { ROUTES, USER_APPLICANT, USER_REJECTED } from "../../utils/constants";
import {
  fetchNotificationsCount,
  fetchNotifications,
} from "../../redux/notifications";
import NotificationOverlay from "../Notification/NotificationOverlay";
import "./CTHeader.css";
import { toast } from "react-toastify";
const CTHeader = () => {
  const progressBar = useSelector((state) => state?.users?.progress);
  const userData = useSelector((state) => state?.users);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);


  const emailId = userData?.user?.attributes?.email || "";
  const userId = userData?.user?.username;
  const tokenId = userData?.user?.token;
  const isAuthenticate = userData.activeUser;
  const role = userData?.user?.attributes?.profile || "";
  const notificationCount = useSelector(
    (state) => state.notifications.notificationsCount
  );
  const notificationsItem = useSelector(
    (state) => state.notifications?.notifications
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationShow, setNotificationShow] = useState("");

  

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        dispatch(setActiveUser(true));
      })
      .catch((e) => console.log(e));
  }, []);

  const handleSignout = () => {
    if (progressBar > 0 && progressBar <= 100) {
      toast.error(
        "Download in progress. Logging out or leaving the page will disrupt the process.",
        {
          position: "top-center",
        }
      );
    } else {
      Auth.signOut().then(() => {
        dispatch(setActiveUser(false));
        dispatch(setAboutYouStage(null));
        dispatch(setHubspotStage(null));
        dispatch(setLoggedIn(false));
        navigate(ROUTES.LANDING_PAGE);
        dispatch(setUserData(null));

      });
    }
  };
  const handleAnalytics = (link) => {
    const payload = {
      email: emailId,
      pagetitle: getPageTitle(window.location.pathname),
      pagelink: link,
    };
    handleAnalyticsClick(payload);
  };
  const goToLogin = () => {
    navigate(ROUTES.LOGIN);
    dispatch(setPasswordStage(0));
  };
  const showNotification = () => {
    let temp = notificationsItem?.length > 0 ? true : false;
    if (temp) {
      let isVisibal = notificationShow == "open" ? "" : "open";
      setNotificationShow(isVisibal);
    } else {
      setNotificationShow("");
    }
  };
  const markAllRead = () => {
    dispatch(markAllAsRead(userId, tokenId));
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchNotificationsCount(userId, tokenId));
      dispatch(fetchNotifications(userId, tokenId));
    }
  }, [userId]);

  const divRef = useRef(null);

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setNotificationShow("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  //console.log(aboutYou?.StatusVal);

  return (
    <>
      {/* --header start */}
      <div className="header">
        {/* Top Header Start */}
        <div className="top-header">
          {isAuthenticate ? (
            <ul>
              { (aboutYou && aboutYou?.StatusVal != "AppDownloadable" && aboutYou?.StatusVal != "AppDownloaded") &&  <li><NavLink to={ROUTES.APP_STAGE}>Complete Registration</NavLink></li>}
              <li className="alignBadge">
                <div className="badge-icon-parent">
                  <a href="javascript:void(0)" onClick={showNotification}>
                    Notifications
                  </a>
                  {notificationCount > 0 && (
                    <div className="badge-icon">{notificationCount}</div>
                  )}
                </div>
                <div
                  className={`header-drop-down notification-drop-down ${notificationShow}`}
                  ref={divRef}
                >
                  {notificationCount > 0 && (
                    <span className="markallreadspan">
                      <a
                        href="javascript:void(0)"
                        onClick={markAllRead}
                        className="markallread"
                      >
                        Mark all as read
                      </a>
                    </span>
                  )}
                  <div className="header-drop-down-inner">
                    <NotificationOverlay></NotificationOverlay>
                  </div>
                </div>
              </li>
                { (aboutYou?.StatusVal == "AppDownloadable" || aboutYou?.StatusVal == "AppDownloaded") && 
                  <li>
                    <NavLink to={ROUTES.DASHBOARD}>Profile</NavLink>
                  </li>
                }
              <li>
                <a href="javascript:void(0)" onClick={handleSignout}>
                  Log Out
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={goToLogin}>
                  Log In{" "}
                </a>
              </li>
            </ul>
          )}
        </div>
        {/* Top Header End */}

        {/* Bottom Header Start */}
        <div className="bottom-header">
          <div className="logo">
            <a
              href="https://www.clear-trail.com/"
              rel="dofollow"
              target="_blank"
            >
              <img
                src="https://www.clear-trail.com/wp-content/uploads/2020/07/logo-white.png"
                height="50"
                alt="ClearTrail Technologies"
                target="_blank"
              />
            </a>
          </div>
          <div className="navigation">
          <a href="javascript:void(0)" className="toggleMenu">
              <span></span>
              <span></span>
              <span></span>
            </a>

            <div className="menu-header-menu-container">
              <ul id="menu-header-menu" className="nav-site">
                <li
                  id="menu-item-74476"
                  className="last-item menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7447"
                >
                  <a rel="dofollow">Resources</a>
                  <div className="submenu product-drop">
                    <ul>
                      <li id="menu-item-8557" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-8557"><a target="_blank" href="https://blog.clear-trail.com/" class="">Blog</a></li>

                      <li id="menu-item-7463" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-7463"><a target="_blank" rel="dofollow" href="https://www.clear-trail.com/events/" class="">Events</a></li>
                      <li id="menu-item-7463" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-7463"><a target="_blank" rel="dofollow" href="https://www.clear-trail.com/webinars/" class="">Webinars</a></li>
                      <li id="menu-item-7472" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7472"><a target="_blank" rel="dofollow" href="https://www.clear-trail.com/knowledge-center/" class="">Knowledge Centre</a></li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-7446"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7446"
                >
                  <a rel="dofollow" href="#">
                    Products
                  </a>
                  <div className="submenu product-drop">
                    <ul>
                      <li
                        id="menu-item-7972"
                        className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7972"
                      >
                        <a
                          href="https://www.clear-trail.com/products/intelligence-fusion-platform/"
                          target="_blank"
                        >
                          Intelligence Fusion Platform{" "}
                          <span>AI-Powered Insights.</span>
                        </a>
                      </li>
                      <li
                        id="menu-item-7448"
                        className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7448"
                      >
                        <a
                          rel="dofollow"
                          href="https://www.clear-trail.com/products/lawful-interception/"
                          target="_blank"
                        >
                          Lawful Interception{" "}
                          <span>Monitoring Centre. Reimagined.</span>
                        </a>
                      </li>

                      <li id="menu-item-8190" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8190">
                        <a rel="noopener" href="https://www.carbn.ai/" className="">
                          CARBN.AI™ <span>Extract Intelligence from Every IP Packet</span>
                        </a>
                      </li>
                      
                      {/* <li
                        id="menu-item-7449"
                        className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7449"
                      >
                        <a
                          rel="dofollow"
                          href="https://www.clear-trail.com/products/open-source-intelligence/"
                          target="_blank"
                        >
                          Open Source Intelligence{" "}
                          <span>Monitor. Discover. Track.</span>
                        </a>
                      </li> */}
                      {/*<li
                        id="menu-item-7462"
                        className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7462"
                      >
                        <a
                          rel="dofollow"
                          href="https://www.clear-trail.com/products/internet-data-investigations/"
                          target="_blank"
                        >
                          Internet Data Investigations{" "}
                          <span>IP Data Intelligence, Simplified.</span>
                        </a>
          </li>*/}
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-7447"
                  className="last-item menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7447"
                >
                  <a rel="dofollow">Company</a>
                  <div className="submenu product-drop">
                    <ul>
                      <li id="menu-item-7469" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7469"><a target="_blank" rel="dofollow" href="https://www.clear-trail.com/about-us/" class="">About Us</a></li>
                      <li id="menu-item-8457" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-8457"><a target="_blank" href="https://www.clear-trail.com/our-culture/" class="">Our Culture</a></li>
                    </ul>
                  </div>
                </li>
                <li
                  id="menu-item-7461"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7461"
                >
                  <a
                    rel="dofollow"
                    href="https://careers.clear-trail.com/"
                    target="_blank"
                  >
                    Careers
                  </a>
                </li>
                <li
                  id="menu-item-7470"
                  className="cta menu-item menu-item-type-post_type menu-item-object-page menu-item-7470"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/contact-us/"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </li>
                <li
                  id="menu-item-7471"
                  className="custom-btn menu-item menu-item-type-post_type menu-item-object-page menu-item-7471"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/partner/"
                    target="_blank"
                  >
                    Partner with Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        {/* Bottom Header End */}
      </div>
      {/* --header end */}
    </>
  );
};

export default CTHeader;
