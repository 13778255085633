import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import NotificationStrip from "../NotificationStrip";
import { ROUTES } from "../../utils/constants";
import IntercomFile from "../Intercom";
import "./Header.css";

function Header() {
  const userData = useSelector((state) => state.users);
  const userId = userData?.user?.username;
  const isAuthenticate = userData.activeUser;
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_KEY;
  const status = useSelector((state) => state?.stage?.aboutYou?.StatusVal);
  const { pathname } = useLocation();
  let brdcom = pathname;
  brdcom = brdcom.substring(1);

  var str = brdcom;
  var remove_character = str.replace(/-/g, ' ');
  //console.log(remove_character);

  /******if user in 5 stage then breadcrumb*******/
  if(remove_character=="app stage"){

    if(status=="AdminVerified"){
      remove_character = "About You";
    }else if(status=="Registered"){
      remove_character = "Sign Letter";
    }else if(status=="PendingVerification" || status=="VerificationFailed"){
      remove_character = "Letter Verification";
    }else if(status=="VerificationCompleted"){
      remove_character = "Payment Detail";
    }else if(status=="AppDownloadable"){
      remove_character = "Download";
    }else{
      remove_character = "App Stage";
    }
    
  }

  return (
    <>
      {isAuthenticate ? (
        <>
          {/* breadcrumb start */}
          { (pathname === "/" || pathname === "/in") ? (
            ""
          ) : (
            <div className="breadcrumb-box">
              <div className="container">
                <ul>
                  <li className="breadcrumb-item">
                    <Link className="navLink" to={ROUTES.LANDING_PAGE}>
                      CARBN.AI<sup>TM</sup>
                    </Link>
                    {/* CARBN.AI<sup>TM</sup> */}
                  </li>
                  <li className="breadcrumb-item active" id="breadcrumb-section" aria-current="page">
                    {remove_character}
                  </li>
                </ul>
              </div>
            </div>
          )}
          {/* breadcrumb end */}
          <IntercomFile
            appID={INTERCOM_APP_ID}
            // Other optional settings
            user_id={userId}
            email={userData?.user?.attributes?.email}
            name={userData?.user?.attributes?.given_name}
          />
        </>
      ) : (
        ""
      )}

      <NotificationStrip userData={userData} status={status} />
    </>
  );
}

export default Header;
