import React from "react";
import mark from "../../../assets/mark.png";
import expertImage from "../../../assets/expert-image.jpg";

const ExpertsBox = () => {
  return (
    <>
      {/* <div className="expert-box">
        <div className="container d-md-flex justify-content-between align-items-center">
          
          <div className="experts-left wow fadeInUp" data-wow-delay="0.5s">
            <h3>What Experts Say</h3>
            <div className="experts-description">
              Open-source tools miss suspects' behavioural patterns over
              encrypted channels
            </div>

            <div className="experts-info">
              <div className="experts-info-image">
                <img src={mark} alt="Mark" />
              </div>
              <h6>Mr. Mark Bentley</h6>
              <p>Communications Data Expert, UK Police</p>
            </div>
          </div>
          
          
          <div className="experts-right wow fadeInUp" data-wow-delay="0.5s">
            <img src={expertImage} alt="" />
          </div>
          
        </div>
      </div> */}
    </>
  );
};
export default ExpertsBox;
