import React from "react";
import { useSelector } from "react-redux";
import { handleAnalyticsClick } from "../../utils/helperCookie";
import { getPageTitle } from "../../utils/utils";
import "./Footer.css";
import { ROUTES } from "../../utils/constants";

const LandingFooter = () => {
  const pathname = window.location.pathname;
  const emailId =
    useSelector((state) => state?.users?.user?.attributes?.email) || "";
  const progressBar = useSelector((state) => state?.users?.progress);

  const handleAnalytics = (link) => {
    const payload = {
      email: emailId,
      pagetitle: getPageTitle(window.location.pathname),
      pagelink: link,
    };
    handleAnalyticsClick(payload);
  };

  return (
    <>
      <div className="footer">
        {pathname !== ROUTES.DOWNLOAD &&
          pathname !== ROUTES.DASHBOARD &&
          progressBar > 0 && (
            <div className="download-progress">
              <p>
                Downloading CARBN.AI<sup>TM</sup> Application
              </p>

              <div className="download-progress-bar">
                <span style={{ width: `${progressBar}%` }} className="stripes">
                  {progressBar?.toFixed(2)}%
                </span>
              </div>
            </div>
          )}

        <div className="container">
          <div className="ftr-col wide-wid">
            <h2>Transform your intelligence delivery paradigm</h2>
            <a
              href="https://www.clear-trail.com/contact-us/?-ftr-redirect"
              onClick={() =>
                handleAnalytics(
                  "https://www.clear-trail.com/contact-us/?-ftr-redirect"
                )
              }
              rel="dofollow"
              target="_blank"
            >
              Contact Us
            </a>
          </div>

          <div className="ftr-col">
            <h3>Products</h3>

            <div className="menu-products-container">
              <ul id="menu-products" className="menu">
                <li
                  id="menu-item-7976"
                  className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7976"
                >
                  <a
                    href="https://www.clear-trail.com/products/intelligence-fusion-platform/"
                    onClick={() =>
                      handleAnalytics(
                        "https://www.clear-trail.com/products/intelligence-fusion-platform/"
                      )
                    }
                    target="_blank"
                  >
                    Intelligence Fusion Platform
                  </a>
                </li>
                <li
                  id="menu-item-7445"
                  className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7445"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/products/lawful-interception/"
                    onClick={() =>
                      handleAnalytics(
                        "https://www.clear-trail.com/products/lawful-interception/"
                      )
                    }
                    target="_blank"
                  >
                    Lawful Interception
                  </a>
                </li>
                <li><a target="_blank" rel="noopener" href="https://www.carbn.ai/">CARBN.AI™</a></li>
                {/* <li
                  id="menu-item-7450"
                  className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7450"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/products/open-source-intelligence/"
                    onClick={() =>
                      handleAnalytics(
                        "https://www.clear-trail.com/products/open-source-intelligence/"
                      )
                    }
                    target="_blank"
                  >
                    Open Source Intelligence
                  </a>
                </li>
                <li
                  id="menu-item-7452"
                  className="menu-item menu-item-type-post_type menu-item-object-ct_product menu-item-7452"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/products/internet-data-investigations/"
                    onClick={() =>
                      handleAnalytics(
                        "https://www.clear-trail.com/products/internet-data-investigations/"
                      )
                    }
                    target="_blank"
                  >
                    Internet Data Investigations
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="ftr-col">
            <h3>Company</h3>

            <div className="menu-company-container">
              <ul id="menu-company" className="menu">
                <li
                  id="menu-item-7464"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7464"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/about-us/"
                    onClick={() =>
                      handleAnalytics("https://www.clear-trail.com/about-us/")
                    }
                    target="_blank"
                  >
                    About Us
                  </a>
                </li>
                <li
                  id="menu-item-7453"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7453"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/events/"
                    onClick={() =>
                      handleAnalytics("https://www.clear-trail.com/events/")
                    }
                    target="_blank"
                  >
                    Events
                  </a>
                </li>
                <li
                  id="menu-item-7451"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7451"
                >
                  <a
                    rel="dofollow"
                    href="https://careers.clear-trail.com/"
                    onClick={() =>
                      handleAnalytics("https://careers.clear-trail.com/")
                    }
                    target="_blank"
                  >
                    Careers
                  </a>
                </li>
                <li
                  id="menu-item-7465"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7465"
                >
                  <a
                    rel="dofollow"
                    href="https://www.clear-trail.com/knowledge-center/"
                    onClick={() =>
                      handleAnalytics(
                        "https://www.clear-trail.com/knowledge-center/"
                      )
                    }
                    target="_blank"
                  >
                    Knowledge Center
                  </a>
                </li>
                <li id="menu-item-8558" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-8558"><a target="_blank" href="https://blog.clear-trail.com/">Blog</a></li>
              </ul>
            </div>
          </div>

          <div className="ftr-col">
            <h3>
              <a
                href="https://www.clear-trail.com/partner/"
                rel="dofollow"
                onClick={() =>
                  handleAnalytics("https://www.clear-trail.com/partner/")
                }
                target="_blank"
              >
                Partner With Us
              </a>
            </h3>
            <h3>
              <a
                href="https://www.clear-trail.com/contact-us/"
                rel="dofollow"
                onClick={() =>
                  handleAnalytics("https://www.clear-trail.com/contact-us/")
                }
                target="_blank"
              >
                Contact Us
              </a>
            </h3>
            <ul className="ftr-social">
              <li>
                <a
                  href="mailto:info@clear-trail.com"
                  onClick={() => handleAnalytics("mailto:info@clear-trail.com")}
                  target="_blank"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007
                                            c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106
                                            C512,99.077,510.301,92.593,507.496,86.728z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211
                                            L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ClearTrailTechnologies/"
                  target="_blank"
                  onClick={() =>
                    handleAnalytics(
                      "https://www.facebook.com/ClearTrailTechnologies/"
                    )
                  }
                >
                  <svg
                    id="Bold"
                    enableBackground="new 0 0 24 24"
                    height="512"
                    viewBox="0 0 24 24"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/cleartrail-technologies"
                  target="_blank"
                  onClick={() =>
                    handleAnalytics(
                      "https://www.linkedin.com/company/cleartrail-technologies"
                    )
                  }
                >
                  <svg
                    id="Bold"
                    enableBackground="new 0 0 24 24"
                    height="512"
                    viewBox="0 0 24 24"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" />
                    <path d="m.396 7.977h4.976v16.023h-4.976z" />
                    <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/cleartrailt" target="_blank"
                  onClick={() =>
                    handleAnalytics("https://twitter.com/cleartrailt")
                  }
                >
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 488 459"  >
                    
                    <g transform="translate(0.000000,459.000000) scale(0.100000,-0.100000)">
                      <g>
                        <path className="st0" d="M14,4579c-2-3,160-224,359-490s622-832,940-1257l578-773l-37-42c-21-23-446-482-944-1020C412,458,3,14,2,9
                          C0,4,87,0,212,1l213,1l70,77c39,42,401,433,805,870s746,807,760,823l25,29l200-268c110-147,413-553,673-901L3432,0h725
                          c603,0,724,2,720,13c-3,8-187,256-409,553c-222,296-476,636-564,754c-89,118-350,469-582,778c-232,310-422,568-422,572
                          c0,7,1226,1338,1643,1783l119,127h-219l-218-1l-255-276c-140-152-483-524-763-825l-507-548l-615,822l-616,823l-725,5
                          C345,4583,16,4582,14,4579z M1732,3693c237-318,603-807,813-1088c210-280,676-904,1036-1385c360-482,658-881,663-888
                          c6-9-65-12-324-12h-331l-656,878C1419,3221,655,4243,646,4258c-6,9,62,12,323,12h331L1732,3693z"/>
                      </g>
                    </g>
                    </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
        </div>
      </div>
      <div className="ftr-wider copyright">
        <p>
          © {new Date().getFullYear()} ClearTrail Technologies Pvt. Ltd. All
          Rights Reserved. |{" "}
          <a
            href="https://www.clear-trail.com/privacy-policy/"
            rel="dofollow"
            onClick={() =>
              handleAnalytics("https://www.clear-trail.com/privacy-policy/")
            }
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
        </p>
      </div>
    </>
  );
};

export default LandingFooter;
