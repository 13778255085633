import React, { useState } from "react";
import "./HowToBegin.css";
import playIcon from "../../assets/play-icon.png";
import tutorialThumb from "../../assets/app-tutorial-thumb.jpg";
import HowToBeginModal from "./HowToBeginModal";

const HowToBegin = ({ handleStartNow }) => {
  const [open, setOpen] = useState(false);
  const closeHowItWorks = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="started-box mgtop">
        <div className="container d-md-flex justify-space-between">
          <div className="started-left">
            <div className="started-intro">
              <p className="sub-title">How to get started</p>
              <h2 className="headingHow">
                Welcome to CARBN.AI<sup>TM</sup>
                <br /> Let’s get you started.
              </h2>

              <p className="started-paragraph">
                The setup should take about 5 minutes to complete.
              </p>

              <button
                type="button"
                className="btn primary-btn"
                onClick={handleStartNow}
              >
                Start now
              </button>
            </div>
            <div className="tutorial-row">
              <h4 className="tutorial-title">
                Tutorials to help you get started
              </h4>

              {/* <!--col box start--> */}
              <div className="tutorial-box">
                {/* <!--col start--> */}
                <div className="tutorial-col app-tutorial-col">
                  <img src={tutorialThumb} alt="Video" />
                  <button
                    type="button"
                    className="play-btn"
                    onClick={() => setOpen(true)}
                  >
                    <img src={playIcon} alt="Play Icon" />
                  </button>
                </div>
                {/* <!--col end--> */}
              </div>
              {/* <!--col box end--> */}
            </div>
          </div>

          <div className="started-right">
            <div className="timeline-col">
              <h4>Getting to know you.</h4>
              <p>Share your basic details.</p>
            </div>

            <div className="timeline-col">
              <h4>Tell us about your organisation.</h4>
              <p>
                Share an attested letter from your organisation for verification
                as an authorised user from law enforcement.
              </p>
            </div>

            <div className="timeline-col">
              <h4>Select your plan</h4>
              <p>Pick the subscription plan that suits your investigations.</p>
            </div>

            <div className="timeline-col">
              <h4>
                Download CARBN.AI<sup>TM</sup>
              </h4>
              <p>
                Install the setup and start diving into the world of actionable
                insights.
              </p>
            </div>
          </div>
        </div>
        <HowToBeginModal howItWorks={open} closeHowItWorks={closeHowItWorks} />
      </div>
    </>
  );
};

export default HowToBegin;
