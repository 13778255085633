import { Box, Button, Typography } from "@mui/material";
import { ROUTES } from "../../utils/constants";
import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingScreen from "../LoadingScreen";

const PageNotFound = () => {
  const { user, isLoggedIn } = useSelector((state) => state.users);

  if (user && !isLoggedIn) {
    return (
      <div>
        <LoadingScreen open={true} />
      </div>
    );
  }

  return (
    <div className="thank-box inner-content-box">
      <div className="container">
        <div className="confirmation-details text-center">
          <h2 className="text-center">404 :( </h2>
          <h4>Page Not Found</h4>
          <p className="mb-5">The Page you requested could not found</p>
          <NavLink to={ROUTES.LANDING_PAGE} className="btn primary-border-btn">
            Back to Home
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
