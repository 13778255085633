import { createSlice } from "@reduxjs/toolkit";
import ApiService from "../service/axiosUtils";

// Slice

const slice = createSlice({
  name: "notifications",
  initialState: {
    notificationsCount: 0,
    notifications: [],
    eventDayForLandingPage: false,
  },
  reducers: {
    notificationsCountSuccess: (state, action) => {
      state.notificationsCount = action.payload;
    },
    notificationsSuccess: (state, action) => {
      state.notifications = action.payload;
    },
    notificationsMarked: (state, action) => {
      state.notificationsCount = 0;
    },
    setEventDayForLandingPage: (state, action) => {
      state.eventDayForLandingPage = action.payload?.data;
    },
  },
});

export default slice.reducer;

// Actions

const {
  notificationsCountSuccess,
  notificationsSuccess,
  notificationsMarked,
  setEventDayForLandingPage,
} = slice.actions;

export const fetchNotificationsCount =
  (userId, tokenId) => async (dispatch) => {
    try {
      await ApiService.getApiData(`/notifications/count`, tokenId).then(
        (response) => dispatch(notificationsCountSuccess(response?.data))
      );
    } catch (e) {
      return console.error(e?.message);
    }
  };

export const fetchNotifications = (userId, tokenId) => async (dispatch) => {
  try {
    await ApiService.getApiData(`/notifications/id`, tokenId).then((response) =>
      dispatch(notificationsSuccess(response?.data))
    );
  } catch (e) {
    return console.error(e?.message);
  }
};

export const markAllAsRead = (userId, tokenId) => async (dispatch) => {
  try {
    await ApiService.patchApiData(`/notifications/id`, {}, tokenId).then(
      (response) => dispatch(notificationsMarked(response?.data))
    );
  } catch (e) {
    return console.error(e?.message);
  }
};
export const getEventDay = () => async (dispatch) => {
  try {
    await ApiService.getApiDataWToken(`eventDay`).then((response) =>
      dispatch(setEventDayForLandingPage(response?.data))
    );
  } catch (e) {
    return console.error(e?.message);
  }
};
