import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Pricing from "../Pricing";
import LetterUpload from "../ProductRegistration/LetterUpload/LetterUpload";
import SignLetter from "../ProductRegistration/SignLetter";
import StageOneAboutYou from "../ProductRegistration/StageOneAboutYou";
import { STATUS_PENDING_VERIFICATION } from "../../utils/constants";

const stepStyle = {
  "& .MuiStepLabel-root .Mui-active": {
    color: "#f7941d",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#f7941d",
  },
};
const stepLabel = {
  "& .Mui-disabled .MuiStepIcon-text": {
    fill: "rgba(255, 255, 255, 0.3)",
  },
  "& .Mui-disabled": {
    color: "rgba(255, 255, 255, 0.3)",
  },
  "& .Mui-disabled .MuiSvgIcon-root.MuiStepIcon-root": {
    border: "1px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
  },
  "& .MuiStepLabel-iconContainer": {
    marginBottom: "10px",
  },
};

export default function HorizontalLinearStepper({
  steps,
  stage,
  hubspotUser,
  setValidationError,
  validationError,
  activeStep,
  handleBack,
  handleNext,
  aboutYouDetails,
  setAboutYouDetails,
  aboutYouError,
  setAboutYouError,
  downloadSignLetter,
}) {
  const navigate = useNavigate();

  const renderComponent = (step) => {
    console.log(aboutYouDetails);
    console.log(step);
    console.log("steps here");
    if ( (hubspotUser === 1 || hubspotUser === "1") || aboutYouDetails.Loaskip=="Yes") {
      switch (step) {
        case 0:
          return (
            <StageOneAboutYou
              stage={stage}
              aboutYouDetails={aboutYouDetails}
              setAboutYouDetails={setAboutYouDetails}
              aboutYouError={aboutYouError}
              setAboutYouError={setAboutYouError}
              validationError={validationError}
              setValidationError={setValidationError}
            />
          );
        case 1:
          return <Pricing />;
        default:
          return <h3>Default</h3>;
      }
    } else {
      switch (step) {
        case 0:
          return (
            <StageOneAboutYou
              stage={stage}
              aboutYouDetails={aboutYouDetails}
              setAboutYouDetails={setAboutYouDetails}
              aboutYouError={aboutYouError}
              setAboutYouError={setAboutYouError}
              validationError={validationError}
              setValidationError={setValidationError}
            />
          );
        case 1:
          return <SignLetter downloadSignLetter={downloadSignLetter} />;
        case 2:
          return <LetterUpload />;
        case 3:
          return <Pricing />;
        default:
          return <h3>Default</h3>;
      }
    }
  };

  // console.log(hubspotUser);
  // console.log("hubspot user");
  // console.log(activeStep);
  // console.log("active step");

  return (
    <Box
      sx={{
        width: "98vw",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        // border:'1px solid red'
      }}
    >
      <Stepper
        activeStep={activeStep}
        sx={{
          marginTop: "30px",
          padding: "20px",
          display: "flex",
          gap: 3,
        }}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label} sx={stepStyle}>
              <div className="stepLabel">
                <StepLabel sx={stepLabel}>{label}</StepLabel>
              </div>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderComponent(activeStep)}

          <div className="download-footer">
            <div className="download-footer-left">
              <button
                className="btn primary-btn secondary-btn"
                onClick={handleBack}
              >
                Back
              </button>
            </div>

            { ((hubspotUser === "1" || hubspotUser === 1) || aboutYouDetails?.Loaskip=="Yes") ? (
              activeStep === 1 ? null : (
                <div className="download-footer-right 2">
                  <button className="btn primary-btn" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </button>
                </div>
              )
            ) : activeStep === 3 ? null : (
              <div className="download-footer-right">
                <button
                  className="btn primary-btn 3"
                  onClick={handleNext}
                  disabled={
                    activeStep === 2 &&
                    stage?.StatusVal === STATUS_PENDING_VERIFICATION
                  }
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </button>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Box>
  );
}
