import React from "react";
import carbonLogo from "../../../assets/carbon-logo.png";

const ExperienceBox = () => {
  return (
    <>
      <div className="experience-box">
        <div className="container">
          <div className="carbon-logo text-center">
            <img src={carbonLogo} alt="CARBN.AI" /><sup>TM</sup>
          </div>

          <div className="experience-inner-box d-md-flex justify-content-between">
            {/* left start */}
            <div className="experience-left wow fadeInUp">
              Experience
              <br /> the Future of PCAP Analysis
            </div>
            {/* left end */}

            {/* right start */}
            <div className="experience-right wow fadeInUp">
              <p>
                CARBN.AI<sup>TM</sup> is an IP data decoding and analytics tool,
                that automates the processing of volumes of data to reveal
                actionable intelligence swiftly.
              </p>
              <p>
                Its deep packet inspection capabilities construct a 360-degree
                profile, exposing devices used, web browsing behaviours,
                communication channels accessed, and more - painting a vivid
                picture of a suspect's digital life.
              </p>
            </div>
            {/* right end */}
          </div>
        </div>
      </div>
    </>
  );
};
export default ExperienceBox;
