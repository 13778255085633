import React, { useState } from "react";
import { Modal } from "@mui/material";
import "./Dashboard.css";
import { style, invoiceStyle, paraStyle } from "./styles";
import "./index.css";
import { getInvoiceKeyDate } from "../../utils/utils";

function InvoiceModal({
  handleModalOpen,
  openInvoiceModal,
  handleInvoiceModal,
  invoicePreviewModal,
  handleInvoicePreviewModal,
  invoiceData,
  downloadInvoice,
  invoiceUrl,
}) {
  return (
    <>
      <Modal
        className="modal-box invoice-modal-box"
        open={openInvoiceModal}
        onClose={() => handleInvoiceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-body">
          <div className="invoice-table">
            <table cellpadding="0" cellspacing="0" align="center">
              <thead>
                <tr>
                  <th>Purchase Date</th>
                  <th>Plan Name</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.map((e) => {
                  return (
                    <tr>
                      <td>{getInvoiceKeyDate(e?.PurTm)}</td>
                      <td>{e?.PlanNm}</td>
                      <td>
                        <button
                          className="history-link"
                          onClick={() => {
                            downloadInvoice(e?.TxnID);
                          }}
                        >
                          Preview
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      <Modal
        open={invoicePreviewModal}
        onClose={() => handleInvoicePreviewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div
            onClick={() => handleInvoicePreviewModal(false)}
            style={paraStyle}
          >
            X
          </div>
          <iframe src={invoiceUrl} width="500px" height="500px" />
        </div>
      </Modal>
    </>
  );
}

export default InvoiceModal;
