import { Button, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uploadImg from "../../../assets/upload-icon.png";
import { setAboutYouStage } from "../../../redux/stages";
import ApiService from "../../../service/axiosUtils";
import {
  ENDPOINT,
  NON_PDF_FILE_ERROR,
  PDF_FILE_FORMAT_ERROR,
  PDF_FILE_UPLOADED_SUCCESS,
  SIZE_PDF_FILE_ERROR,
  STATUS_PENDING_VERIFICATION,
  STATUS_VERIFICATION_FAILED,
} from "../../../utils/constants";
import { formatBytes } from "../../../utils/utils";
import ModalDashboard from "../../Dashboard/ModalDashboard";
import SnackBar from "../../Snackbar";
import ConfirmationModal from "./ConfirmationModal";
import "./LetterUpload.css";
import {
  fetchNotifications,
  fetchNotificationsCount,
} from "../../../redux/notifications";

const DropFileInput = () => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [activeStage, setActiveStage] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [fileBase, setFileBase] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [licenseUrl, setLicenseUrl] = useState("");

  const [open, setOpen] = useState(false);
  const [errApi, setErrApi] = useState("");
  const [msgType, setMsgType] = useState("");

  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState(null);


  const userId = useSelector((state) => state?.users?.user?.username);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const stage = useSelector((state) => state?.stage?.aboutYou);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  useEffect(() => {
    if (stage?.StatusVal === STATUS_PENDING_VERIFICATION) {
      setActiveStage(2);
    } else if (stage?.StatusVal === STATUS_VERIFICATION_FAILED) {
      setActiveStage(3);
    } else if (stage?.ContractS3Key) {
      setActiveStage(2);
    }
  }, []);

  const getNotifications = () => {
    dispatch(fetchNotificationsCount(userId, tokenId));
    dispatch(fetchNotifications(userId, tokenId));
  };

  const handleConfirmModalClose = (visible) => {
    setOpenConfirmModal(visible);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    setFileType(newFile.type);

    if (newFile?.type !== "application/pdf") {
      setOpen(true);
      setErrApi(NON_PDF_FILE_ERROR);
      setMsgType("error");
      return;
    }
    if (newFile?.size >= 10485760) {
      setOpen(true);
      setErrApi(SIZE_PDF_FILE_ERROR);
      setMsgType("error");
      return;
    }
    setSelectedFile(newFile);

    let file = null;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(newFile);
    fileReader.onload = function (fileLoadedEvent) {
      file = fileLoadedEvent.target.result;
      let fileBaseName = file.split("base64,");
      setFileBase(fileBaseName[1]);
      setActiveStage(1);
    };

    /****preview file****/
    fileReader.onloadend = () => {
      setFilePreview(fileReader.result);
    };


  };

  const fileRemove = () => {
    setSelectedFile(null);
    setFileBase(null);
    setFileUploading(false);
    setActiveStage(0);
    setProgress(0);
  };

  const uploadFile = () => {
    if (fileBase && selectedFile?.name) {
      handleConfirmModalClose(false);
      setFileUploading(true);
      const body = {
        fileName: selectedFile?.name,
        data: fileBase,
      };
      setActiveStage(2);

      ApiService.postApiDataWithUpload(
        ENDPOINT.FILE_UPLOAD,
        body,
        tokenId,
        (progressEvent) => {
          const { loaded, total } = progressEvent;
          const calculatedProgress = (loaded / total) * 100;
          setProgress(calculatedProgress);
          if (calculatedProgress >= 100) {
            setProgress(0);
          }
        }
      )
        .then(() => {

          setFileUploading(false);

          setOpen(true);
          setErrApi(PDF_FILE_UPLOADED_SUCCESS);
          setMsgType("success");
          
          getNotifications();

          ApiService.getApiData(`/users/id`, tokenId)
            .then((res) => {
              if (res?.data && res?.data?.length > 0) {
                dispatch(setAboutYouStage(res?.data[0]));
              }
            })
            .catch((e) => {
              console.log(e?.message);
              fileRemove();
            });
        })
        .catch((err) => {
          setOpen(true);
          setErrApi(err?.response?.data?.error || PDF_FILE_FORMAT_ERROR);
          setMsgType("error");
          fileRemove();
        });
    } else {
      setOpen(true);
      setErrApi(PDF_FILE_FORMAT_ERROR);
      setMsgType("error");
      setFileUploading(false);
    }
  };

  const previewOnClick = () => {
    setOpenModal(true);
    setLicenseUrl(filePreview);
  }

  const downloadUploadLetter = () => {
    ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: "signed_letter",
      },
      tokenId
    )
      .then((resp) => {
        if (resp?.data?.appUrl) {
          setOpenModal(true);
          setLicenseUrl(resp?.data?.appUrl);
        }
      })
      .catch((e) => console.log(e));
  };

  const renderComponent = (activeStage) => {
    switch (activeStage) {
      case 0:
        return (
          <>
            <div className="signed-upload-box">
              <h3 className="mb-20">
                Please upload the signed letter for verification
              </h3>
              

              <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              >
                <div className="upload-box">
                  <label>
                    <img
                      src={uploadImg}
                      alt=""
                      height={"70px"}
                      width={"40px"}
                    />
                    <h5>Select a file or drag and drop here</h5>

                    <p>PDF file size no more than 10MB</p>

                    <span>Select File</span>
                  </label>
                </div>
                <input
                  type="file"
                  value=""
                  accept=".pdf"
                  onChange={onFileDrop}
                />
              </div>
            </div>
          </>
        );

      case 1:
        return (
          <>
            <div className="signed-upload-box">
              <h3 className="mb-20">
                Please upload the signed letter for verification
              </h3>
              

              <div className="close-file-container">
                <p>CARBN.AI_LetterOfAuthorisation.pdf</p>
                <p className="preview-text" onClick={previewOnClick}>Preview</p>
                <button
                  type="button"
                  className="upload-close"
                  onClick={fileRemove}
                >
                  x
                </button>
              </div>

              {filePreview && (
                <div className="pdf-container">
                  {/* {fileType.startsWith('image/') && <img src={filePreview} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />} */}
                  {/* {fileType === 'application/pdf' && <embed src={filePreview} type="application/pdf" width="100%" height="300px" />} */}
                  {/* {fileType.startsWith('text/') && <iframe src={filePreview} title="File Preview" width="100%" height="300px"></iframe>} */}
                  {/* {fileType === 'application/pdf' && <object data={filePreview} type="application/pdf" width="100%" height="300px" />} */}
                  

                </div>
              )}
              
              <button
                type="button"
                className="btn primary-border-btn"
                onClick={() => handleConfirmModalClose(true)}
                disabled={fileUploading}
              >
                UPLOAD
              </button>
            </div>
          </>
        );

      case 2:
        return (
          <>
            {stage?.StatusVal === STATUS_PENDING_VERIFICATION && (
              <>
                <div className="signed-upload-box">
                  <h3 className="mb-20">
                    Thank You! Your letter is being reviewed.
                  </h3>
                  <p className="mb-25">This may take up to 48 hours.</p>
                </div>
              </>
            )}
            <div className="upload-progress-box signed-upload-box">
              <div className="upload-progress-container">
                <div className="upload-progress-child">
                  <p className="fileName">CARBN.AI_LetterOfAuthorisation.pdf</p>
                  <p
                    onClick={() => {
                      downloadUploadLetter();
                    }}
                    className="preview-text"
                    style={{ marginRight: "10px" }}
                  >
                    Preview
                  </p>
                  {stage?.StatusVal === STATUS_PENDING_VERIFICATION && (
                    <p>Verification in progress...</p>
                  )}
                </div>

                <div className="upload-progress-child">
                  <p>
                    {selectedFile?.size
                      ? formatBytes(selectedFile?.size)
                      : null}
                  </p>

                  {/* <div className="close-icon" onClick={fileRemove}>
                    x
                  </div> */}
                </div>
              </div>
              <div>
                {progress > 0 && (
                  <LinearProgress variant="determinate" value={progress} />
                )}
              </div>
            </div>
          </>
        );

      case 3:
        return (
          <>
            <div className="signed-upload-box">
              <h3 className="mb-20">We could not verify your letter.</h3>
              <div className="verify-failed-progress-box">
                <div className="upload-progress-container">
                  <div className="upload-progress-child">
                    <p>CARBN.AI_LetterOfAuthorisation.pdf</p>
                    {/* <p
                      onClick={() => {
                        downloadUploadLetter();
                      }}
                      className="preview-text"
                    >
                      Preview
                    </p> */}
                    <p
                      className="verification-failed"
                      style={{ marginLeft: "10px" }}
                    >
                      Verification failed  <span className="material-icons-outlined">feedback</span>
                    </p>
                  </div>
                  <div className="upload-progress-child">
                    <p>
                      {selectedFile?.size
                        ? formatBytes(selectedFile?.size)
                        : null}
                    </p>
                    {/* <div className="close-icon" onClick={fileRemove}>
                      x
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="letter-verification-failed">
                <p className="letter-verification-failed-text">
                Your Letter of Authorisation has been rejected because: {stage?.VerifyReason}
                </p>
                <p className="letter-verification-error-failed-text">
                Please upload a corrected document to move forward.
                </p>
              </div>
              <button type="button" className="btn primary-border-btn" onClick={fileRemove}>
                UPLOAD AGAIN 
              </button>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className="box">{renderComponent(activeStage)}</div>
      <SnackBar
        open={open}
        setOpen={setOpen}
        severity={msgType}
        message={errApi}
      />
      <ModalDashboard
        openModal={openModal}
        appUrl={licenseUrl}
        handleModalClose={handleModalClose}
      />
      <ConfirmationModal
        openConfirmModal={openConfirmModal}
        handleConfirmModalClose={handleConfirmModalClose}
        uploadFile={uploadFile}
        fileName={selectedFile?.name}
      />
    </>
  );
};

export default DropFileInput;
