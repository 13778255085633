export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  color: "black",
  backgroundColor: "white",
  border: "2px solid #000",
  padding: "40px",
};

export const paraStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "end",
  fontWeight: "bold",
  cursor: "pointer",
};

export const invoiceStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 500,
  backgroundColor: "black",
  color: "white",
  border: "2px solid #000",
  padding: "40px",
  overflowY: "scroll",
};
