import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { callTrackingApi } from "../../utils/helperCookie";
import {
  DOWNLOAD_EXE_ERROR,
  LICENSE_ERROR,
  PAGES,
  TRANSACTION_ID_MISSING,
} from "../../utils/constants";
import JSZip from "jszip";
import Axios from "axios";
import DashboardHome from "../../Components/Dashboard";
import LoadingScreen from "../../Components/LoadingScreen";


import ApiService from "../../service/axiosUtils";
import { setAboutYouStage } from "../../redux/stages";
import { toast } from "react-toastify";
import {
  dealStageAdminHubspot,
  getHubspotUser,
} from "../../service/hubspotAxios";
import { setDownloadClicked, setDownloadProgress } from "../../redux/users";
import { sortBy } from "lodash";

const Dashboard = () => {
  
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const progressBar = useSelector((state) => state?.users?.progress);
  const attributes = useSelector((state) => state?.users?.user?.attributes);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const userId = useSelector((state) => state?.users?.user?.username);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const eventDay = useSelector((state) => state?.users?.eventDay);
  const downloadClicked = useSelector((state) => state?.users?.downloadClicked);
  const planName = aboutYou ? aboutYou.Plan : "Freemium";

  const [open, setOpen] = useState(false);
  const [errApi, setErrApi] = useState("");
  const [loading, setLoading] = useState(false);

  const [aboutYouDetails, setAboutYouDetails] = React.useState(aboutYou);
  const [progress, setProgress] = useState(0);
  const [downloadEnable, setDownloadEnable] = useState(false);
  const [pleaseWaitText, setPleaseWaitText] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [invoicePreviewModal, setInvoicePreviewModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [licenseUrl, setLicenseUrl] = useState("");


  // useEffect(() => {
  //   let t = tokenId;
  //   let i = setInterval(() => {
  //     ApiService.getApiData(`/users/id`, t)
  //       .then((res) => {
  //         if (res?.data && res?.data?.length > 0) {
  //           dispatch(setAboutYouStage(res?.data[0]));
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e?.message);
  //       });
  //   }, 10000);

  //   return () => {
  //     clearInterval(i);
  //   };
  // }, []);

  useEffect(() => {
    if (attributes?.emailId) {
      callTrackingApi({
        email: attributes?.emailId,
        pagetitle: PAGES.DASHBOARD,
        pagelink: pathname,
      });
    }
  }, [attributes]);

  useEffect(() => {
    setAboutYouDetails(aboutYou);
  }, [aboutYou]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleInvoicePreviewModal = (visible) => {
    setInvoicePreviewModal(visible);
  };

  const handleInvoiceModal = (visible) => {
    setOpenInvoiceModal(visible);
  };

  const updateDownloadStatus = (payload) => {
    ApiService.patchApiData(`/users/id`, payload, tokenId)
      .then(() => {
        ApiService.getApiData(`/users/id`, tokenId)
          .then((res) => {
            if (res?.data && res?.data?.length > 0) {
              dispatch(setAboutYouStage(res?.data[0]));
            }
          })
          .catch((e) => {
            console.log(e?.message);
          });
      })
      .catch((err) => console.log(err));
  };

  const downloadZipWithTxtFile = async () => {
    if (!planName) {
      return;
    }
    try {      
      
      setLoading(true);      
      dispatch(setDownloadClicked(true));
      let day = eventDay;
      if (day === "true") {        
        // EVENT DAY IS TRUE
        let payload = {
          EventDay: true,
          status: "AppDownloaded",
          FirstTimeUser: false,
          Plan: "Freemium",
          Country: hubspotYou?.Country
        };

        ApiService.getApiData(
          `/download/app?license=Y&plan=${planName}`,
          tokenId
        )
          .then((resp) => {

            console.log(resp);
            console.log("response data in if");
            if (resp?.data) {
              downloadExeFile(
                resp?.data?.appUrl,
                resp?.data?.licenseUrl,
                resp?.data?.checksumTxt,
                payload,
                resp?.data?.fileName
              );
            } else {
              setLoading(false);              
              setOpen(true);
              setErrApi(DOWNLOAD_EXE_ERROR);
              resetProgress();
            }
          })
          .catch((e) => {
            setLoading(false);           
            setOpen(true);
            setErrApi(e?.message || DOWNLOAD_EXE_ERROR);
            resetProgress();
          });
      } else {
        // EVENT DAY IS FALSE
        let payload = {
          EventDay: false,
          status: "AppDownloaded",
          FirstTimeUser: false,
          Country: hubspotYou?.Country
        };

        ApiService.getApiData(
          `/download/app?license=Y&plan=${planName}&country=${hubspotYou?.Country}`,
          tokenId
        )
          .then((resp) => {

            console.log(resp);
            console.log("response data in else");

            if (resp?.data) {
              downloadExeFile(
                resp?.data?.appUrl,
                resp?.data?.licenseUrl,
                resp?.data?.checksumTxt,
                payload,
                resp?.data?.fileName
              );
            } else {
              setLoading(false);
              setOpen(true);
              setErrApi(DOWNLOAD_EXE_ERROR);
              resetProgress();
            }
          })
          .catch((e) => {
            setLoading(false);
            setOpen(true);
            setErrApi(e?.message || DOWNLOAD_EXE_ERROR);
            resetProgress();
          });
      }
    } catch (err) {
      setOpen(true);
      setErrApi(err?.message || DOWNLOAD_EXE_ERROR);
      resetProgress();
    }
  };

  const calculateChecksum = async (responseData) => {
    try {
      const buffer = responseData.data;
      const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const checksum = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

      return checksum;
    } catch (error) {
      return error;
    }
  };

  const downloadExeFile = async (
    zipFileUrl,
    licenseUrl,
    checksumText,
    payload,
    fileName
  ) => {
    let existingZip = null;
    if (zipFileUrl) {

      var LicensePreUrl = await getLicenseUrlPre();
      console.log(LicensePreUrl);

      // download the zip file
      const existZipResponse = await Axios.get(zipFileUrl, {
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setTotalSize(total);
          const calculatedProgress = (loaded / total) * 100;
          dispatch(setDownloadProgress(calculatedProgress));
          if (calculatedProgress >= 98) {
            dispatch(setDownloadProgress(99));
            setPleaseWaitText(true);
          }
          setLoading(false);
        },
      }).catch((err) => {
        setLoading(false);
        setOpen(true);
        setErrApi(err?.message || DOWNLOAD_EXE_ERROR);
        resetProgress();
      });
      if (!existZipResponse?.data) {
        return;
      }

      const checkSumKeyFromAWS = checksumText;

      const sha256Checksum = await calculateChecksum(existZipResponse);
      console.log(sha256Checksum);

      const areEqual =
        sha256Checksum.toUpperCase() === checkSumKeyFromAWS.toUpperCase();
      if (!areEqual) {
        console.log("not matched");
        setOpen(true);
        setErrApi("Encountering issues; kindly attempt a redownload.");
        return;
      }

      console.log("matched");

      existingZip = await JSZip.loadAsync(existZipResponse.data);
    }
    // TODO: to check for event day flag
    if (LicensePreUrl && aboutYou?.Plan === "Freemium") {
      // download the text file
      const textResponse = await Axios.get(LicensePreUrl, {
        responseType: "arraybuffer",
      }).catch(() => {
        setOpen(true);
        setErrApi(DOWNLOAD_EXE_ERROR);
        resetProgress();
      });
      if (!textResponse?.data) {
        return;
      }
      existingZip.file("license.txt", textResponse?.data);
    }

    const content = await existingZip.generateAsync({ type: "blob" });
    dispatch(setDownloadProgress(100));

    if (aboutYou?.Plan === "Freemium") {
      // CALL DEAL API
      const p = getHubspotUser(attributes?.email);
      p.then((r) => r.json()).then(() => {
        const payload = {
          dealname: attributes?.given_name + " " + attributes?.family_name,
          dealstage: "150835226",
        };
        dealStageAdminHubspot(payload, attributes?.email);
      });
    }

    updateDownloadStatus(payload);

    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = fileName || process.env.REACT_APP_ZIP_NAME;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    resetProgress();

    //save which version downloaded by user
    ApiService.patchApiData(`/users/id?DownloadVersion=${fileName}`, {}, tokenId)
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      
    });
    
  };

  const getLicenseUrlPre = async () => {
    let doc = "license";
    var preLicenseUrl = "";
    await ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: doc,
        TransactionID: aboutYou?.TransactionID,
      },
      tokenId
    )
      .then((resp) => {
         preLicenseUrl = resp?.data?.appUrl;
      })
      return preLicenseUrl;
  }

  const resetProgress = () => {
    // dispatch(setDownloadClicked(false));
    setPleaseWaitText(false);
    dispatch(setDownloadProgress(0));
  };

  const downloadLetter = () => {
    ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: "signed_letter",
      },
      tokenId
    )
      .then((resp) => {
        if (resp?.data?.appUrl) {
          setOpenModal(true);
          setLicenseUrl(resp?.data?.appUrl);
        }
      })
      .catch((e) => {

        console.log("response data in else 33");


        toast.error(e?.message, {
          position: "top-center",
        });
      });
  };

  const downloadLicenseKey = async () => {
    // Event true and plan Freemium => licenseEvent-Freemium
    // licensecurrent
    let doc = "license";
    // if (eventDay === "true" && aboutYou?.Plan === "Freemium") {
    //   doc = "licenseEvent-Freemium";
    // } else {
    //   doc = "license";
    // }
    ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: doc,
        TransactionID: aboutYou?.TransactionID,
      },
      tokenId
    )
      .then((resp) => {
        const licenseUrl = resp?.data?.appUrl;

        Axios.get(licenseUrl, {
          responseType: "arraybuffer",
        })
          .then((resp) => {
            console.log(resp);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([resp?.data]));
            link.download = "license.txt";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((e) => {

            console.log("response data in else 22");

            toast.error(LICENSE_ERROR || e?.message, {
              position: "top-center",
            });
          });
      })
      .catch((e) => console.log(e));
  };

  const downloadInvoice = async (txtId) => {
    if (!txtId) {
      toast.error(TRANSACTION_ID_MISSING, {
        position: "top-center",
      });
      return;
    }
    ApiService.getApiDataDownload(
      "/download/userfiles",
      {
        UserID: userId,
        docName: "invoice",
        TransactionID: txtId,
      },
      tokenId
    )
      .then((resp) => {
        console.log(resp?.data);

        const licenseUrl = resp?.data?.appUrl;
        if (licenseUrl) {
          handleInvoicePreviewModal(true);
          setInvoiceUrl(resp?.data?.appUrl);
        }
      })
      .catch((e) => {

        console.log("response data in else 11");

        toast.error(e?.message, {
          position: "top-center",
        });
      });
  };

  const showHistoryInvoice = () => {
    ApiService.getApiData("/users/payments?paymentStatus=paid", tokenId)
      .then((resp) => {
        console.log(resp?.data);
        setOpenInvoiceModal(true);
        let obj = [];
        resp?.data?.forEach((e) => {
          if (e?.PlanNm !== "Freemium") {
            obj.push(e);
          }
        });
        const iteratees = obj => -obj.PurTm;
        const sortArr = sortBy(obj, iteratees);
        setInvoiceData(sortArr);
      })
      .catch((e) => {
        toast.error(e?.message, {
          position: "top-center",
        });
      });
  };
  
  return (
    <>
    <DashboardHome
      attributes={attributes}
      aboutYou={aboutYouDetails}
      open={open}
      setOpen={setOpen}
      errApi={errApi}
      totalSize={totalSize}
      progress={progress}
      downloadEnable={downloadEnable}
      pleaseWaitText={pleaseWaitText}
      downloadZipWithTxtFile={downloadZipWithTxtFile}
      planName={planName}
      downloadLetter={downloadLetter}
      downloadLicenseKey={downloadLicenseKey}
      downloadInvoice={downloadInvoice}
      openModal={openModal}
      licenseUrl={licenseUrl}
      handleModalClose={handleModalClose}
      progressBar={progressBar}
      downloadClicked={downloadClicked}
      showHistoryInvoice={showHistoryInvoice}
      handleInvoiceModal={handleInvoiceModal}
      invoicePreviewModal={invoicePreviewModal}
      handleInvoicePreviewModal={handleInvoicePreviewModal}
      openInvoiceModal={openInvoiceModal}
      invoiceData={invoiceData}
      invoiceUrl={invoiceUrl}
    />
    {loading && <LoadingScreen open={loading} />}
    </>
  );

};

export default Dashboard;
