import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiService from "../../service/axiosUtils";
import {
  dealStageAdminHubspot,
  getHubspotUser,
} from "../../service/hubspotAxios";
import { ROUTES } from "../../utils/constants";
import LoadingScreen from "../LoadingScreen";
import "./PaymentSuccess.css";
import confirmedIcon from "../../assets/confirmed-icon.png";


const PaymentSuccess = () => {
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.users?.user);
  const { state } = location;
  const [counter, setCounter] = useState(10);
  // get session id
  const query = new URLSearchParams(window.location.search);
  const session_id = query.get("session_id");
  const paymentmode = query.get("paymentmode");
  const [paymentStatus, setPaymentStatus] = useState();
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Check if payment success or failure
  useEffect(() => {
    if (tokenId) {
      if (session_id) {
        gtePaymentStatus();
      }
    }
  }, [tokenId]);

  const gtePaymentStatus = () => {
    setLoading(true);
    ApiService.getApiData(
      `${process.env.REACT_APP_PROD_BASE_URL}/payment/sessionStatus/${session_id}`,
      tokenId
    )
      .then((response) => {
        const status = response?.data?.status;
        // const status = "failure";
        setPaymentStatus(status);
        if (status === "complete") {
          setTimeout(() => {
            if (session_id && paymentStatus !== "complete") {
              navigate(ROUTES.DOWNLOAD);
            }
          }, 10000);

          setMsg("Payment successful!...");

          // CALL DEAL HUBSPOT API
          const p = getHubspotUser(userDetails?.attributes?.email);
          p.then((r) => r.json())
            .then((res) => {
              console.log(res);
              const payload = {
                dealname:
                  userDetails?.attributes?.given_name +
                  " " +
                  userDetails?.attributes?.family_name,
                dealstage: "147595019",
              };
              dealStageAdminHubspot(payload, userDetails?.attributes?.email);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          setMsg("Payment not successful!...");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.message, {
          position: "top-center",
        });
      });
  };

  return (
    <div className="pay-container-box">
      <div className="container">
      <div className="pay-inner-box">
        {session_id && paymentStatus === "complete" && (
          <>
            <h2 className={`pay-status-message pay-title`}> {msg} </h2>
            <p className="message">
              {" "}
              You will be redirected to download exe page shortly. (
              {counter >= 0 ? counter : ""})
            </p>
            {counter < 1 ? (
              <NavLink to={ROUTES.DOWNLOAD} className={"pay-msg"}>
                &#8209; Go to Download
              </NavLink>
            ) : (
              ""
            )}
          </>
        )}

        {session_id && paymentStatus !== "complete" && (
          <>
            <label className={`pay-status-message pay-error `}> {msg} </label>
          </>
        )}

        {paymentmode === "offline" && (
          <>
            <img src={confirmedIcon} alt="confirmedIcon" className="confirmed-icon" />
            <h2 className={`pay-status-message pay-title`}>
              {" "} Your Order is confirmed!
            </h2>
            <p className="message">
              {" "}
              Please pay and get access of Exe.{" "}
            </p>
          </>
        )}
      </div>
      </div>
      {loading && <LoadingScreen open={loading} />}
    </div>
  );
};

export default PaymentSuccess;
