import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { markAllAsRead } from "../../redux/notifications";
import { PAGES } from "../../utils/constants";
import { callTrackingApi } from "../../utils/helperCookie";
import "./Notification.css";

const NotificationOverlay = () => {
  const { pathname } = useLocation();
  const emailId = useSelector((state) => state?.users?.user?.attributes?.email);
  const userId = useSelector((state) => state?.users?.user?.username);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const notificationCount = useSelector(
    (state) => state.notifications?.notificationsCount
  );
  const notificationsItem = useSelector(
    (state) => state.notifications?.notifications
  );
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();
  const markAllRead = () => {
    dispatch(markAllAsRead(userId, tokenId));
  };

  useEffect(() => {
    if (emailId) {
      callTrackingApi({
        email: emailId,
        pagetitle: PAGES.NOTIFICATION,
        pagelink: pathname,
      });
    }
  }, [emailId]);

  useEffect(() => {
    if (notificationsItem) {
      setNotificationData(notificationsItem);
    }
  }, [notificationsItem]);

  return (
    <>
      <div className="header-menu d-flex align-center"></div>
      <ul>
        {notificationData.map((notification, index) => (
          <li key={index}>
            <a href="#">
              <div className="notification-header-profile">
                {notification.Sub?.charAt(0)}
              </div>
              <h6>{notification.Sub}</h6>
              <p>{notification.Body}</p>
              <span>{notification.TxnDt}</span>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default NotificationOverlay;
