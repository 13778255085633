import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "users",
  initialState: {
    user: null,
    activeUser: false,
    signUpEmail: "",
    eventDay: "false",
    firstTimeUser: false,
    Country: "",
    passwordStage: 0,
    progress: 0,
    downloadClicked: false,
    isLoggedIn: false,
    confirmLoading: false,
    hubspotUser: "0",
    razorpayAddress: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setSignupEmail: (state, action) => {
      state.signUpEmail = action.payload;
    },
    setEventDay: (state, action) => {
      state.eventDay = action.payload;
    },
    setFirstTimeUser: (state, action) => {
      state.firstTimeUser = action.payload;
    },
    setCountry: (state, action) => {
      state.Country = action.payload;
    },
    setPasswordStage: (state, action) => {
      state.passwordStage = action.payload;
    },
    setDownloadProgress: (state, action) => {
      state.progress = action.payload;
    },
    setDownloadClicked: (state, action) => {
      state.downloadClicked = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setConfirmLoading: (state, action) => {
      state.confirmLoading = action.payload;
    },
    setHubUser: (state, action) => {
      state.hubspotUser = action.payload;
    },
    setRazorpayAddress: (state, action) => {
      state.razorpayAddress = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setUserData,
  setCurrentUser,
  setActiveUser,
  setSignupEmail,
  setEventDay,
  setFirstTimeUser,
  setCountry,
  setPasswordStage,
  setDownloadProgress,
  setDownloadClicked,
  setLoggedIn,
  setConfirmLoading,
  setHubUser,
  setRazorpayAddress,
} = slice.actions;
