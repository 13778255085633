import { Box } from "@mui/material";
import React, { useState } from "react";
import { PASSWORD_ERROR, RE_PASSWORD_ERROR } from "../../utils/constants";
import SnackBarHome from "../Snackbar/SnackbarHome";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const ResetPasswordStageThree = ({
  user,
  userError,
  handleChange,
  resetUserPassword,
  open,
  setOpen,
  message,
  handleIconToggle,
  iconVisible,
}) => {
  const [confirmIconVisisble, setConfirmIconVisible] = useState(true);

  return (
    <>
      <h3 className="reset-title mb-4">Please reset your temporary password</h3>

      <div className="field-col full-width">
        <label className="field-title-code">
          New password<span>*</span>
        </label>
        <div>
          <input
            className="field-input-login"
            name="resetUserPassword"
            type={iconVisible ? "password" : "text"}
            required
            value={user?.resetUserPassword}
            onChange={handleChange}
          />
          <span className="input-icon-login" onClick={handleIconToggle}>
            {iconVisible ? (
              <EyeSlash color="black" size={24} />
            ) : (
              <Eye color="black" size={24} />
            )}
          </span>
        </div>
        <span className="error-message">
          {userError?.resetUserPasswordError ? PASSWORD_ERROR : ""}
        </span>
      </div>

      <div className="field-col full-width">
        <label className="field-title-code">
          Confirm new password<span>*</span>
        </label>
        <div>
          <input
            className="field-input-login"
            name="confirmResetUserPassword"
            type={confirmIconVisisble ? "password" : "text"}
            required
            value={user?.confirmResetUserPassword}
            onChange={handleChange}
          />
          <span
            className="input-icon-login"
            onClick={() => setConfirmIconVisible((prevState) => !prevState)}
          >
            {confirmIconVisisble ? (
              <EyeSlash color="black" size={24} />
            ) : (
              <Eye color="black" size={24} />
            )}
          </span>
        </div>
        <span className="error-message">
          {userError?.confirmResetUserPasswordError ? RE_PASSWORD_ERROR : ""}
        </span>
      </div>

      <p>
      Your password should be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.
      </p>

      <SnackBarHome
        open={open}
        setOpen={setOpen}
        severity="error"
        message={message}
      />
      <button
        className="btn primary-btn"
        onClick={(event) => {
          resetUserPassword(event);
        }}
      >
        Submit
      </button>
    </>
  );
};

export default ResetPasswordStageThree;
