import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ADMIN_VERIFIED,
  STATUS_DOWNLOADABLE,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PENDING_VERIFICATION,
  STATUS_REGISTERED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_VERIFICATION_FAILED,
} from "../../utils/constants";
import { getRouteHeading, getRouteName } from "../../utils/utils";

const NotificationStrip = ({ userData, status }) => {
  const [barVisible, setBarVisible] = useState("none");

  const [showNotificationStrip, setShowNotificationStrip] = useState(false);


  useEffect(() => {
    getNotificationVisible();
  }, [userData, status]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotificationStrip(true);
    }, 2000); // 2-second delay

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const getNotificationVisible = () => {

    console.log(window.location.pathname, status);
    if (
      window.location.pathname === "/app-stage" &&
      status === STATUS_DOWNLOADABLE
    ) {
      setBarVisible("block");
    } else if (
      window.location.pathname === "/download" &&
      (status === STATUS_DOWNLOADABLE || status === STATUS_PAYMENT_SUCCESS)
    ) {
      setBarVisible("none");
    }else if (
      window.location.pathname === "/profile" &&
      (status === STATUS_DOWNLOADABLE || status === STATUS_PAYMENT_SUCCESS)
    ) {
      setBarVisible("none");
    } else if(window.location.pathname === "/how-to-begin" || window.location.pathname === "/in" || window.location.pathname === "/freemiumStatus" || window.location.pathname ===  "/profile-verification"){
      setBarVisible("none");
    }  else if(( window.location.pathname === "/profile" || window.location.pathname === "/pricing" || window.location.pathname === '/freemiumStatus') &&
      status === STATUS_DOWNLOADABLE
    ){
      setBarVisible("none");
    } else if (
      userData &&
      userData?.activeUser &&
      userData?.eventDay === "false" &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/app-stage" &&
      (status === ADMIN_VERIFIED ||
        status === STATUS_REGISTERED ||
        status === STATUS_PENDING_VERIFICATION ||
        status === STATUS_VERIFICATION_FAILED ||
        status === STATUS_VERIFICATION_COMPLETED ||
        status === STATUS_PAYMENT_FAILED ||
        status === STATUS_PAYMENT_AWAITED ||
        status === STATUS_PAYMENT_SUCCESS ||
        status === STATUS_DOWNLOADABLE)
    ) {
      setBarVisible("block");
     // console.log(status);
    } else {
      setBarVisible("none");
    }
  };

  //console.log(barVisible +' '+ status +' '+window.location.pathname);
  //console.log("bar status");
  return (
    <>
    {showNotificationStrip  &&
      <div id="notificationstrip-id" className="notificationstrip" style={{ display: barVisible }}>
        Click on the <NavLink to={getRouteName(status)}>{getRouteHeading(status)}</NavLink> to complete your purchase journey.
        {/* <NavLink to={getRouteName(status)}>{getRouteHeading(status)}</NavLink>' */}
         
      </div>
    }
    </>
  );
};

export default NotificationStrip;
