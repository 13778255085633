import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../../../Components/LoadingScreen";
import LoginForm from "../../../Components/Login";
import SnackBar from "../../../Components/Snackbar";
import { signUpUserHubspot } from "../../../service/hubspotAxios";

import { setAboutYouStage } from "../../../redux/stages";
import {
  setCountry,
  setFirstTimeUser,
  setLoggedIn,
  setUserData,
  setPasswordStage,
} from "../../../redux/users";
import ApiService from "../../../service/axiosUtils";
import {
  ADMIN_REGISTERED,
  ADMIN_SUPPORT_ERROR,
  ADMIN_VERIFIED,
  CONGRATS_TEXT,
  EMAIL_NOT_EXIST,
  PAGES,
  ROUTES,
  STATUS_DOWNLOADABLE,
  STATUS_DOWNLOADED,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PENDING_VERIFICATION,
  STATUS_REGISTERED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_VERIFICATION_FAILED,
  TOKEN_ERROR,
  OTPNEWUSER
} from "../../../utils/constants";
import { callTrackingApi } from "../../../utils/helperCookie";
import { getEmailVerifiedError, getError } from "../../../utils/utils";
import { getPricingTable } from "../../../redux/prices";
import { toast } from "react-toastify";

function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cognitoUser, setCognitoUser] = useState({});
  const userValue = {
    email: "",
    password: "",
    verifyEmail: "",
    code: "",
    newPassword: "",
    confirmPassword: "",
    resetUserPassword: "",
    confirmResetUserPassword: "",
    newCode: "",
    submitDisabled: true 
  };
  const [user, setUser] = useState(userValue);
  const initialError = {
    emailError: false,
    passwordError: false,
    verifyEmailError: false,
    codeError: false,
    newPasswordError: false,
    confirmPasswordError: false,
    resetUserPasswordError: false,
    confirmResetUserPasswordError: false,
    newCodeError: false,
  };
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.useRef();
  const [userError, setUserError] = useState(initialError);
  const [errApi, setApiError] = useState("");
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  //const [forgotPasswordStage, setForgotPasswordStageFunc] = useState(0);

  const forgotPasswordStage = useSelector((state) => state?.users?.passwordStage);

  const [iconVisible, setIconVisible] = useState(true);

  useEffect(() => {
    callTrackingApi({
      email: "",
      pagetitle: PAGES.SIGIN_PAGE,
      pagelink: location?.pathname,
    });
  }, []);

  useEffect(() => {
    if (location?.state && location?.state?.user === "user") {
      setOpenInfo(true);
    }
  }, [location]);

  const checkKeyPress = useCallback(
    (e) => {
      const { key } = e;
      if (key === "Enter") {
        handleSubmit(e);
      }
    },
    [user]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  const handleChange = (event) => {
    if (!event.target.value.includes(' ')) {
      setUser({ ...user, [event.target.name]: event.target.value });
    }
    setUserError(initialError);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    if (!user.email || !user.password) {
      if (!user.email) {
        setUserError((prevState) => ({ ...prevState, emailError: true }));
      }
      if (!user.password) {
        setUserError((prevState) => ({ ...prevState, passwordError: true }));
      }
      return;
    }
    setLoading(true);

    // try {
    //   await ApiService.getApiData(
    //     `${process.env.REACT_APP_PROD_BASE_URL}/v1/public/password-reset-request?email=${user.email}`
    //   );
    // } catch (err) {
    //   setLoading(false);
    //   toast.error(err?.response?.data?.error, {
    //     position: "top-center",
    //   });
    //   return;
    // }

    const token = await recaptchaRef?.current?.executeAsync();
    if (!token) {
      setApiError(TOKEN_ERROR);
      setOpen(true);
      return;
    }
    recaptchaRef.current.reset();

    Auth.signIn(user?.email, user?.password, { captcha: token })
      .then((resp) => {
        setCognitoUser(resp);
        // setUser(userValue);
        if (
          resp?.attributes?.profile === "admin" ||
          resp?.attributes?.profile === "support"
        ) {
          setLoading(false);
          setApiError(ADMIN_SUPPORT_ERROR);
          setOpen(true);
          Auth.signOut();
          setCognitoUser({});
          return;
        }

        if (!resp?.signInUserSession?.idToken?.jwtToken) {
          setLoading(false);
          setForgotPasswordStage(3);
          return;
        }
        callEventDayAndCheckApi(resp);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.message?.includes("User is not confirmed")) {
          handleCodeResendByUsername();
          setForgotPasswordStage(4);
        }
        setApiError(getError(`${err?.message}`));
        setOpen(true);
      });
  };

  const handleForgotPassword = (event) => {
    if (!user.verifyEmail) {
      setUserError((prevState) => ({ ...prevState, verifyEmailError: true }));
      return;
    }
    ApiService.getApiData(
      `${process.env.REACT_APP_PROD_BASE_URL}/v1/public/password-reset-request?email=${user.verifyEmail}`
    )
      .then((res) => {
        console.log(res);
        console.log("in if condition");
        if (!res) {
          toast.error(EMAIL_NOT_EXIST, {
            position: "top-center",
          });
          return;
        }
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (user.verifyEmail) {
          if (!regex.test(user.verifyEmail)) {
            setUserError((prevState) => ({
              ...prevState,
              verifyEmailError: true,
            }));
            return;
          }
        }
        event.preventDefault();
        setLoading(true);
        Auth.forgotPassword(user?.verifyEmail)
          .then(() => {
            setForgotPasswordStage(2);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setApiError(getEmailVerifiedError(`${err?.message}`));
            setOpen(true);
          });
      })
      .catch((err) => {
        // console.log("error here");
        // console.log(err?.response?.data?.error);
        // toast.error(err?.response?.data?.error, {
        //   position: "top-right",
        // });
      });
  };

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    if (!user.code || !user?.newPassword || !user?.confirmPassword) {
      if (!user.code) {
        setUserError((prevState) => ({ ...prevState, codeError: true }));
      }
      if (!user.newPassword) {
        setUserError((prevState) => ({ ...prevState, newPasswordError: true }));
      }
      if (!user.confirmPassword) {
        setUserError((prevState) => ({
          ...prevState,
          confirmPasswordError: true,
        }));
      }
      return;
    }
    if (user?.newPassword !== user?.confirmPassword) {
      setUserError((prevState) => ({
        ...prevState,
        confirmPasswordError: true,
      }));
      return;
    }
    setLoading(true);
    Auth.forgotPasswordSubmit(user?.verifyEmail, user?.code, user?.newPassword)
      .then(() => {
        setForgotPasswordStage(0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(`${err?.message}`);
        setOpen(true);
      });
  };

  const handleCodeResendByUsername = () => {
    setLoading(true);
    Auth.resendSignUp(user?.email)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(`${err?.message}`);
        setOpen(true);
      });
  };

  const handleCodeResend = (event) => {
    event.preventDefault();
    setLoading(true);
    Auth.resendSignUp(user?.verifyEmail)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(`${err?.message}`);
        setOpen(true);
      });
  };

  const resetUserPassword = (event) => {
    event.preventDefault();
    if (!user.resetUserPassword || !user.confirmResetUserPassword) {
      if (!user.resetUserPassword) {
        setUserError((prevState) => ({
          ...prevState,
          resetUserPasswordError: true,
        }));
      }

      if (!user.confirmResetUserPassword) {
        setUserError((prevState) => ({
          ...prevState,
          confirmResetUserPasswordError: true,
        }));
      }
      return;
    }

    if (user?.resetUserPassword !== user?.confirmResetUserPassword) {
      setUserError((prevState) => ({
        ...prevState,
        confirmResetUserPasswordError: true,
      }));
      return;
    }
    setLoading(true);
    Auth.completeNewPassword(cognitoUser, user?.resetUserPassword)
      .then((res) => {
        setLoading(false);
        callEventDayAndCheckApi(res);
        setCognitoUser({});
      })
      .catch((err) => {
        setLoading(false);
        setApiError(`${err?.message}`);
        setOpen(true);
      });
  };

  const handleLoginCodeSubmit = async (event) => {
    event.preventDefault();
    console.log("handleLoginCodeSubmit");
    if (!user?.newCode) {
      setUserError((prevState) => ({ ...prevState, newCodeError: true }));
      return;
    }
    const token = await recaptchaRef?.current?.executeAsync();
    setLoading(true);
    recaptchaRef.current.reset();
    Auth.confirmSignUp(user?.email, user?.newCode)
      .then(() => {
        console.log("here clicked");
        if (!token) {
          console.log("inner clicked");
          setApiError(TOKEN_ERROR);
          setOpen(true);
          return;
        }
        signUpUserHubspot(user);
        Auth.signIn(user?.email, user?.password, { captcha: token })
          .then((resp) => {
            if (
              resp?.attributes?.profile === "admin" ||
              resp?.attributes?.profile === "support"
            ) {
              setLoading(false);
              setApiError(ADMIN_SUPPORT_ERROR);
              setOpen(true);
              return;
            }
            callEventDayAndCheckApi(resp);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        //setLoading(false);

        setLoading(false);
        setApiError(OTPNEWUSER);
        setOpen(true);


      });
  };

  const callEventDayAndCheckApi = (resp) => {
    const userObj = {
      username: resp?.username,
      attributes: resp?.attributes,
      token: resp?.signInUserSession?.idToken?.jwtToken,
    };
    // dispatch(getPricingTable(resp?.signInUserSession?.idToken?.jwtToken));
    dispatch(setUserData(userObj));

    setLoading(true);
    // EVENT DAY API
    ApiService.getApiData(
      `/eventDay`,
      resp?.signInUserSession?.idToken?.jwtToken
    )
      .then((eventRes) => {
        let body = { data: "false" };
        if (typeof eventRes?.data?.body === "string") {
          body = JSON.parse(eventRes?.data?.body);
        } else {
          body = eventRes?.data;
        }

        // Check if event day is true
        if (body?.data === "true") {
          // GET USERS BY ID API
          ApiService.getApiData(
            `/users/id`,
            resp?.signInUserSession?.idToken?.jwtToken
          )
            .then((res) => {
              setLoading(false);
              if (res?.data && res?.data?.length > 0) {
                dispatch(setFirstTimeUser(res?.data[0].FirstTimeUser));
                dispatch(setCountry(res?.data[0].Country));
                dispatch(setAboutYouStage(res?.data[0]));
                // TODO: Add logic to re-direct to specific route
                if (
                  res?.data[0]?.StatusVal === ADMIN_REGISTERED ||
                  res?.data[0]?.StatusVal === ADMIN_VERIFIED
                ) {
                  navigate(ROUTES.DOWNLOAD);
                } else if (res?.data[0]?.StatusVal === STATUS_DOWNLOADED) {
                  navigate(ROUTES.DOWNLOAD);
                } else {
                  navigate(ROUTES.DOWNLOAD);
                }
              } else {
                navigate(ROUTES.CONFIRMATION);
              }
              dispatch(setLoggedIn(true));
            })
            .catch((e) => {
              setLoading(false);
              navigate(ROUTES.CONFIRMATION);
            });
        } else {
          // Check if event day is false
          // GET USERS BY ID API
          ApiService.getApiData(
            `/users/id`,
            resp?.signInUserSession?.idToken?.jwtToken
          )
            .then((res) => {
              setLoading(false);
              if (res?.data && res?.data?.length > 0) {
                dispatch(setFirstTimeUser(res?.data[0].FirstTimeUser));
                dispatch(setCountry(res?.data[0].Country));
                dispatch(setAboutYouStage(res?.data[0]));
                // TODO: Add logic to re-direct to specific route
                if (
                  res?.data[0]?.StatusVal === STATUS_REGISTERED ||
                  res?.data[0]?.StatusVal === STATUS_PENDING_VERIFICATION ||
                  res?.data[0]?.StatusVal === STATUS_VERIFICATION_FAILED ||
                  res?.data[0]?.StatusVal === STATUS_VERIFICATION_COMPLETED ||
                  res?.data[0]?.StatusVal === STATUS_PAYMENT_FAILED ||
                  res?.data[0]?.StatusVal === STATUS_PAYMENT_AWAITED
                ) {
                  navigate(ROUTES.APP_STAGE);
                } else if (
                  res?.data[0]?.StatusVal === STATUS_PAYMENT_SUCCESS ||
                  res?.data[0]?.StatusVal === STATUS_DOWNLOADABLE
                ) {
                  navigate(ROUTES.DOWNLOAD);
                } else if (
                  res?.data[0]?.StatusVal === STATUS_DOWNLOADED &&
                  res?.data[0]?.ContractS3Key
                ) {
                  navigate(ROUTES.DOWNLOAD);
                } else if (
                  res?.data[0]?.StatusVal === STATUS_DOWNLOADED &&
                  !res?.data[0]?.ContractS3Key
                ) {
                  navigate(ROUTES.APP_STAGE);
                } else if (
                  res?.data[0]?.StatusVal === ADMIN_REGISTERED ||
                  res?.data[0]?.StatusVal === ADMIN_VERIFIED
                ) {
                  navigate(ROUTES.HOW_TO_BEGIN);
                } else {
                  navigate(ROUTES.APP_STAGE);
                }
              } else {
                navigate(ROUTES.CONFIRMATION);
              }
              dispatch(setLoggedIn(true));
            })
            .catch(() => {
              setLoading(false);
              navigate(ROUTES.CONFIRMATION);
            });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleIconToggle = () => {
    setIconVisible(!iconVisible);
  };

  const setForgotPasswordStage = (data) => {
    //setForgotPasswordStageFunc(data);
    dispatch(setPasswordStage(data));
  }

  return (
    <>
      <LoginForm
        user={user}
        userError={userError}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        forgotPasswordStage={forgotPasswordStage}
        setForgotPasswordStage={setForgotPasswordStage}
        handleForgotPassword={handleForgotPassword}
        handleForgotPasswordSubmit={handleForgotPasswordSubmit}
        // handleCodeResend={handleCodeResend}
        handleCodeResend={handleForgotPassword}
        recaptchaRef={recaptchaRef}
        resetUserPassword={resetUserPassword}
        handleLoginCodeSubmit={handleLoginCodeSubmit}
        open={open}
        setOpen={setOpen}
        severity="error"
        message={errApi}
        iconVisible={iconVisible}
        handleIconToggle={handleIconToggle}
      />
      <SnackBar
        open={openInfo}
        setOpen={setOpenInfo}
        severity="info"
        message={CONGRATS_TEXT}
      />
      {loading && <LoadingScreen open={loading} />}
    </>
  );
}

export default Login;
