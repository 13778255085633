import React, { useEffect, useState } from "react";
import Axios from "axios";
import HorizontalLinearStepper from "../../Components/Stepper";
import SnackBar from "../../Components/Snackbar";
import ApiService from "../../service/axiosUtils";
import { isEqual } from "lodash";
import {
  steps,
  stepsForSkipLOA,
  REGISTERATION_FAILED,
  STATUS_REGISTERED,
  SIGN_LETTER_ERROR,
  PAGES,
  UPLOAD_ERROR,
  STATUS_PENDING_VERIFICATION,
  STATUS_VERIFICATION_FAILED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_AWAITED,
  VERIFICATION_FAILED_ERROR,
  VERIFICATION_PENDING_ERROR,
  ROUTES,
  STATUS_PAYMENT_SUCCESS,
  USER_APPLICANT,
  USER_REJECTED,
  hubspotSteps,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setFirstTimeUser, setCountry, setHubUser } from "../../redux/users";
import { setAboutYouStage, setHubspotStage } from "../../redux/stages";
import {
  getHubspotUser,
  getIfHubspotUserExist,
  registerFormHubspot,
} from "../../service/hubspotAxios";
import { getName } from "../../utils/utils";
import LoadingScreen from "../../Components/LoadingScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { callTrackingApi } from "../../utils/helperCookie";
import { toast } from "react-toastify";
import {
  fetchNotifications,
  fetchNotificationsCount } from "../../redux/notifications";

const Stages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const stage = useSelector((state) => state?.stage?.aboutYou);

  const userId = useSelector((state) => state?.users?.user?.username);
  const firstName = useSelector(
    (state) => state?.users?.user?.attributes?.given_name
  );
  const lastName = useSelector(
    (state) => state?.users?.user?.attributes?.family_name
  );
  const email = useSelector((state) => state?.users?.user?.attributes?.email);
  const tokenId = useSelector((state) => state?.users?.user?.token);
  const profile = useSelector(
    (state) => state?.users?.user?.attributes?.profile
  );
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const hubspotUser = useSelector((state) => state?.users?.hubspotUser);

  /******if set LOA SKip the steps for Skiploa*******/
  // var stepsData;
  // if(stage?.Loaskip=="Yes"){
  //   stepsData = stepsForSkipLOA;
  // }else{
  //   stepsData = steps;
  // }


  const initialError = {
    nameError: false,
    lastNameError: false,
    organizationError: false,
    departmentError: false,
    designationError: false,
    phNoError: false,
    macError: false,
    countryError: false,
  };

  const [aboutYouDetails, setAboutYouDetails] = React.useState({
    FirstName: "",
    LastName: "",
    Org: "",
    Dept: "",
    Desgn: "",
    PhNo: "",
    // Mac: "",
    Email: email || "",
    role: "user",
    UserID: userId,
    Country: "India",
  });
  const [oldDetails, setOldDetails] = React.useState({
    FirstName: "",
    LastName: "",
    Org: "",
    Dept: "",
    Desgn: "",
    PhNo: "",
    // Mac: "",
    Email: email || "",
    role: "user",
    UserID: userId,
    Country: "India",
  });

  const [aboutYouError, setAboutYouError] = React.useState(initialError);
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [errApi, setErrApi] = useState("");
  const [validationError, setValidationError] = React.useState(initialError);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email) {
      getHubspot();
    }
  }, [email]);

  const getHubspot = () => {
    try {
      getIfHubspotUserExist(email)
        .then((r) => r.text())
        .then((resp) => {
          dispatch(setHubUser(resp));
        });
    } catch (e) {}
  };

  useEffect(() => {
    if (profile === USER_APPLICANT || profile === USER_REJECTED) {
      navigate(ROUTES.LANDING_PAGE);
    }
  }, [profile]);

  useEffect(() => {
    setAboutYouDetails({
      FirstName: getName(stage?.FirstName, firstName),
      LastName: getName(stage?.LastName, lastName),
      Org: hubspotYou?.Org || "",
      Dept: hubspotYou?.Dept || "",
      Desgn: hubspotYou?.Desgn || "",
      PhNo: hubspotYou?.PhNo || "",
      // Mac: hubspotYou?.Mac || "",
      Email: stage?.Email || email,
      role: stage?.role || "user",
      UserID: userId,
      Country: hubspotYou?.Country || "India",
      Loaskip: stage?.Loaskip || "",
      status: "Registered",
    });
    setOldDetails({
      FirstName: getName(stage?.FirstName, firstName),
      LastName: getName(stage?.LastName, lastName),
      Org: hubspotYou?.Org || "",
      Dept: hubspotYou?.Dept || "",
      Desgn: hubspotYou?.Desgn || "",
      PhNo: hubspotYou?.PhNo || "",
      // Mac: hubspotYou?.Mac || "",
      Email: stage?.Email || email,
      role: stage?.role || "user",
      UserID: userId,
      Country: hubspotYou?.Country || "India",
      status: "Registered",
    });
  }, [stage, hubspotYou]);

  useEffect(() => {
    if (email) {
      callTrackingApi({
        email,
        pagetitle: PAGES.STAGES,
        pagelink: pathname,
      });
    }
  }, [email]);

  const getUserDetails = () => {
    setLoading(true);
    ApiService.getApiData(`/users/id`, tokenId)
      .then((res) => {
        setLoading(false);
        console.log(hubspotUser);
        console.log(stage?.Loaskip);
        console.log(res?.data[0].StatusVal);
        console.log(res);
        console.log("stage inner");
        if (res?.data && res?.data?.length > 0) {
          dispatch(setFirstTimeUser(res?.data[0].FirstTimeUser));
          dispatch(setCountry(res?.data[0].Country));
          dispatch(setAboutYouStage(res?.data[0]));
          // TODO: Add logic to re-direct to specific route
          if (res?.data[0].StatusVal === STATUS_REGISTERED) {
             setActiveStep(1);
          } else if (
            res?.data[0].StatusVal === STATUS_PENDING_VERIFICATION ||
            res?.data[0].StatusVal === STATUS_VERIFICATION_FAILED
          ) {
            setActiveStep(2);
          } else if (
            res?.data[0].StatusVal === STATUS_VERIFICATION_COMPLETED ||
            res?.data[0].StatusVal === STATUS_PAYMENT_FAILED ||
            res?.data[0].StatusVal === STATUS_PAYMENT_AWAITED
          ) {
            //if hubspot user or LoaSkip then move to Plan Stage
            if((hubspotUser === 1 || hubspotUser === "1") || res?.data[0].Loaskip=="Yes"){
              console.log("inif me aaya");
              //SKIP Loa Step, move to plan stage
              setActiveStep(1);
            }else{
              console.log("else me aaya");
              setActiveStep(3);
            }

          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e?.message);
      });
  };

  useEffect(() => {
    if (userId) {
      //const timer = setTimeout(() => {
       getUserDetails();
      //}, 5000);
    }
  }, [userId, hubspotUser]);

  const registerUser = (body) => {
    setLoading(true);
    
    //console.log(registerUser);
    ApiService.patchApiData(`/users/id`, body, tokenId)
      .then(() => {
        registerFormHubspot(body, email);
        ApiService.getApiData(`/users/id`, tokenId)
          .then((res) => {
            setLoading(false);
            if (res?.data && res?.data?.length > 0) {
              dispatch(setAboutYouStage(res?.data[0]));
              setActiveStep((prevActiveStep) => prevActiveStep + 1);              
            }
          })
          .catch((e) => {
            setLoading(false);
            console.log(e?.message);
          });

        const p = getHubspotUser(email);
        p.then((r) => r.json())
          .then((resp) => {
            const obj = {
              Org: body.Org || resp?.properties?.company?.value,
              Dept: body.Dept || resp?.properties?.department_unit_org_chart?.value,
              PhNo: body.PhNo || resp?.properties?.phone?.value,
              Desgn: body.Desgn || resp?.properties?.jobtitle?.value,
              Country: body.Country || resp?.properties?.custom_country?.value,
            };
            dispatch(setHubspotStage(obj));
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(() => {
        setLoading(false);
        setOpen(true);
        setErrApi(REGISTERATION_FAILED);
      });
  };

  const handleNext = () => {
    dispatch(fetchNotificationsCount(userId, tokenId));
    dispatch(fetchNotifications(userId, tokenId));

    // console.log(activeStep);
    // console.log("active step switch");
    switch (activeStep) {
      case 0:
        if (
          !aboutYouDetails.FirstName ||
          !aboutYouDetails.LastName ||
          !aboutYouDetails.Org ||
          !aboutYouDetails.Dept ||
          !aboutYouDetails.Desgn ||
          !aboutYouDetails.PhNo ||
          // !aboutYouDetails.Mac ||
          !aboutYouDetails.Country
        ) {
          if (!aboutYouDetails.FirstName) {
            setAboutYouError((prevState) => ({
              ...prevState,
              nameError: true,
            }));
          }
          if (!aboutYouDetails.LastName) {
            setAboutYouError((prevState) => ({
              ...prevState,
              lastNameError: true,
            }));
          }
          if (!aboutYouDetails.Org) {
            setAboutYouError((prevState) => ({
              ...prevState,
              organizationError: true,
            }));
          }
          if (!aboutYouDetails.Dept) {
            setAboutYouError((prevState) => ({
              ...prevState,
              departmentError: true,
            }));
          }
          if (!aboutYouDetails.Desgn) {
            setAboutYouError((prevState) => ({
              ...prevState,
              designationError: true,
            }));
          }
          if (!aboutYouDetails.PhNo) {
            setAboutYouError((prevState) => ({
              ...prevState,
              phNoError: true,
            }));
            setValidationError((prevState) => ({
              ...prevState,
              phNoError: true,
            }));
          }
          // if (!aboutYouDetails.Mac) {
          //   setAboutYouError((prevState) => ({
          //     ...prevState,
          //     macError: true,
          //   }));
          // }
          if (!aboutYouDetails.Country) {
            setAboutYouError((prevState) => ({
              ...prevState,
              countryError: true,
            }));
          }
        } else if (
          validationError.macError ||
          validationError.nameError ||
          validationError.lastNameError ||
          validationError.phNoError
        ) {
          if (validationError.phNoError) {
            setAboutYouError((prevState) => ({
              ...prevState,
              phNoError: true,
            }));
          }
        } else {
          //registerUser(aboutYouDetails);

          if(
            aboutYouDetails.FirstName &&
            aboutYouDetails.LastName &&
            aboutYouDetails.Org &&
            aboutYouDetails.Dept &&
            aboutYouDetails.Desgn &&
            aboutYouDetails.PhNo &&
            aboutYouDetails.Country
          ){
            console.log(activeStep);
            console.log("In button click");
            document.getElementById("breadcrumb-section").textContent = " Sign Letter";
            /*******if user filled once then they can not edit from back******/
            if(stage?.StatusVal=="AdminVerified"){
              registerUser(aboutYouDetails);
              //alert("Hello")
            }else{

              setActiveStep((prevActiveStep) => prevActiveStep + 1);

            }
          }
          
        }
        break;
      case 1:
        document.getElementById("breadcrumb-section").textContent = " Letter Verification";
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        if (stage?.StatusVal === STATUS_PENDING_VERIFICATION) {
          toast.error(VERIFICATION_PENDING_ERROR, {
            position: "top-center",
          });
          return;
        }
        if (stage?.StatusVal === STATUS_VERIFICATION_FAILED) {
          toast.error(VERIFICATION_FAILED_ERROR, {
            position: "top-center",
          });
          return;
        }
        if (!stage?.ContractS3Key) {
          toast.error(UPLOAD_ERROR, {
            position: "top-center",
          });
          return;
        }
        document.getElementById("breadcrumb-section").textContent = " Payment Detail";
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 3:
        document.getElementById("breadcrumb-section").textContent = " Download Exe";
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        setActiveStep(0);
        break;
    }
  };

  const downloadSignLetter = () => {
    ApiService.getApiData(`/download/form`, tokenId)
      .then((resp) => {
        if (resp?.data?.formUrl) {
          Axios.get(resp?.data?.formUrl, {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "CARBN.AI_LetterOfAuthorisation.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(() => {
              setOpen(true);
              setErrApi(SIGN_LETTER_ERROR);
            });
        }
      })
      .catch(() => {
        setOpen(true);
        setErrApi(SIGN_LETTER_ERROR);
      });
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(ROUTES.HOW_TO_BEGIN);
      return;
    }
   
    if(activeStep==1){
      document.getElementById("breadcrumb-section").textContent = " About you";
    }else if(activeStep==2){
      document.getElementById("breadcrumb-section").textContent = " Sign Letter";
    }else if(activeStep==3){
      document.getElementById("breadcrumb-section").textContent = " Letter Verification";
    }else if(activeStep==4){
      document.getElementById("breadcrumb-section").textContent = " Payment Detail";
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  console.log(hubspotUser);
  console.log("hubspot user update");

  return (
    <>
      <div className="stage-main-box">
        <HorizontalLinearStepper
          steps={
            ( (hubspotUser === 1 || hubspotUser === "1") || stage?.Loaskip=="Yes") ? hubspotSteps : steps
          }
          hubspotUser={hubspotUser}
          stage={stage}
          validationError={validationError}
          setValidationError={setValidationError}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          aboutYouDetails={aboutYouDetails}
          setAboutYouDetails={setAboutYouDetails}
          aboutYouError={aboutYouError}
          setAboutYouError={setAboutYouError}
          downloadSignLetter={downloadSignLetter}
        />
        <SnackBar
          open={open}
          setOpen={setOpen}
          severity="error"
          message={errApi}
        />
        {loading && <LoadingScreen open={loading} />}
      </div>
    </>
  );
};

export default Stages;
