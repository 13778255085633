import React from "react";
import Intercom from "react-intercom";

const IntercomFile = ({ appID, user_id, email, name }) => {
  return (
    <div className="intercomProviderClass">
      <Intercom
        appID={appID}
        // Other optional settings
        user_id={user_id}
        email={email}
        name={name}
      />
    </div>
  );
};

export default React.memo(IntercomFile);
