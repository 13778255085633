import "animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import WOW from "wowjs";
import carbonLogo from "../../assets/carbon-logo.png";
import playBtn from "../../assets/play-btn.png";
import "../../styles/animate.css";
import "../../styles/owl.carousel.min.css";
import { PAGES, ROUTES } from "../../utils/constants";
import { callTrackingApi } from "../../utils/helperCookie";
import ReachFooter from "../WayReach";
import "./LandingPage.css";
import CoverBox from "./inner-parts/CoverBox";
import ExperienceBox from "./inner-parts/ExperienceBox";
import ExpertsBox from "./inner-parts/ExpertsBox";
import Testimonialcover from "./inner-parts/Testimonialcover";
import TrialBox from "./inner-parts/TrialBox";
import TrustBox from "./inner-parts/TrustBox";
import { getEventDay } from "../../redux/notifications";
import HubspotModel from "./inner-parts/HubspotModel";
import HowItWorks from "./inner-parts/HowItWorks";
import $ from "jquery";
window.$ = $;

const LandingPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [signUpClicked, setSignUpClicked] = useState(false);
  const eventDay = useSelector(
    (state) => state?.notifications?.eventDayForLandingPage
  );
  const activeUser = useSelector((state) => state?.users?.activeUser);
  const [openEarlyAccess, setOpenEarlyAccess] = useState(false);
  const [howItWorks, setHowItWorks] = useState(false);

  const registerRef = useRef();

  useEffect(() => {
    setSignUpClicked(false);
    dispatch(getEventDay());
  }, []);

  useEffect(() => {
    callTrackingApi({
      email: "",
      pagetitle: PAGES.HOME_PAGE,
      pagelink: pathname,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const wow = new WOW.WOW();
      wow.init();
      //Load More
      $(".load-btn").click(function () {
        $(".FAQ-box").toggleClass("load-more");
      });
    }, 500);
  }, []);


  const handleModalClose = () => {
    setOpenEarlyAccess(false);
  };
  const opneEarlyAccess = () => {
    setOpenEarlyAccess(true);
  };

  const closeHowItWorks = () => {
    setHowItWorks(false);
  };
  const openHowItWorks = () => {
    setHowItWorks(true);
  };

  const handelClickOfEarlyAccess = () => {
    window.scrollTo(0, registerRef.current.offsetTop);
  };

  const goToSignup = () => {
    navigate(ROUTES.SIGNUP);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  };

  const earlyAccessBtn = () => {
    let ele = "";
    if (!activeUser) {
      // if (eventDay === "true") {
      //   ele = (
      //     <button
      //       className="btnlandingpage access-btn"
      //       onClick={handelClickOfEarlyAccess}
      //     >
      //       Early Access <span>LEAs only</span>
      //     </button>
      //   );
      // } else {
      ele = (
        <button
          className="btnlandingpage-signup access-btn-signup access-btn"
          onClick={goToSignup}
        >
          Sign Up
        </button>
      );
      // }
      return ele;
    }
    return ele;
  };

  return (
    <>
      <div id="landingPageW">
        {/*Top Section start*/}
        <div className="top-section">
          {/*banner start*/}
          <div className="banner">
            <div className="container text-center">
              <div className="carbon-logo">
                <img src={carbonLogo} alt="CARBN.AI" />
                <sup>TM</sup>
              </div>
              <h1 className="wow fadeInUp">
                Extract Intelligence from Every IP Packet
              </h1>
              <p className="wow fadeInUp" data-wow-delay="0.5s">
                Leveraging ClearTrail's 22 years of handling 1000s of Gbps of IP
                traffic to craft the ultimate compact IP decoder
              </p>
              <div
                className="wow fadeIn"
                data-wow-delay="1s"
                data-wow-duration="2s"
              >
                {earlyAccessBtn()}
                <button
                  type="button"
                  className="btnlandingpage work-btn"
                  onClick={openHowItWorks}
                >
                  How it works
                </button>
              </div>
            </div>
          </div>
          {/*banner end*/}

          {/*insight start*/}
          <div className="insight-box">
            <div className="container">
              <h2 className="wow fadeInUp" data-wow-delay="0.1s">
                Critical Insights Hidden
                <br /> within IP Data
              </h2>
              <div className="insight-col-box">
                <div className="insight-col wow fadeInUp">
                  Cleartext is only the
                  <br /> tip of PCAP data
                </div>
                <div className="insight-col wow fadeInUp">
                  Covert communications over encrypted messaging applications
                </div>
                <div className="insight-col wow fadeInUp">
                  Thousands of unknown
                  <br /> communication channels
                </div>
                <div className="insight-col wow fadeInUp">
                  Deep packet inspection tools
                  <br /> need high-tech expertise
                </div>
              </div>
            </div>
          </div>
          {/*insight end*/}

          {/*surface start*/}
          <div className="surface-box">
            <div className="container">
              <h2 className="wow fadeInUp">Surface the Deep Truth</h2>
              <p className="wow fadeInUp" data-wow-delay="0.5s">
                To solve high-tech crimes, analysts must understand a suspect's
                pattern-of-life over the internet - the specific set of
                behaviours and movements associated with a particular entity
                over a given period of time - to have a clear understanding of
                what activities were done and when.
              </p>
              <div
                className="wow fadeIn"
                data-wow-delay="1s"
                data-wow-duration="2s"
              >
                {earlyAccessBtn()}
                <button
                  type="button"
                  className="btnlandingpage work-btn"
                  onClick={openHowItWorks}
                >
                  How it works
                </button>
              </div>
            </div>
          </div>
          {/*surface end*/}
        </div>
        {/*Top Section end*/}
        {/* experience box start */}
        <ExperienceBox></ExperienceBox>
        {/* experience box end */}
        {/* feature box start */}
        <div className="feature-box wow fadeInUp">
          <div className="container">
            <div className="feature-col-box d-md-flex justify-content-between text-center align-items-start">
              {/* col start */}
              <div className="feature-col wow fadeInUp" data-wow-delay="0.7s">
                <h4>Unravel Connections</h4>
                <p>
                  Discover communication links between multiple suspects over encrypted IP channels
                </p>
              </div>
              {/* col end */}

              {/* col start */}
              <div className="feature-col wow fadeInUp" data-wow-delay="1.5s">
                <h4>Uncover Pattern-of-Life</h4>
                <p>
                  Organise, summarise and interpret activities over encrypted communication channels
                </p>
              </div>
              {/* col end */}

              {/* col start */}
              <div className="feature-col wow fadeInUp" data-wow-delay="2.2s">
                <h4>Experience a User-Friendly Interface</h4>
                <p>Instant visual insights, no technical expertise</p>
              </div>
              {/* col end */}
            </div>

            <div className="text-center wow fadeIn" data-wow-delay="1.0s">
              {earlyAccessBtn()}
              <button
                type="button"
                className="btnlandingpage work-btn"
                onClick={openHowItWorks}
              >
                How it works
              </button>
            </div>
          </div>
        </div>
        {/* feature box end */}
        {/* access box start */}
        {/* <div className="access-box" ref={registerRef}>
          <div className="container d-md-flex justify-content-between">
           
            <div className="access-left wow fadeInUp">
              <img src="" alt="" />
              <button
                type="button"
                className="access-play-btn"
                onClick={openHowItWorks}
              >
                <img src={playBtn} alt="Play Icon" />
                <span>How it works</span>
              </button>
            </div>
            

            {eventDay ? (
              <div className="access-right wow fadeInUp">
                <h3>Get Early Access</h3>
                <p>
                  Join us for an Special Event to unveil CARBN.AI<sup>TM</sup>.
                  Attend and receive complimentary 1-month access, enabling you
                  to upload and analyse up to 1 GB of PCAP files. Sign up now
                  for this unique opportunity.
                </p>
                <p>
                  Event Date: <strong> 13, Tuesday</strong> | Time:{" "}
                  <strong>4:15 PM GST</strong>
                </p>
                <p>
                  Where: <strong>JW Marriott Marquis, Dubai and Virtual</strong>
                </p>
                {!activeUser && (
                  <button
                    className="btnlandingpage access-btn"
                    onClick={opneEarlyAccess}
                  >
                    Register Now <span>LEAs only</span>
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div> */}
        {/* access box end */}
        {/* trust box start */}
        <TrustBox></TrustBox>
        {/* trust box end */}
        {/* experts box start */}
        <ExpertsBox></ExpertsBox>
        {/* experts box end */}
        {/* Testimonialcover box end */}
        <Testimonialcover></Testimonialcover>
        {/* Testimonialcover box end */}
        {/* trial box start */}
        {/* <TrialBox></TrialBox> */}
        {/* trial box end */}
        {/* cover box start */}
        <CoverBox></CoverBox>
        {/* cover box end */}
        <ReachFooter></ReachFooter>
      </div>
      <HubspotModel
        openEarlyAccess={openEarlyAccess}
        handleModalClose={handleModalClose}
      ></HubspotModel>
      <HowItWorks
        howItWorks={howItWorks}
        closeHowItWorks={closeHowItWorks}
      ></HowItWorks>
    </>
  );
};

export default LandingPage;
