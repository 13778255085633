import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {  ROUTES,SESSION_EXPIRED } from "../../../utils/constants";
import { setActiveUser, setUserData } from "../../../redux/users";
import { setAboutYouStage, setHubspotStage } from "../../../redux/stages";

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    userLogout();
  }, []); 

  const userLogout = () => {
    toast.error(SESSION_EXPIRED, {
      position: "top-center",
    });
    Auth.signOut().then(() => {
      dispatch(setUserData(null));
      dispatch(setActiveUser(false));
      dispatch(setAboutYouStage(null));
      dispatch(setHubspotStage(null));
      navigate(ROUTES.LOGIN);
    });
  }; 
  return (<></>);
}

export default Logout;