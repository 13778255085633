import React from "react";
import ReactOwlCarousel from "react-owl-carousel";
import quote from "../../../assets/quote.png";
import whitepaperImage from "../../../assets/whitepaper-image.jpg";

const Testimonialcover = () => {
  return (
    <>
      <div className="testimonial-cover">
        {/* testimonial box start */}
        <div className="testimonial-box">
          <div className="container">
            <h2 className="section-title text-center wow fadeInUp">
              What Intelligence Analysts Say
            </h2>

            <div
              className="testimonial-inner-box text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="quote-icon">
                <img src={quote} alt="Quote" />
              </div>
              <ReactOwlCarousel
                items={1}
                loop
                autoplay={true}
                autoplayHoverPause={true}
                autoplaySpeed={10}
                className="testimonial-slider owl-carousel"
              >
                {/* Item */}
                <div className="item">
                  <p>
                  Customizable and with a powerful filtering mechanism, perfect for our diverse data analysis needs.
                  </p>
                  <h6>Law Enforcement Agency, Eastern Europe</h6>
                </div>
                {/* Item */}
                <div className="item">
                  <p>
                  CARBN.AI<sup>TM</sup> stands out as an all-in-one offline tool with unique case structure for PCAP file storage, a feature lacking in other solutions.
                  </p>
                  <h6>Law Enforcement Agency, APAC</h6>
                </div>
                {/* Item */}
                <div className="item">
                  <p>
                  CARBN.AI<sup>TM</sup> excels in providing quick previews of PCAP files and exceptional service, adapting to feedback for improved solutions.
                  </p>
                  <h6>Military Intelligence, APAC</h6>
                </div>
                
              </ReactOwlCarousel>
            </div>
          </div>
        </div>
        {/* testimonial box end */}

        {/* Whitepaper box start 
        <div className="whitepaper-box">
          <div className="container d-md-flex justify-content-between">
            <div className="whitepaper-left wow fadeInUp" data-wow-delay="0.5s">
              <img src={whitepaperImage} alt="Whitepaper" />
            </div>
            <div
              className="whitepaper-right wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <h4>Whitepaper</h4>
              <h2>
                Decoding the Future: The Innovative Technology Powering CARBN.AI<sup>TM</sup>
              </h2>
              <p>
                Explore how CARBN.AI<sup>TM</sup>'s advanced underlying technology
                revolutionises data processing and intelligence gathering in the
                era of communication gone 'dark'.
              </p>
              <a href="#" className="btnlandingpage access-btn">
                Download
              </a>
            </div>
          </div>
        </div>*/}
      </div>
    </>
  );
};
export default Testimonialcover;
