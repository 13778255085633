import axios from "axios";
import { ROUTES } from "../utils/constants";
import store from "../redux/store";
import { setActiveUser, setUserData } from "../redux/users";
import { setAboutYouStage } from "../redux/stages";
import { toast } from "react-toastify";

const { dispatch } = store;

let BASE_URL = process.env.REACT_APP_PROD_BASE_URL;
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = `${window.location.origin}${ROUTES.LOGOUT}`;
      dispatch(setUserData(null));
      dispatch(setActiveUser(false));
      dispatch(setAboutYouStage(null));
    } else if (
      error?.response?.status !== 404 &&
      !error?.response?.data?.error?.includes(
        "profile applicant Not authorize to use this API"
      )
    ) {
      toast.error(error?.response?.data?.error ?? error.message ?? error, {
        position: "top-center",
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
